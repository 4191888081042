import React from 'react';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

const FormModal = ({
  open,
  content,
  showContent,
  onBack,
  loading,
  showInternalLinkContent,
  internalLinkContent,
  i18n,
}) => (
  <div
    className={classnames('ChatForm__FormModal p-3', { active: open, loading })}
  >
    <div
      className={classnames('ChatForm__FormModalContent', { loading, 'd-none': !showContent })}
      dangerouslySetInnerHTML={{ __html: content }}
    />

    {
      showInternalLinkContent && (
        <div>
          <div
            className={classnames('ChatForm__FormModalContent', { loading })}
            dangerouslySetInnerHTML={{ __html: internalLinkContent }}
          />

          <div className="text-center">
            <Button
              variant="secondary"
              onClick={onBack}
              className="text-uppercase"
            >
              {i18n.back}
            </Button>
          </div>
        </div>
      )
    }
  </div>
);

FormModal.propTypes = {};

export default FormModal;
