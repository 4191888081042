import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';

import {
  deserializeJsonApi,
  i18nShape,
  jsonApiShape,
  productCategoryShape,
  productShape,
  productVariantShape,
} from '../utils';

import { productPath, productsPath, fetchProductDetails } from '../../api/products';
import {
  addProductToCart, removeProductFromCart,
  addColorToCart, removeColorFromCart,
} from '../../api/cart';

const Context = createContext({});

export const Provider = ({
  i18n,
  locale,
  artemida,
  children,
  ...props
}) => {
  const colorsRef = useRef(null);

  const product = props.product.data.attributes;
  const jsonApiCategories = props.categories;
  const categories = deserializeJsonApi(jsonApiCategories);
  const jsonApiColors = props.colors;
  const productColors = deserializeJsonApi(jsonApiColors);
  const [colors, setColors] = useState(productColors);

  const [zoomedColorId, setZoomedColorId] = useState(null);
  const resetZoomedColor = () => { setZoomedColorId(null); };
  const handleZoomColorId = (id) => {
    setZoomedColorId(id);
  };

  // Cart
  const [cartColorIds, setCartColorIds] = useState(props.cartColorIds);
  const [cartProductIds, setCartProductIds] = useState(props.cartProductIds);

  const [details, setDetails] = useState(null);
  useEffect(() => {
    fetchProductDetails({ locale, product, artemida })
      .then((response) => (response.json()))
      .then((payload) => { setDetails(payload); });
  }, []);

  const handleToggleCartColorId = (id) => {
    const alreadySelected = _.includes(cartColorIds, id);
    const willBeSelected = !alreadySelected; // next state
    const nextCartColorIds = alreadySelected
      ? cartColorIds.filter((cId) => (cId !== id))
      : [...cartColorIds, id];
    const toogleCart = willBeSelected ? addColorToCart : removeColorFromCart;

    toogleCart({ locale, colorId: id })
      .then((response) => (response.json()));

    $(document).trigger('AddToCartCta:change', ['color', id, willBeSelected]);

    setCartColorIds(nextCartColorIds);
  };
  const handleToggleCartProductId = (id) => {
    const alreadySelected = _.includes(cartProductIds, id);
    const willBeSelected = !alreadySelected; // next state
    const nextCartProductIds = alreadySelected
      ? cartProductIds.filter((cId) => (cId !== id))
      : [...cartProductIds, id];
    const toogleCart = willBeSelected ? addProductToCart : removeProductFromCart;

    toogleCart({ locale, productId: id })
      .then((response) => (response.json()));

    $(document).trigger('AddToCartCta:change', ['product', id, willBeSelected]);

    setCartProductIds(nextCartProductIds);
  };

  const normalize = (str) => _.trim(_.toLower(_.deburr(str || '')));

  const handleColorFilterChange = (text) => {
    const normalizedText = normalize(text);

    if (normalizedText.length === 0) {
      setColors(productColors);
      return;
    }

    const nextColors = productColors.filter(({ name }) => (
      _.includes(normalize(name), normalizedText)
    ));

    setColors(nextColors);
  };

  const scrollIntoColors = () => {
    if (colorsRef) {
      colorsRef.current.scrollIntoView();
    }
  };

  // The shared state
  const value = useMemo(
    () => ({
      i18n,
      locale,
      product,
      artemida,
      details,
      colors,
      categories,
      cartColorIds,
      cartProductIds,
      toggleCartProductId: handleToggleCartProductId,
      toggleCartColorId: handleToggleCartColorId,
      zoomedColorId,
      zoomColorId: handleZoomColorId,
      resetZoomedColor,
      scrollIntoColors,
      colorsRef,
      onColorFilterChange: handleColorFilterChange,
      productPath: (params) => (productPath({ locale, artemida, ...params })),
      productsPath: (params) => (productsPath({ locale, artemida, ...params })),
    }),
    [
      details,
      cartColorIds,
      cartProductIds,
      zoomedColorId,
      colors,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
  i18n: i18nShape.isRequired,
  product: PropTypes.shape({
    data: PropTypes.shape({
      attributes: productShape,
    }).isRequired,
  }).isRequired,
  categories: jsonApiShape(productCategoryShape).isRequired,
  colors: jsonApiShape(productVariantShape).isRequired,
  cartColorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  cartProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  artemida: PropTypes.bool,
};

Provider.defaultProps = {
  artemida: false,
  // productCategories: {},
  // colorPalettes: {},
  // products: {},
  // currentCategory: null,
  // currentColors: [],
  // view: 'products',
  // textFilter: '',
  // colorFilter: '',
  // cartColorIds: [],
};

export default Context;
