import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { productVariantShape } from './utils';
import Icon from './Icon';
import AddColorButton from './AddColorButton';

const ColorTile = ({ color, Context, zoomOnClick, serigraphy }) => {
  const {
    locale,
    cartColorIds,
    toggleCartColorId,
    zoomColorId,
    productPath,
  } = useContext(Context);
  const {
    id,
    name,
    product_name,
    product_id,
    product_slug,
  } = color;
  const { thumb } = color.image || {};
  const product = { id: product_id, slug: product_slug, serigraphy };
  const url = zoomOnClick
    ? '#zoom'
    : productPath({ product, locale });

  const isStarred = _.includes(cartColorIds, id);
  const handleZoomClick = (event) => {
    event.preventDefault();
    zoomColorId(id);
  };

  const handleInfoClick = (event) => {
    if (!zoomOnClick) { return; }

    handleZoomClick(event);
  };

  return (
    <div className="ColorTile">
      <div
        className="ColorTile__image"
        style={{ backgroundImage: `url('${thumb}')` }}
      />

      <div className="ColorTile__backdrop" />

      <div className="ColorTile__preview">
        <h6 className="ColorTile__previewLabel">{name}</h6>
      </div>

      <div className="ColorTile__content">
        <a href={url} className="ColorTile__anchor" onClick={handleInfoClick}>
          <span className="btn btn--h6 ColorTile__article">
            {product_name}
          </span>

          <h6 className="ColorTile__color mt-2">{name}</h6>
        </a>

        <div className="ColorTile__ctas">
          <a className="ColorTile__cta" href="#zoom" onClick={handleZoomClick}>
            <Icon name="search" fw />
          </a>
          &nbsp;
          <AddColorButton
            isStarred={isStarred}
            onClick={toggleCartColorId}
            className="ColorTile__cta"
            colorId={id}
          />
        </div>
      </div>
    </div>
  );
};

ColorTile.propTypes = {
  color: productVariantShape.isRequired,
  zoomOnClick: PropTypes.bool,
  serigraphy: PropTypes.bool,
};

ColorTile.defaultProps = {
  zoomOnClick: false,
  serigraphy: false,
};

export default ColorTile;
