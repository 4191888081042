import React, { useContext } from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import Context from './Context';

const HeaderFilter = () => {
  const {
    i18n,
    textFilter,
    setTextFilter,
    scrollIntoBody,
  } = useContext(Context);

  const handleFilterChange = _.debounce((event) => {
    setTextFilter(event.target.value);
  }, 500);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      scrollIntoBody();
    }
  };

  const className = classnames('HeaderFilter', 'row');

  return (
    <div className={className}>
      <div className="offset-md-1 col-md-7">
        <Form>
          <Form.Group className="mb-3 HeaderFilters__formGroup" controlId="formPrint">
            <Form.Control
              type="text"
              placeholder={i18n.filter_by}
              className="form-control--header"
              onChange={handleFilterChange}
              onKeyPress={handleKeyPress}
              defaultValue={textFilter}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

HeaderFilter.propTypes = {
};

HeaderFilter.defaultProps = {
};

export default HeaderFilter;
