import React from 'react';

import FallbackImage from './FallbackImage';
import ComposedImage from '../ComposedImage';

const ProductImage = ({ product }) => {
  const { has_composed_image } = product;
  const Image = has_composed_image ? ComposedImage : FallbackImage;

  return (<Image product={product} />);
};

export default ProductImage;
