import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './Context';
import Layout from './Layout';

const PrintsPage = (props) => (
  <Provider {...props}>
    <Layout />
  </Provider>
);

PrintsPage.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
// //   productCategories: jsonApiShape(productCategoryShape).isRequired,
// //   products: jsonApiShape(productShape).isRequired,
// //   productVariants: jsonApiShape(productVariantShape).isRequired,
};
//
// PrintsPage.defaultProps = {
// };

export default PrintsPage;
