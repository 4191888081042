import React, { useContext } from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import Context from './Context';

const HeaderFilter = () => {
  const {
    i18n,
    textFilter,
    setTextFilter,
    colorFilter,
    setColorFilter,
    filtersRef,
  } = useContext(Context);

  const handleArticleNameChange = _.debounce((event) => {
    setTextFilter(event.target.value);
  }, 500);
  const handleColorNameChange = _.debounce((event) => {
    setColorFilter(event.target.value);
  }, 500);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      filtersRef.current.scrollIntoView();
    }
  };

  const className = classnames('HeaderFilter', 'row');

  return (
    <div className={className}>
      <div className="offset-md-1 col-md-10 col-lg-8 col-xl-7">
        <Form>
          <Form.Group className="mb-3 HeaderFilters__formGroup" controlId="formArticleName">
            <Form.Control
              type="text"
              placeholder={i18n.filter_by_article}
              className="dform-control--header"
              onChange={handleArticleNameChange}
              onKeyPress={handleKeyPress}
              defaultValue={textFilter}
            />
          </Form.Group>

          <Form.Group className="HeaderFilters__formGroup" controlId="formColorName">
            <Form.Control
              type="text"
              placeholder={i18n.filter_by_color}
              className="dform-control--header"
              onChange={handleColorNameChange}
              onKeyPress={handleKeyPress}
              defaultValue={colorFilter}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

HeaderFilter.propTypes = {
};

HeaderFilter.defaultProps = {
};

export default HeaderFilter;
