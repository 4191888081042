import $ from 'jquery';
import _ from 'lodash';

const selector = '.scrollify';
const scrollTo = (item) => {
  const offset = $(item).offset().top;
  $(window).scrollTop(offset);
};

const defaultScrollTrigger = 0.25;

export default {
  handleScroll(event) {
    const winHeight = $(window).height();
    const currScrollTop = $(window).scrollTop();
    const currScrollBottom = currScrollTop + winHeight;
    const items = $(selector).toArray();

    if (event.originalEvent.wheelDelta > 0 || event.originalEvent.detail < 0) {
      //
      // SCOLLING UP
      //
      _.reverse(items);

      const currentItem = items.find((el) => {
        const height = $(el).height();
        const startFrom = $(el).offset().top;
        const endTo = startFrom + height;

        return currScrollBottom >= startFrom && currScrollBottom <= endTo;
      });

      if (!currentItem) { return; }

      const currentItemStartFrom = $(currentItem).offset().top;
      const currentItemEndTo = $(currentItem).height() + currentItemStartFrom;

      const prevItem = items.find((el) => {
        const height = $(el).height();
        const startFrom = $(el).offset().top;
        const endTo = startFrom + height;

        return endTo <= currentItemStartFrom;
      });

      if (!prevItem) { return; }

      const st = 1 - defaultScrollTrigger;
      const willScrollUp = currScrollBottom < (currentItemStartFrom + currentItemEndTo) * st
        && currScrollBottom - winHeight < currentItemStartFrom;

      if (willScrollUp) { scrollTo(prevItem); }
    } else {
      //
      // SCOLLING DOWN
      //
      // console.log('scroll down');
      const currentItem = items.find((el) => {
        const height = $(el).height();
        const startFrom = $(el).offset().top;
        const endTo = startFrom + height;

        return currScrollTop >= startFrom && currScrollTop <= endTo;
      });

      if (!currentItem) { return; }

      const currentItemStartFrom = $(currentItem).offset().top;
      const currentItemEndTo = $(currentItem).height() + currentItemStartFrom;
      const nextItem = items.find((el) => {
        const startFrom = $(el).offset().top;
        return startFrom >= currentItemEndTo;
      });

      if (!nextItem) { return; }

      const st = defaultScrollTrigger;

      const willScrollDown = currScrollTop > (currentItemStartFrom + currentItemEndTo) * st
        && currScrollTop + winHeight > currentItemEndTo;

      if (willScrollDown) { scrollTo(nextItem); }
    }
  },

  init() {
    $(window).on(
      'mousewheel DOMMouseScroll',
      _.debounce(this.handleScroll, 250),
    );
  },

  destroy() {
    $(window).off('mousewheel DOMMouseScroll');
  },
};
