import React from 'react';
import PropTypes from 'prop-types';
import YoutubeBackground from 'react-youtube-background';
import SlideCaption from './SlideCaption';

const isPresent = (text) => (text && text.toString().length > 0);
const isBlank = (text) => (!isPresent(text));

const Slide = (props) => {
  const {
    title,
    titleImage,
    subtitle,
    ctaUrl,
    ctaText,
    ctaTarget,
    videoId,
  } = props;

  const cta = {
    url: ctaUrl,
    text: ctaText,
    target: ctaTarget,
  };

  const { height, image } = props;
  const style = { height };

  if (isPresent(image) && isBlank(videoId)) {
    style.backgroundImage = `url(${image})`;
  }

  const caption = (
    <SlideCaption
      title={title}
      titleImage={titleImage}
      subtitle={subtitle}
      cta={cta}
    />
  );

  if (isBlank(videoId)) {
    return (
      <div className="slide" style={style}>
        {caption}
      </div>
    );
  }

  return (
    <YoutubeBackground videoId={videoId} className="slide" style={style}>
      {caption}
    </YoutubeBackground>
  );
};

Slide.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  titleImage: PropTypes.string,
  subtitle: PropTypes.string,
  videoId: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaTarget: PropTypes.string,
  height: PropTypes.number,
  mobile: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired
};

export default Slide;
