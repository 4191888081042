import { get, post, currentLocale } from './utils';

const basePath = ({ locale }) => (
  `/${locale || currentLocale()}/chat_contact`
);

// Product

export const newChatContact = ({ locale }) => (
  get(`${basePath({ locale })}/new?from=widget`)
);

export const createChatContact = ({ locale, params }) => (
  post(`${basePath({ locale })}?from=widget`, { chat_contact: params })
);

export default {
  newChatContact,
  createChatContact,
};
