import $ from 'jquery';
import 'jscroll';

export default {
  init() {
    $('.infiniteScroller').jscroll({
      loadingHtml: '<h4 class="text-center py-5">Loading...</h4>',
      padding: 50,
      nextSelector: 'a.next-page',
      contentSelector: '.infiniteScroller__content',
    });
  },

  destroy() {
  },
};
