import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './Icon';

const AddColorButton = ({
  isStarred, onClick, colorId, showLabel, i18n,
  ...props
}) => {
  const className = classnames('AddColorButton', props.className, {
    'AddColorButton--active': isStarred,
  });

  const handleClick = (e) => {
    e.preventDefault();

    onClick(colorId);
  };

  // WARNING: React does not work well with the Icon (font-awesome) component
  // This ugly render function is required to ensure a correct rerender
  return (
    <>
      {
        isStarred && (
          <a className={className} href="#unstar" onClick={handleClick}>
            <Icon name="star" family="fas" fw />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.saved_color}
                </span>
              )
            }
          </a>
        )
      }
      {
        !isStarred && (
          <a className={className} href="#star" onClick={handleClick}>
            <Icon name="star" family="far" fw />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.save_color}
                </span>
              )
            }
          </a>
        )
      }
    </>
  );
};

AddColorButton.propTypes = {
  isStarred: PropTypes.bool,
  showLabel: PropTypes.bool,
  onClick: PropTypes.func,
  colorId: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    save_color: PropTypes.string,
    saved_color: PropTypes.string,
  }),
  className: PropTypes.string,
};

AddColorButton.defaultProps = {
  isStarred: false,
  onClick: () => {},
  className: '',
  showLabel: false,
  i18n: {},
};

export default AddColorButton;
