import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';

const LoadMoreSpinner = ({ show, style, className }) => {
  const spinnerClassName = classnames('animate__animated', {
    animate__fadeInUp: show,
    animate__fadeOutDown: !show,
  });

  return (
    <div className={className} style={style}>
      <div className="p-5 text-center">
        <Spinner animation="grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>

        <h6 className="mt-3">Loading...</h6>
      </div>
    </div>
  );
};

LoadMoreSpinner.propsTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

LoadMoreSpinner.defaultProps = {
  show: true,
  className: null,
  style: {},
};

export default LoadMoreSpinner;
