import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Ruler from '../Ruler';
import AddPrintButton from '../AddPrintButton';
import { productPath } from '../../api/products';

const RelatedProduct = ({ product, first, last, locale, i18n }) => (
  <span>
    { !first && !last ? ', ' : null }
    { !first && last ? ` ${i18n.and} ` : null }
    <a href={productPath({ product, locale })}>{product.name}</a>
  </span>
)

const RelatedProducts = ({ products, i18n, locale }) => {
  if (!products || products.length === 0) { return null; }

  return (
    <p>
      {i18n.available_for}:
      <br />
      {
        products.map((product, idx) => (
          <RelatedProduct
            key={product.id}
            locale={locale}
            product={product}
            first={idx === 0}
            last={idx === products.length - 1}
            i18n={i18n}
          />
        ))
      }
    </p>
  );
};

const PrintDetails = ({
  locale,
  print,
  i18n,
  onPrintAddedToCart,
  isStarred,
}) => {
  const {
    id,
    image,
    name,
    description,
    image_percentage_ruler,
    products,
  } = print;
  const title = [name].join(' - ');
  const imageUrl = (image || {}).zoom;

  return (
    <div className="variant-image row pt-5 pb-1">
      <div className="col-lg-7 offset-lg-1 col-sm-8">
        <Ruler percentage={image_percentage_ruler}>
          <img
            src={imageUrl}
            alt={name}
            className="variant-image__image"
          />
        </Ruler>
      </div>

      <div className="col-lg-4 col-sm-4">
        <h3 className="title title--compact variant-image__title">
          {name}
          <div className="title__separator" />
        </h3>

        <p>{parse(description || '')}</p>

        <hr />

        <RelatedProducts
          locale={locale}
          products={products}
          i18n={i18n}
        />

        <AddPrintButton
          className="variant-image__star mt-3"
          starred={isStarred}
          onClick={onPrintAddedToCart}
          printId={id}
          i18n={i18n}
          showLabel
        />
      </div>
    </div>
  );
};

PrintDetails.propTypes = {
  // i18n: i18nShape.isRequired,
  print: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  onPrintAddedToCart: PropTypes.func.isRequired,
  isStarred: PropTypes.bool.isRequired,
};

PrintDetails.defaultProps = {
};

export default PrintDetails;
