import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ProductTileWrapper = ({ index, children }) => {
  const isPair = index % 2 === 0;
  const isOdd = !isPair;
  const classNames = classnames('ProductTileWrapper', 'col-md-6', 'col-lg-5', {
    'offset-lg-1': isOdd,
  });

  return (
    <div className={classNames}>
      {children}
    </div>
  );
};

ProductTileWrapper.propTypes = {
  index: PropTypes.number.isRequired,
};

//
// ArticlesGrid.defaultProps = {
// };

export default ProductTileWrapper;
