import React, { useContext } from 'react';
import classnames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';

import Context from './Context';
import PaginationArrow from './PaginationArrow';
import ProductsView from './ProductsView';
import ColorsView from './ColorsView';
import LoadMoreSpinner from './LoadMoreSpinner';

// Considr a renaming into ArticlesNav ...
const ArticlesGrid = () => {
  const {
    i18n,
    products,
    productsMeta,
    isLoading,
    articlesGridRef,
    view,
    hasNextPage,
    infiniteScrollRef,
  } = useContext(Context);

  const View = view === 'variants' ? ColorsView : ProductsView;

  // const className = classnames('ArticlesGrid', 'row', 'mb-5', 'animate__animated', {
  //   loading: isLoading,
  //   animate__fadeInUp: !isLoading,
  //   animate__fadeOutDown: isLoading,
  // });
  const className = classnames('ArticlesGrid', 'row', 'mb-5', 'animate__animated');
  const spinnerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  };
  // const spinnerClassName = classnames('animate__animated', {
  //   animate__fadeInUp: isLoading,
  //   animate__fadeOutDown: !isLoading,
  // });
  const spinnerClassName = classnames('d-none');

  return (
    <div ref={articlesGridRef}>
      <div className={spinnerClassName} style={{ position: 'relative', height: 0, overflow: 'visible' }}>
        <div style={spinnerStyle} className="p-5 text-center">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>

          <h6 className="mt-3">Loading...</h6>
        </div>
      </div>

      <div className={className}>
        <div className="col-md-11 col-xxl-12">
          <div className="row">
            {
              (products.length === 0) && (
                <h6 className="text-center p-5">{i18n.no_entries}</h6>
              )
            }

            <View
              i18n={i18n}
              products={products}
              emptyLabel={i18n.no_entries}
            />
          </div>
        </div>
      </div>

      <div className={classnames('d-none', 'd-lg-none', { 'd-none': isLoading })}>
        <div className={classnames('row', 'mb-5')}>
          <div className="col-6">
            <PaginationArrow meta={productsMeta} direction="prev" />
          </div>

          <div className="col-6">
            <PaginationArrow meta={productsMeta} direction="next" />
          </div>
        </div>
      </div>

      {
        (isLoading || hasNextPage) && (
          <div ref={infiniteScrollRef}>
            <LoadMoreSpinner show />
          </div>
        )
      }
    </div>
  );
};

ArticlesGrid.propTypes = {
};

ArticlesGrid.defaultProps = {
};

export default ArticlesGrid;
