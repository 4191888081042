import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ColorTileWrapper = ({ index, children, compact }) => {
  // const isPair = index % 2 === 0;
  // const isOdd = !isPair;
  const classNames = classnames('ColorTileWrapper', 'col-sm-6', 'col-md-4', 'col-lg-4', 'col-xl-3', {
    'ColorTileWrapper--compact': compact,
  });

  return (
    <div className={classNames}>
      {children}
    </div>
  );
};

ColorTileWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

ColorTileWrapper.defaultProps = {
  compact: false,
};

export default ColorTileWrapper;
