import React, { useContext } from 'react';
import parse from 'html-react-parser';
import _ from 'lodash';
import classnames from 'classnames';

import Context from './Context';
import ProductDatatable from './ProductDatatable';
// import badgeImg from '../../images/notified_body_no_1084.png';
import Icon from '../Icon';
import AddProductButton from '../AddProductButton';

// const ProductCertifications = () => (
//   <>
//     <img
//       src={badgeImg}
//       alt="Notified body no. 1084"
//       title="Notified body no. 1084"
//       className="product-detail__img"
//     />
//
//     <hr />
//   </>
// );

const ProductDetail = () => {
  const {
    i18n,
    product,
    categories,
    cartProductIds,
    toggleCartProductId,
  } = useContext(Context);
  const {
    id,
    description,
    attachment,
    has_custom_logo,
    custom_logo,
    custom_logo_external_url,
  } = product;
  const attachmentUrl = (attachment || {}).url;
  const parsedDescription = parse(description || '');
  const isStarred = _.includes(cartProductIds, id);

  const hasCustomLogoExternalUrl = custom_logo_external_url && custom_logo_external_url.toString().length > 0;

  return (
    <div className="product-detail">
      <ProductDatatable />

      <div className="product-detail__paragraph mb-5">
        {parsedDescription}
      </div>

      <div className="product-detail__icons">
        {
          categories.map(({ icon, name, ...props }) => (
            <img
              key={props.id}
              src={(icon || { url: null }).thumb.url}
              alt={name}
              title={name}
              className="product-detail__img"
            />
          ))
        }

        { categories.length > 0 && (<hr />) }

        {
          attachmentUrl && (
            <a
              href={attachmentUrl}
              className="product-detail__cta"
              target="_blank"
              title="Download PDF"
              rel="noreferrer"
            >
              <Icon name="file-pdf" family="far" size="2x" fw />
            </a>
          )
        }

        <AddProductButton
          className={classnames('product-detail__cta', { 'product-detail__cta--starred': isStarred })}
          isStarred={isStarred}
          onClick={toggleCartProductId}
          productId={id}
          i18n={i18n}
          iconProps={{ size: '2x' }}
        />

        {
          has_custom_logo && hasCustomLogoExternalUrl && (
            <a href={custom_logo_external_url} target="_blank" className="product-detail__custom-logo">
              <img
                src={custom_logo.small.url}
                className="product-detail__custom-logo-img"
              />
            </a>
          )
        }

        {
          has_custom_logo && !hasCustomLogoExternalUrl && (
            <span className="product-detail__custom-logo">
              <img
                src={custom_logo.small.url}
                className="product-detail__custom-logo-img"
              />
            </span>
          )
        }
      </div>
    </div>
  );
};

export default ProductDetail;
