import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import HeaderFilters from './HeaderFilters';
import scrollImage from '../../images/scrolldown.gif';

const Header = () => {
  const { i18n } = useContext(Context);

  return (
    <div className={classnames('phbox', 'Scrolldown', 'scrollify')}>
      <img
        alt="Scroll down"
        className="Scrolldown__image"
        data-scrolldown="true"
        src={scrollImage}
      />

      <div className="container-fluid phbox__bg">
        <div className="row row--no-gutter">
          <div className="col col--no-gutter phbox__bg phbox__bg--prints">
            <div className="phbox__wrapper">
              <div className="phbox__image-wrapper" />
            </div>
          </div>
        </div>
      </div>

      <div className="container phbox__content">
        <div className="row">
          <div className="col-md-6">
            <div className="phbox__title mb-5">
              <h1 className="pt-2">{i18n.title}</h1>
            </div>
          </div>

          <div className="col-md-6">
            <div className="phbox__title mt-2">
              <HeaderFilters />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Header.propTypes = {
//   i18n: i18nShape.isRequired,
//   productCategories: productCategoryShape.isRequired,
// };
//
// Header.defaultProps = {
// };

export default Header;
