import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import ColorTileWrapper from '../ColorTileWrapper';
import ColorTile from '../ColorTile';

const ColorsView = () => {
  const {
    i18n,
    product,
    colors,
    colorsRef,
  } = useContext(Context);
  const { name, serigraphy } = product;
  const className = classnames('ProductPage__ColorsView');

  return (
    <div className={className} ref={colorsRef}>
      <div className="container">
        <h4 className="mb-5 text-center text-md-start">
          {i18n.discover_the_different_articles_for}
          &nbsp;
          <strong>{name}</strong>
        </h4>

        <div className="row">
          {
            colors.map((color, index) => (
              <ColorTileWrapper index={index} key={color.id} compact>
                <ColorTile
                  Context={Context}
                  key={color.id}
                  color={color}
                  index={index}
                  serigraphy={serigraphy}
                  zoomOnClick
                />
              </ColorTileWrapper>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ColorsView;
