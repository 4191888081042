import React, { useContext } from 'react';
import classnames from 'classnames';
import Joyride from 'react-joyride';
import $ from 'jquery';

import Context from './Context';
import DefaultHeader from './Header';
import ArtemidaHeader from './ArtemidaHeader';
import ProductsConfiguratorFilters from './ProductsConfiguratorFilters';
import Body from './Body';
import DownloadModal from './DownloadModal';

const LSTutotialCompletedKey = '_fl_configurator_tutorial_completed';

const JoyrideLocales = {
  it: { back: 'Indietro', close: 'Successivo', last: 'Fine', next: 'Successivo', open: 'Apri', skip: 'Chiudi tutorial' },
  en: { back: 'Back', close: 'Next', last: 'End', next: 'Next', open: 'Open the dialog', skip: 'Close tutorial' },
};

const Layout = () => {
  const {
    artemida,
    urlParams,
    i18n,
    setShowFilter,
    colorPalettes,
    pushColorId,
    popColorId,
    setHighlightedColorId,
    products,
  } = useContext(Context);
  const Header = artemida ? ArtemidaHeader : DefaultHeader;
  const steps = [
    {
      target: '.toggle-main-menu-button',
      title: i18n.configurator_steps.toggle_main_menu_title,
      content: i18n.configurator_steps.toggle_main_menu_text,
      isFixed: true
    },
    {
      target: '.FiltersSecondaryMenuFiltersSelection',
      title: i18n.configurator_steps.filters_selection_title,
      content: i18n.configurator_steps.filters_selection_text,
      placement: 'left',
      placementBeacon: 'top-center',
      isFixed: false
    },
    {
      target: '.FiltersSecondaryMenuFilterColor__firstItem',
      title: i18n.configurator_steps.colors_selection_title,
      content: i18n.configurator_steps.colors_selection_text,
      placement: 'left',
      disableScrolling: true,
    },
    {
      target: '.ColorTile__firstItem',
      title: i18n.configurator_steps.remove_color_selection_title,
      content: i18n.configurator_steps.remove_color_selection_text,
      placement: 'right',
      disableScrolling: true,
    },
    {
      target: '.FiltersCloud',
      title: i18n.configurator_steps.deselect_filters_selection_title,
      content: i18n.configurator_steps.deselect_filters_selection_text,
      placement: 'left',
      disableScrolling: true,
    },
    {
      target: '#download-selection',
      title: i18n.configurator_steps.download_selection_title,
      content: i18n.configurator_steps.download_selection_text,
      placement: 'bottom',
      disableScrolling: true,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (status === 'finished' || status === 'skipped') {
      popColorId(colorPalettes[0].id);
      localStorage.setItem(LSTutotialCompletedKey, true);
      return;
    }

    switch (index) {
      case 0:
        setShowFilter(false);
        setHighlightedColorId(null);
        break;

      case 1:
        setShowFilter('menu');
        break;

      case 2:
        setShowFilter('color');
        pushColorId(colorPalettes[0].id);
        setHighlightedColorId(null);
        setTimeout(() => { $(window).scrollTop(0); }, 200);
        break;

      case 3:
        setShowFilter(false);
        setHighlightedColorId(products[0].id);
        setTimeout(() => { $(window).scrollTop(0); }, 200);
        break;

      case 4:
        setShowFilter(false);
        setHighlightedColorId(null);
        break;

      case 5:
        setShowFilter(false);
        setHighlightedColorId(null);
        popColorId(colorPalettes[0].id);
        break;
    }
  };

  const isTutorialCompleted = !!localStorage.getItem(LSTutotialCompletedKey);

  return (
    <div className={classnames('ProductsConfiguratorPage')}>
      {
        !isTutorialCompleted && (
          <Joyride
            locale={JoyrideLocales[gon.locale]}
            steps={steps}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                zIndex: 10000,
                primaryColor: '#009534',
              }
            }}
            showSkipButton
            showProgress
            autoOpen
            run
          />
        )
      }
      <ProductsConfiguratorFilters />
      <Body />
      <DownloadModal
        searchParams={urlParams}
        hidden
      />
    </div>
  );
};

Layout.propTypes = {
};

Layout.defaultProps = {
};

export default Layout;
