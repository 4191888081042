import React from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import Icon from '../Icon';
import BackButton from './BackButton';

const FiltersSecondaryMenuFooter = ({
  i18n,
  showFilter,
  setShowFilter,
}) => {
  return (
    <div className="FiltersSecondaryMenuFooter">
      <button
        className="btn btn-link d-none"
        onClick={() => { setShowFilter(false); }}
      >
        <Icon name="times-circle" family="far" size="2x" fw />
      </button>

      <BackButton
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        className="d-none"
      />

      <button
        className="btn btn-link float-end"
        onClick={() => { setShowFilter(false); }}
      >
        <Icon name="chevron-right" family="fas" size="2x" fw />
      </button>
    </div>
  );
};

export default FiltersSecondaryMenuFooter;
