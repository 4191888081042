import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';
import _ from 'lodash';

import PrintDetails from './PrintDetails';
import { i18nShape } from '../utils';

const defaultSlickSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 650,
  lazyLoad: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
};

const getInitialSlideIndex = ({ activePrintId, prints }) => {
  if (!activePrintId) { return 0; }

  let index = 0;

  for (let i = 0; i < prints.length; i += 1) {
    if (prints[i].id === activePrintId) {
      break;
    } else {
      index += 1;
    }
  }

  return index;
};

const PrintsGallery = ({
  i18n,
  locale,
  prints,
  activePrintId,
  onClose,
  cartPrintIds,
  onPrintAddedToCart,
  showInfoButton,
}) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) { onClose(); }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (activePrintId && sliderRef) {
      const counter = getInitialSlideIndex({ prints, activePrintId });
      sliderRef.current.slickGoTo(counter);
    }
  }, [activePrintId]);

  const isActive = !!activePrintId;

  const className = classnames('ZoomPrintsGallery', {
    'ZoomPrintsGallery--active': isActive,
  });

  const nestedClassName = classnames('ZoomPrintsGallery__wrapper animate__animated d-flex align-items-center', {
    animate__fadeInUp: isActive,
    animate__fadeOutDown: !isActive,
  });

  const settings = {
    ...defaultSlickSettings,
  };

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={className}>
      <div className={nestedClassName}>
        <div className="container ZoomPrintsGallery__container">
          <a href="#close" className="ZoomPrintsGallery__close mt-5" onClick={handleClose}>
            &times;
          </a>

          <Slider
            {...settings}
            ref={sliderRef}
            initialSlide={0}
            className="slick-slider--variant-gallery"
          >
            {
              prints.map((print) => (
                <PrintDetails
                  print={print}
                  i18n={i18n}
                  locale={locale}
                  key={print.id}
                  onPrintAddedToCart={onPrintAddedToCart}
                  isStarred={_.includes(cartPrintIds, print.id)}
                  showInfoButton={showInfoButton}
                />
              ))
            }
          </Slider>
        </div>
      </div>
    </div>
  );
};

PrintsGallery.propTypes = {
  i18n: i18nShape.isRequired,
  locale: PropTypes.string.isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})),
  activePrintId: PropTypes.number,
  onClose: PropTypes.func,
  onPrintAddedToCart: PropTypes.func,
  cartPrintIds: PropTypes.arrayOf(PropTypes.number),
  showInfoButton: PropTypes.bool,
};

PrintsGallery.defaultProps = {
  activePrintId: null,
  prints: [],
  onClose: () => {},
  onPrintAddedToCart: () => {},
  cartPrintIds: [],
  showInfoButton: true,
};

export default PrintsGallery;
