import $ from 'jquery';
import select2 from '../widgets/select2';

const productVariantsSampleRequestPath = ({ product_id, format, locale }) => (
  `/${locale}/sample_request/${product_id}/variants.${format}`
);

function SampleRequestItem(item, products) {
  this.productInput = '.sample-request-item__product';
  this.productVariantInput = '.sample-request-item__product-variant';
  this.imageElement = '.sample-request-item__image';
  this.imageElement = '.sample-request-item__image';
  this.radioElements = 'input.sample-request-item__radio-button';
  this.productFieldsContainer = '.sample-request-item__fieldset--product';
  this.productPrintFieldsContainer = '.sample-request-item__fieldset--product-print';
  this.productPrintCategoryInput = '.sample-request-item__product-print-category';
  this.productPrintInput = '.sample-request-item__product-print';

  this.productVariantsRequest = (product_id) => (
    $.ajax({
      url: productVariantsSampleRequestPath({ product_id, format: 'json', locale: gon.locale }),
      dataType: 'json',
      method: 'GET'
    })
  );

  this.getRadioValue = () => (
    this.item.find(`${this.radioElements}:checked`).val()
  );

  this.areProductFieldsShown = () => (
    this.getRadioValue() && this.getRadioValue().toString() === 'product'
  );

  this.areProductPrintFieldsShown = () => (
    this.getRadioValue() && this.getRadioValue().toString() === 'product_print'
  );

  this.getProductInput = () => (
    this.item.find(this.productInput).first()
  );

  this.getProductVariantInput = () => (
    this.item.find(this.productVariantInput).first()
  );

  this.getProductPrintCategoryInput = function() {
    return this.item.find(this.productPrintCategoryInput).first();
  };

  this.getProductPrintInput = function() {
    return this.item.find(this.productPrintInput).first();
  };

  this.getImageElement = function() {
    return this.item.find(this.imageElement).first();
  };

  this.getProductFieldsContainer = function() {
    return this.item.find(this.productFieldsContainer).first();
  };

  this.getProductPrintFieldsContainer = function() {
    return $(this.item.find(this.productPrintFieldsContainer).first());
  };

  this.setImage = (imageUrl) => {
    const $image = this.getImageElement();

    $image.css({
      backgroundImage: `url(${imageUrl})`,
    }).addClass('sample-request-item__image--active');
  };

  this.updateProductImage = () => {
    const $productVariantInput = this.getProductVariantInput();
    const $productInput = this.getProductInput();

    if ($productVariantInput.val() && $productVariantInput.val() !== '') {
      this.setImage(this.productVariants[$productVariantInput.val()].image);
    } else if ($productInput.val() && $productInput.val() !== '') {
      this.setImage(this.products[$productInput.val()].image);
    } else {
      this.removeImage();
    }
  };

  this.updateProductPrintImage = () => {
    const $input = this.getProductPrintInput();

    if ($input.val() && $input.val() !== '') {
      this.setImage(this.productPrints[$input.val()].image);
    } else {
      this.removeImage();
    }
  };

  this.updateImage = () => {
    if (this.areProductFieldsShown()) {
      this.updateProductImage();
    } else {
      this.updateProductPrintImage();
    }
  };

  this.removeImage = () => {
    const $image = this.getImageElement();

    $image
      .css({ backgroundImage: 'none' })
      .removeClass('sample-request-item__image--active');
  };

  this.updateProductVariantInput = (product_id) => {
    const component = this;
    const $input = this.getProductVariantInput();

    this.destroySelectInput($input);

    return this.productVariantsRequest(product_id).done((data) => {
      component.productVariants = data;
    });
  };

  this.destroySelectInput = ($input) => {
    $input.html('');
    select2.destroyByElement($input);
    $input.prop('disabled', true);
    $input.off('change');
  };

  this.disableProductVariantInput = () => {
    const $input = this.getProductVariantInput();
    this.productVariants = {};
    this.destroySelectInput($input);
    this.initProductVariantInput();
  };

  this.initProductInput = () => {
    const component = this;
    const $input = this.getProductInput();

    select2.initByElement($input);

    $input.on('change', () => {
      const value = $input.val();

      if (value && value !== '') {
        component.updateProductVariantInput(value).done((data) => {
          component.initProductVariantInput(data);
        });
        component.updateImage();
      } else {
        component.disableProductVariantInput();
        component.removeImage();
      }
    });
  };

  this.buildSelectOptions = (data) => {
    let options = "<option value=''></option>";

    $.each(data, (id, datum) => {
      options += `<option value='${datum.id}'>${datum.name}</option>`;
    });

    return options;
  };

  this.initProductVariantInput = (productVariants) => {
    const component = this;
    const $input = this.getProductVariantInput();
    const data = productVariants || this.productVariants || {};
    const inputOptions = this.buildSelectOptions(data);
    const presettedId = $input.data('product-variant-id');

    $input.html(inputOptions);
    select2.initByElement($input);

    $input.prop('disabled', (Object.keys(data).length === 0));

    if (presettedId && presettedId !== '') {
      $input.val(presettedId);
      $input.data('product-variant-id', null);
      $input.trigger('change');
    }

    $input.on('change', () => {
      component.updateImage();
    });
  };

  this.initProductPrintCategoryInput = () => {
    const component = this;
    const $productPrintCategoryInput = this.getProductPrintCategoryInput();

    select2.initByElement($productPrintCategoryInput);

    $productPrintCategoryInput.on('change', () => {
      component.updateProductPrintInput();

      const value = $(this).val();

      if (!value || value === '') {
        component.updateProductPrintInput();
      }
    });
  };

  this.initProductPrintInput = () => {
    const component = this;
    const $input = this.getProductPrintInput();
    select2.initByElement($input);

    $input.on('change', () => {
      component.updateImage();
    });

    $input.trigger('change');
  };

  this.updateProductPrintInput = () => {
    const $productPrintCategoryInput = this.getProductPrintCategoryInput();
    const $productPrintInput = this.getProductPrintInput();
    const categoryValue = $productPrintCategoryInput.val();
    const presettedId = $productPrintInput.data('product-print-id');
    this.productPrints = {};

    if (categoryValue === 'furnishings') { this.productPrints = gon.furnishings_product_prints; }
    if (categoryValue === 'leatherwear') { this.productPrints = gon.leatherwear_product_prints; }

    this.destroySelectInput($productPrintInput);

    const inputOptions = this.buildSelectOptions(this.productPrints);
    $productPrintInput.html(inputOptions);

    $productPrintInput.prop('disabled', (Object.keys(this.productPrints).length == 0));

    this.initProductPrintInput();

    if (presettedId && presettedId !== '') {
      $productPrintInput.val(presettedId);
      $productPrintInput.data('product-print-id', null);
      $productPrintInput.trigger('change');
    }
  };

  this.showProductFields = () => {
    this.getProductPrintFieldsContainer().addClass('disabled').removeClass('show').addClass('out');
    this.getProductFieldsContainer().removeClass('disabled').removeClass('out');

    setTimeout(() => {
      this.getProductFieldsContainer().addClass('show');
    }, 1);
  };

  this.showProductPrintFields = () => {
    const component = this;
    this.getProductFieldsContainer().addClass('disabled').removeClass('show').addClass('out');
    this.getProductPrintFieldsContainer().removeClass('disabled').removeClass('out');

    setTimeout(() => {
      component.getProductPrintFieldsContainer().addClass('show');
    }, 1);
  };

  this.toggleInputs = () => {
    if (this.areProductFieldsShown()) {
      this.showProductFields();
    } else {
      this.showProductPrintFields();
    }
  };

  this.initRadioInputsChange = () => {
    const $inputs = this.item.find(this.radioElements);
    const component = this;

    $inputs.on('change', () => {
      component.toggleInputs();
      component.updateImage();
    });
  };

  this.item = item;
  this.products = products;

  const component = this;
  const $productInput = this.getProductInput();

  this.toggleInputs();
  this.initRadioInputsChange();
  this.initProductInput();

  if ($productInput.val() && $productInput.val() !== '') {
    this.updateProductVariantInput($productInput.val()).done((data) => {
      component.initProductVariantInput(data);
      component.updateImage();
    });
  } else {
    this.initProductVariantInput();
  }

  this.initProductPrintCategoryInput();
  this.initProductPrintInput();
  this.updateProductPrintInput();
  this.updateImage();

  setTimeout(() => {
    $(item).removeClass('sample-request-item--hidden');
  }, 10);

  return this;
}

export default {
  init(item, products) {
    return (new SampleRequestItem(item, products));
  },

  destroy() {},
};
