import PropTypes from 'prop-types';
import { productPath } from '../api/products';

export const deserializeJsonApi = (json) => {
  if (!json || !json.data) { return []; }
  const { data } = json;

  return data.map(({ attributes }) => (attributes));
};

export const i18nShape = PropTypes.shape({
  save_color: PropTypes.string,
  saved_color: PropTypes.string,
  all_leathers: PropTypes.string,
  filter_by_color: PropTypes.string,
});

export const jsonApiShape = (shape) => (
  PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      attributes: shape,
    })),
    meta: PropTypes.shape({}),
  })
);

export const productCategoryShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const colorPaletteShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const productShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  abstract: PropTypes.string,
});

export const productVariantShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  product_id: PropTypes.number,
  product_name: PropTypes.string,
  product_slug: PropTypes.string,
});

export default {
  productPath,
  i18nShape,
  deserializeJsonApi,
  jsonApiShape,
  productCategoryShape,
  colorPaletteShape,
  productShape,
  productVariantShape,
};
