import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

const BackButton = ({ setShowFilter, showFilter, className }) => (
  <button
    className={classnames('btn btn-link FSM_BackButton', className)}
    onClick={() => { setShowFilter('menu'); }}
    disabled={showFilter === 'menu'}
  >
    <Icon name="undo-alt" family="fas" size="2x" fw />
  </button>
);

export default BackButton;
