import _ from 'lodash';

export const getCsrfToken = () => (
  document.querySelector('meta[name="csrf-token"]').content
);

export const defaultHeaders = () => {
  const headers = new Headers();

  headers.append('Content-type', 'application/json');
  headers.append('Accept', 'application/json, text/plain, */*');
  headers.append('X-CSRF-Token', getCsrfToken());

  return headers;
};

export const toParams = (params = {}) => (
  Object.keys(params).map((key) => (`${key}=${params[key]}`)).join('&')
);

export const get = (url, params = {}, options = {}) => {
  const queryString = Object.keys(params).map((key) => (`${key}=${params[key]}`)).join('&');
  const joiner = _.includes(url, '?') ? '&' : '?';
  const endpoint = queryString && queryString.length > 0
    ? [url, queryString].join(joiner)
    : url;

  return (
    fetch(endpoint, {
      method: 'GET',
      headers: defaultHeaders(),
      ...options,
    })
  );
};

export const post = (url, payload = {}, options = {}) => (
  fetch(url, {
    method: 'POST',
    headers: defaultHeaders(),
    body: JSON.stringify(payload),
    ...options,
  })
);

export const patch = (url, payload = {}, options = {}) => (
  fetch(url, {
    method: 'PATCH',
    headers: defaultHeaders(),
    body: JSON.stringify(payload),
    ...options,
  })
);

export const httpDelete = (url, payload = {}, options = {}) => (
  fetch(url, {
    method: 'DELETE',
    headers: defaultHeaders(),
    body: JSON.stringify(payload),
    ...options,
  })
);

export const fetchPaginatedResources = async ({ fetchApi, ...options }) => {
  const extractAttrs = (data) => (data.map(({ attributes }) => (attributes)));
  const initialResponse = await fetchApi({ page: 1 });
  const initialPage = await initialResponse.json();
  const { meta, data } = initialPage;
  const { pages } = meta;

  if (pages <= 1) { return extractAttrs(data); }

  const eachBatch = options.eachBatch || (() => {});
  eachBatch(extractAttrs(data));

  const promises = _.range(2, pages + 1).map(async (page) => {
    const response = await fetchApi({ page });
    const { data } = await response.json();
    eachBatch(extractAttrs(data));

    return data;
  });

  const results = await Promise.all(promises);
  const entries = extractAttrs(_.flattenDeep(_.concat(data, results)));

  return entries;
};

export const pathWithFormat = (path, options = {}) => {
  if (!options.format) { return path; }

  return `${path}.${options.format}`;
};

export const currentLocale = () => (gon.locale);

export default {
  toParams,
  pathWithFormat,
  fetchPaginatedResources,
  getCsrfToken,
  get,
  post,
  patch,
  httpDelete,
  currentLocale,
};
