import React from 'react';
import classnames from 'classnames';

import Icon from '../../Icon';

const IconBadge = ({ open, onClick }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    onClick();
  };

  return (
    <a
      className={classnames('btn btn-link text-light text-start ps-0 d-none', { active: open })}
      onClick={handleClick}
      href="#download"
    >
      <h6>Download</h6>
      <Icon
        name="file-pdf"
        size="2x"
      />
    </a>
  );
}

IconBadge.propTypes = {};

export default IconBadge;

