import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';

export default {
  defaultSelector: 'select.select:not(.select-alternate)',

  defaultOptions: {
    theme: 'bootstrap-5',
    // theme: 'default',
    width: '100%',
  },

  initByElement($el, options) {
    const extendedOptions = $.extend(this.defaultOptions, options || {});
    return $el.select2(extendedOptions);
  },

  init(selector, options) {
    const currentSelector = selector || this.defaultSelector;
    return this.initByElement($(currentSelector), options);
  },

  initByParent($parent, selector, options) {
    const currentSelector = selector || this.defaultSelector;
    return this.initByElement($parent.find(currentSelector), options);
  },

  destroyByElement($el) {
    $el.select2('destroy');
  },

  destroy(selector) {
    const currentSelector = selector || this.defaultSelector;
    this.destroyByElement($(currentSelector));
  },
};
