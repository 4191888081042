import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterButton from './FilterButton';
import Icon from '../Icon';
import { priceKeys } from './utils';

const FiltersSecondaryMenuFilterPrice = ({
  i18n,
  show,
  toggleCurrentPrice,
  currentPrices,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'price';
  const { configurator_filters, configurator_price_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterPrice',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );

  const handleClick = (id) => { toggleCurrentPrice(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className="col-sm-4">
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            priceKeys.map((key) => (
              <div className="col-sm-4" key={key}>
                <FilterButton
                  key={key}
                  onClick={(event) => (handleClick(key))}
                  className={`${key} my-3`}
                  label={configurator_price_filters[key]}
                  active={_.includes(currentPrices, key)}
                />
              </div>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterPrice;
