//
// Boot custom/legacy page behaviours
//

// Behaviours
import sampleRequestsBehaviour from './sample_requests';
import distributors from './distributors';
import contacts from './contacts';

// Widgets
import select2 from './widgets/select2';
import scrolldown from './widgets/scrolldown';
import cookiesConsent from './widgets/cookiesConsent';
import scrollify from './widgets/scrollify';
import infiniteScroller from './widgets/infiniteScroller';

const bootstraps = {
  // 'homepage#index': homepage,
  // 'products#index': products,
  // 'serigraphy_products#index': products,
  // 'company#index': company,
  'sample_requests#new': sampleRequestsBehaviour,
  'sample_requests#create': sampleRequestsBehaviour,
  'distributors#index': distributors,
  'contacts#new': contacts,
  'contacts#create': contacts,
};

const getBoostrap = () => {
  const key = gon.controller_name + '#' + gon.action_name;
  return bootstraps[key];
};

document.addEventListener('turbo:load', async () => {
  select2.init();
  scrolldown.init();
  cookiesConsent.init();
  scrollify.init();
  infiniteScroller.init();

  const bootstrap = getBoostrap();
  if (bootstrap) { bootstrap.init(); }
});

document.addEventListener('turbo:before-render', async () => {
  select2.destroy();
  scrolldown.destroy();
  cookiesConsent.destroy();
  scrollify.destroy();

  const bootstrap = getBoostrap();
  if (bootstrap) { bootstrap.destroy(); }
});
