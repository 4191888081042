import $ from 'jquery';
import sampleRequestItem from './sampleRequestItem';

export default {
  container: '#sample-request-items-nested-form',

  listeners: [],

  getContainer() {
    return document.getElementById('sample-request-items-nested-form');
  },

  on(type, handler) {
    this.listeners = [
      ...this.listeners,
      { type, handler },
    ];

    const $container = this.getContainer();
    return $container.addEventListener(type, handler);
  },

  off(type, cb = null) {
    const $container = this.getContainer();

    return $container.removeEventListener(type, cb);
  },

  destroy() {
    this.listeners.forEach(({ type, handler }) => { this.off(type, handler); });
  },

  // showNestedItems() {
  //   $('.sample-request-item').removeClass('sample-request-item--hidden');
  // },

  initPendingComponents() {
    $('.sample-request-item--hidden').each((idx, item) => {
      sampleRequestItem.init($(item), gon.products);
    });
  },

  handleAfterInsert() {
    this.initPendingComponents();
  },

  initNewComponents() {
    this.on('cocoon:after-insert', () => (this.handleAfterInsert()));
  },

  // initNestedComponents() {
  //   this.on('cocoon:after-insert', (e, item) => {
  //     console.log({ e, item }, 'yeppa')
  // //     sampleRequestItem.init(item, gon.products);
  //   });
  // },

  init() {
    this.destroy();
    // this.showNestedItems();
    this.initPendingComponents();
    this.initNewComponents();
    /// component.initNestedComponents();
  },
};
