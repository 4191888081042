import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Pie from './Pie';
import BarChart from './BarChart';

import boxImage from '../../images/balance-planet-box.png';
import energyImage from '../../images/balance-planet-energy.png';
import greenStarImage from '../../images/balance-green-star.png';

const slideColor = '#3E9EA3';
const slideColorAlt = '#3E7076';

const SlidePlanet = ({ code, label, i18n, active }) => {
  const {
    one, two, three, four, five, six, seven, eight, nine,
  } = i18n;

  return (
    <div className="BalanceSliderSlide BalanceSliderSlide--planet">
      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-one with-arrow">
          <div className="">
            <h5 className="mb-5">{one.title}</h5>

            <h4 className="BalanceSliderSlide__title-alt mb-0">
              {one.co2_amount}
              <br />
              <small>{one.recovered}</small>
            </h4>
          </div>
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-two">
          <BarChart
            rows={5}
            cols={[
              { label: '2019', height: '39%', legend: '58.000 kg',
                color: slideColorAlt, left: 0 },
              { label: '2020', height: '64%', legend: '65.000 kg',
                color: slideColorAlt, left: '40%' },
              { label: '2021', labelClassName: 'h6 mb-0', height: '60%',
                legend: '60.000 kg', color: slideColorAlt, left: '75%' },
              { label: null, height: '73%', legend: '73.500 kg',
                color: slideColor, left: '85%' },
            ]}
            active={active}
          />
        </div>

        <div className="col-lg-4 col-md-6 BalanceSliderSlide__step BalanceSliderSlide__step-three">
          <ul className="BalanceSliderSlide__ul pe-5">
            <li>
              {three.list_item_1}
            </li>

            <li className="alt">
              {three.list_item_2}
            </li>
          </ul>

          <h4 className="BalanceSliderSlide__title-alt">
            <span className="BalanceSliderSlide__strong-number-3">
              +130
            </span>
            <span style={{ verticalAlign: 'super' }}>%</span>
          </h4>
        </div>
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-four with-arrow">
          <h5 className="mb-5">{four.title}</h5>

          <img
            src={boxImage}
            className="d-block img-fluid w-50 pe-4"
          />
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-five">
          <div className="row">
            <div className="col-sm-7 col-md-12 col-xl-7">
              <h6
                className="BalanceSliderSlide__title text-nowrap ps-2"
                style={{ borderLeft: `15px solid ${slideColorAlt}` }}
              >
                {five.list_item_1}
              </h6>
            </div>

            <div className="col-sm-5 col-md-12 col-xl-5">
              <h6
                className="BalanceSliderSlide__title-alt text-nowrap ps-2"
                style={{ borderLeft: `3px solid ${slideColor}` }}
              >
                {five.list_item_2}
              </h6>
            </div>
          </div>

          <div className="py-5">
            <BarChart
              rows={5}
              cols={[
                { label: '2019', width: '50px', height: '57%', color: slideColorAlt, left: '18%' },
                { label: '2021', width: '50px', labelClassName: 'h6 mb-0', height: '85%', color: slideColorAlt, left: '70%' },
              ]}
              active={active}
            />
          </div>

          <h6 className="mt-5 small BalanceSliderSlide__text">
            {five.text}
          </h6>

          <div className="BalanceSliderSlide__arrow" />
        </div>

        <div className="col-lg-4 col-md-6 BalanceSliderSlide__step BalanceSliderSlide__step-six">
          <h6 className="BalanceSliderSlide__text mt-5">
            <span className="BalanceSliderSlide__strong-number-5">
              -23
            </span>
            <span style={{ verticalAlign: 'super' }}>%</span>

            <br />
            <span>{six.text}</span>
          </h6>
        </div>
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-seven with-arrow">
          <h5 className="mb-5">{seven.title}</h5>

          <img
            src={energyImage}
            className="d-block img-fluid w-25"
          />
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-eight">
          <ul className="BalanceSliderSlide__ul mb-5">
            <li>
              {eight.list_item_1}
            </li>
          </ul>

          <div>
            <BarChart
              rows={3}
              cols={[
                { label: '2019', height: '60%', color: slideColorAlt, left: 0 },
                { label: '2020', height: '55%', color: slideColorAlt, left: '37%' },
                { label: '2021', labelClassName: 'h6 mb-0', height: '68%', color: slideColorAlt, left: '75%' },
              ]}
              active={active}
            />
          </div>
        </div>

        <div className="col-lg-4 col-md-6 BalanceSliderSlide__step BalanceSliderSlide__step-nine">
          <div className="row">
            <div className="col-sm-6">
              <h6 className="BalanceSliderSlide__text my-5">
                <span className="BalanceSliderSlide__strong-number-4">
                  -16
                </span>
                <span style={{ verticalAlign: 'super' }}>%</span>

                <br />

                <span>{nine.text}</span>
              </h6>
            </div>

            <div className="col-sm-6">
              <img
                src={greenStarImage}
                className="img-fluid p-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SlidePlanet.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SlidePlanet;
