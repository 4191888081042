import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import Context from './Context';
import CategoriesFilters from './CategoriesFilters';
import ColorsFilters from './ColorsFilters';

const ProductsFilters = () => {
  const { showProductsFilters, filtersRef } = useContext(Context);

  const className = classnames('ProductsFilters', {
    active: showProductsFilters,
  });

  return (
    <div className={className} ref={filtersRef}>
      <CategoriesFilters />
      <ColorsFilters />
    </div>
  );
};

ProductsFilters.propTypes = {
};

ProductsFilters.defaultProps = {
};

export default ProductsFilters;
