import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { productShape } from '../utils';
import Context from './Context';
import ProductTileSpecs from './ProductTileSpecs';
import ComposedImage from '../ComposedImage';

const FallbackImage = ({ product }) => {
  const {
    name,
    image,
  } = product;
  const imageUrl = (image || {}).default;

  return (
    <img
      src={imageUrl}
      alt={name}
    />
  );
};

const ProductImage = ({ product }) => {
  const { has_composed_image } = product;
  const Image = has_composed_image ? ComposedImage : FallbackImage;

  return (<Image product={product} thumb />);
};

const ProductTile = ({ product }) => {
  const { productPath } = useContext(Context);
  const { name, abstract } = product;
  const url = productPath({ product });

  return (
    <a className="ProductTile row row--no-gutter" href={url}>
      <div className="col-sm-5 ProductTile__info">
        <h3 className="ProductTile__title btn btn--border">
          {name}
        </h3>

        <p className="ProductTile__abstract">
          {abstract}
        </p>

        <ProductTileSpecs
          product={product}
        />
      </div>

      <div className="col-sm-7 ProductTile__image">
        <ProductImage product={product} />
      </div>
    </a>
  );
};

ProductTile.propTypes = {
  product: productShape.isRequired,
};

ProductTile.defaultProps = {
};

export default ProductTile;
