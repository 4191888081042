import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Ruler from '../Ruler';
import AddColorButton from '../AddColorButton';
import { productPath } from '../../api/products';
import { i18nShape } from '../utils';

const ColorDetails = ({
  locale,
  color,
  i18n,
  onColorAddedToCart,
  isStarred,
  showInfoButton,
  artemida,
}) => {
  const {
    id,
    image,
    name,
    product_name,
    image_percentage_ruler,
  } = color;
  const title = [product_name, name].join(' - ');
  const imageUrl = (image || {}).zoom;
  const product = {
    slug: color.product_slug,
    id: color.product_id,
  };
  const url = productPath({ locale, product, artemida });

  return (
    <div className="variant-image row pt-5 pb-1">
      <div className="col-lg-7 offset-lg-1 col-sm-8">
        <Ruler percentage={image_percentage_ruler}>
          <img
            src={imageUrl}
            alt={title}
            className="variant-image__image"
          />
        </Ruler>
      </div>

      <div className="col-lg-4 col-sm-4">
        <h3 className="title title--compact variant-image__title">
          {product_name}
          <div className="title__separator" />
        </h3>

        <h5 className="title__subtitle">{name}</h5>

        <hr />

        {
          showInfoButton && (
            <Button href={url} variant="secondary" className="text-uppercase">
              {i18n.product_details}
            </Button>
          )
        }

        <AddColorButton
          className="variant-image__star mt-3"
          isStarred={isStarred}
          onClick={onColorAddedToCart}
          colorId={id}
          i18n={i18n}
          showLabel
        />
      </div>
    </div>
  );
};

ColorDetails.propTypes = {
  i18n: i18nShape.isRequired,
  color: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  onColorAddedToCart: PropTypes.func.isRequired,
  isStarred: PropTypes.bool.isRequired,
  showInfoButton: PropTypes.bool,
  artemida: PropTypes.bool,
};

ColorDetails.defaultProps = {
  showInfoButton: true,
  artemida: false,
};

export default ColorDetails;
