import React from 'react';
import _ from 'lodash';

import PrintTile from './PrintTile';

const PrintsSlide = ({
  prints,
  cartPrintIds,
  onCartButtonClick,
  onZoomButtonClick,
}) => (
  <div className="container-fluid">
    <div className="row">
      {
        _.compact(prints).map((print) => (
          <PrintTile
            key={print.id}
            print={print}
            cartPrintIds={cartPrintIds}
            onCartButtonClick={onCartButtonClick}
            onZoomButtonClick={onZoomButtonClick}
          />
        ))
      }
    </div>
  </div>
);

export default PrintsSlide;
