import React from 'react';
import classnames from 'classnames';
import Icon from '../../Icon';

const FormModal = ({ open, content, loading, onToggle }) => {
  const handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onToggle();
  }

  return (
    <div
      className={classnames('ConfiguratorDownloadModal__FormModal p-3', { active: open, loading })}
    >
      <a
        href="#close"
        className={classnames('ConfiguratorDownloadModal__close')}
        onClick={handleCloseClick}
      >
        <Icon
          name="times"
          size="2x"
          fw
        />
      </a>

      <div
        className={classnames('ConfiguratorDownloadModal__FormModalContent', { loading })}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

FormModal.propTypes = {};

export default FormModal;
