import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';

import Pager from './Pager';
import Slide from './Slide';

const settings = {
  arrows: false,
  customPaging: null,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  draggable: false,
  fade: true,
  adaptiveHeight: true
};

const scrollTo = (chunk, wait = 300) => {
  setTimeout(() => {
    document.getElementById(chunk).scrollIntoView();
  }, wait);
};

const BalanceSlider = ({ sections, i18n }) => {
  const sliderRef = useRef(null);
  const initialSection = sections[0].code;
  const [currentSection, setCurrentSection] = useState(initialSection);
  const handleClick = (code) => {
    setCurrentSection(code);

    const currentIndex = sections.findIndex((section) => (
      code === section.code
    ));

    sliderRef.current.slickGoTo(currentIndex);

    scrollTo('balance-slider');
  };

  return (
    <div className="BalanceSlider">
      <div className="container">
        <div className="row BalanceSlider__pager">
          {
            sections.map(({ code, label }) => (
              <Pager
                key={code}
                code={code}
                label={label}
                active={code === currentSection}
                onClick={(code) => { handleClick(code); }}
                i18n={i18n[code]}
              />
            ))
          }
        </div>
      </div>

      <div className="BalanceSlider__wrapper">
        <div className="BalanceSlider__container container" id="balance-slider">
          <Slider
            {...settings}
            className="BalanceSlider__slider"
            ref={sliderRef}
          >
            {
              sections.map(({ code, label }) => (
                <Slide
                  key={code}
                  code={code}
                  label={label}
                  i18n={i18n[code]}
                  active={code === currentSection}
                />
              ))
            }
          </Slider>
        </div>
      </div>
    </div>
  );
};

BalanceSlider.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    code:  PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired
};

export default BalanceSlider;
