import styled from 'styled-components';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-100%)')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  text-align: left;
  position: absolute;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, top 0.3s ease-in-out;
`;

export default StyledMenu;
