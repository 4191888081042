import { get, post, currentLocale } from './utils';
import { productsPath } from './products';

const basePath = ({ locale }) => (
  `/${locale || currentLocale()}/articles/user_configurations`
);

export const newUserConfiguratorPath = ({ locale }) => (
  `${basePath({ locale })}/new`
);

export const createUserConfiguratorPath = ({ locale }) => (
  `${basePath({ locale })}`
);

export const newUserConfiguration = (options) => (
  get(productsPath(options, newUserConfiguratorPath))
);

export const createUserConfiguration = ({ params, ...options }) => (
  post(productsPath(options, createUserConfiguratorPath), {
    products_user_configuration: params
  })
);

export default {
  newUserConfiguration,
  createUserConfiguration,
};
