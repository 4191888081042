import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const Pie = ({ value, color, colorAlt, altLabel = '', active }) => {
  const styles = {
    borderColor: color,
  }

  const chartComponent = (
    <PieChart
      data={[{ value, color }]}
      startAngle={270}
      totalValue={100}
      lineWidth={40}
      label={({ dataEntry }) => `${dataEntry.value}%`}
      labelStyle={{
        fontSize: '22px',
        fontFamily: 'Metropolis',
        fontWeight: 600,
        fill: colorAlt,
      }}
      labelPosition={0}
      background="#f8f9fa"
      animationDuration={1500}
      animate
    />
  );

  const altTextComponent = (
    <div className="PieChart__altText">
      <div className="PieChart__altTextBody d-flex justify-content-center align-items-center">
        {altLabel}
      </div>
    </div>
  );

  return (
    <div className="PieChart" style={styles}>
      {
        active ? chartComponent : altTextComponent
      }
    </div>
  );
};

export default Pie;
