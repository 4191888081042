import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterItem from './FilterItem';
import Icon from '../Icon';
import imageFlowerFull from '../../images/flf-flower_full.jpg';
import imageFlowerCorrected from '../../images/flf-flower_corrected.jpg';
import imageFlowerHalf from '../../images/flf-flower_half.jpg';
import imageFlowerLaminated from '../../images/flf-flower_laminated.jpg';

const FiltersSecondaryMenuFilterFlower = ({
  i18n,
  show,
  toggleCurrentFlowerType,
  currentFlowerTypes,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'flower';
  const { configurator_filters, configurator_flower_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterFlower',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );
  const flowerKeys = [
    'flower_full',
    'flower_corrected',
    'flower_half',
    'flower_laminated',
  ];
  const flowerImages = {
    flower_full: imageFlowerFull,
    flower_corrected: imageFlowerCorrected,
    flower_half: imageFlowerHalf,
    flower_laminated: imageFlowerLaminated,
  };

  const handleClick = (id) => { toggleCurrentFlowerType(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className={classnames('col-sm-4')}>
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            flowerKeys.map((key, idx) => (
              <React.Fragment key={key}>
                <div className={classnames('col-sm-4 my-3')}>
                  <FilterItem
                    key={key}
                    id={key}
                    name={configurator_flower_filters[key]}
                    image={flowerImages[key]}
                    onClick={(event) => (handleClick(key))}
                    active={_.includes(currentFlowerTypes, key)}
                  />
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterFlower;
