import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TrendColor = ({
  code, label, index, link,
}) => {
  const ref = useRef(null);
  const handleClick = (e) => {
    e.preventDefault();

    if (link && global.Turbo) {
      global.Turbo.visit(link);
    }
  };

  return (
    <div
      ref={ref}
      className={classnames('TrendColor', `TrendColor--${code}`)}
      onClick={handleClick}
    >
      <div className="TrendColor__content">
        <div className="TrendColor__bg" />
        <h6 className="TrendColor__caption">
          <span className="TrendColor__captionIndex">
            {index}
            .o
          </span>
          <span className="TrendColor__captionLabel">{label}</span>
        </h6>
      </div>
    </div>
  );
};

TrendColor.propTypes = {
  label: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default TrendColor;
