import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { productShape } from './utils';

const imgUrl = (image) => ((image || { default: {} }).default.url);

const ComposedImage = ({ product, thumb }) => {
  const {
    name,
    image,
    cover_image,
    wave_image,
    detail_image,
    image_percentage_ruler,
  } = product;
  const leftUrl = imgUrl(cover_image);
  const rightUrl = imgUrl(wave_image);
  const centerUrl = imgUrl(detail_image);

  const className = classnames('product-detail__composed-image', {
    'product-detail__composed-image--thumb': thumb,
  });

  return (
    <>
      <div className={className}>
        <div
          className="product-detail__composed-left-image"
          style={{ backgroundImage: `url(${leftUrl})` }}
        />
        <div
          className="product-detail__composed-right-image"
          style={{ backgroundImage: `url(${rightUrl})` }}
        />
        <div className="product-detail__composed-center-image-wrapper d-flex justify-content-center align-items-center">
          <div
            className="product-detail__composed-center-image"
            style={{ backgroundImage: `url(${centerUrl})` }}
          />
        </div>
      </div>
    </>
  );
};

ComposedImage.propTypes = {
  product: productShape.isRequired,
  thumb: PropTypes.bool,
};

ComposedImage.defaultProps = {
  thumb: false
};

export default ComposedImage;
