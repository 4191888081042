import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { newChatContact, createChatContact } from '../../api/chatContacts';

const Context = createContext({});
const formId = '#new_chat_contact';
const closeButtonSel = '.chat-contact-close';

export const Provider = ({ children, i18n, }) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('Loading...');
  const [internalLinkContent, setInternalLinkContent] = useState(null);

  const showContent = !internalLinkContent;
  const showInternalLinkContent = !!internalLinkContent;

  const onChatToggle = () => { setOpen((o) => (!o)) }
  const loadRemoteContent = () => {
    newChatContact({ locale: gon.locale })
      .then((response) => (response.text()))
      .then((response) => {
        setInternalLinkContent(null);
        setContent(response);
        setLoading(false);
      })
      .catch((error) => {
        setInternalLinkContent(null);
        setContent('Ops.. Somethink went wrong');
        setLoading(false);
      });
  };

  const handleInternalLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const $a = $(event.target);

    setLoading(true);

    $.get($a.attr('href')).then((response) => {
      setInternalLinkContent(response);
      setLoading(false);
    })
  };

  // Component did mount
  useEffect(() => {
    let mounted = true;
    if (mounted) { loadRemoteContent(); }

    $('body').on('click', '.internal-link', handleInternalLinkClick);

    return () => {
      mounted = false;
      $('body').off('click', '.internal-link', handleInternalLinkClick);
    };
  }, []);

  const handleBack = () => { setInternalLinkContent(null); };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    // const qString =  $(formId).serialize();
    const rawData =  $(formId).serializeArray();

    var params = rawData.reduce((obj, item) => {
      if (item.name.substr(0, 13) === 'chat_contact[') {
        // "chat_contact[foo]".substring(13, "chat_contact[foo]".lenght - 1)
        obj[item.name.substring(13, item.name.length - 1)] = item.value;
      } else {
        obj[item.name] = item.value;
      }

      return obj;
    }, {});

    createChatContact({ locale: gon.locale, params })
      .then((response) => (response.text()))
      .then((response) => {
        setContent(response);
        setLoading(false);
      })
      .catch((error) => {
        setContent('Ops.. Somethink went wrong');
        setLoading(false);
      });
  }

  // On content change
  useEffect(() => {
    $(formId).off('submit', handleSubmit);
    $(formId).on('submit', handleSubmit);
    $(closeButtonSel).off('click');
    $(closeButtonSel).on('click', () => { setOpen(false); });

    return () => {
      $(formId).off('submit', handleSubmit);
      $(closeButtonSel).off('click');
    };
  }, [content]);

  // The shared state
  const value = useMemo(
    () => ({
      loading,
      open,
      onChatToggle,
      content,
      showContent,
      onBack: handleBack,
      internalLinkContent,
      showInternalLinkContent,
      i18n,
    }),
    [
      loading,
      open,
      content,
      internalLinkContent,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {};

export default Context;
