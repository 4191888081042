import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import FormModal from './FormModal';
import IconBadge from './IconBadge';
import Icon from '../../Icon';

const Body = (props) => {
  const {
    open,
    loading,
    onToggle,
    content,
  } = useContext(Context);

  return (
    <div
      className={classnames('ConfiguratorDownloadModalProducts__Body')}
    >
      <FormModal
        open={open}
        content={content}
        loading={loading}
        onToggle={onToggle}
      />

      <IconBadge
        open={open}
        onClick={onToggle}
      />
    </div>
  );
};

Body.propTypes = {};

export default Body;

