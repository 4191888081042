import React from 'react';
import PropTypes from 'prop-types';

import ColorTileWrapper from '../ColorTileWrapper';
import ColorTile from '../ColorTile';
import { productShape } from '../utils';
import Context from './Context';

const ColorsView = ({ products }) => (
  <>
    {
      products.map((color, index) => (
        <ColorTileWrapper index={index} key={color.id}>
          <ColorTile
            Context={Context}
            key={color.id}
            color={color}
            index={index}
          />
        </ColorTileWrapper>
      ))
    }
  </>
);

ColorsView.propTypes = {
  products: PropTypes.arrayOf(productShape).isRequired,
};

ColorsView.defaultProps = {
};

export default ColorsView;
