import $ from 'jquery';

export default {
  mapSelectorId: 'contacts-map',

  mapHandler: null,

  mapStyles: [
    {
      featureType: 'landscape',
      stylers: [{'hue':'#FFBB00'},{'saturation':43.400000000000006},{'lightness':37.599999999999994},{'gamma':1}]
    },
    {
      featureType: 'road.highway',
      stylers: [{'hue':'#FFC200'},{'saturation':-61.8},{'lightness':45.599999999999994},{'gamma':1}]
    },
    {
      featureType: 'road.arterial',
      stylers: [{'hue':'#FF0300'},{'saturation':-100},{'lightness':51.19999999999999},{'gamma':1}]
    },
    {
      featureType: 'road.local',
      stylers: [{'hue':'#FF0300'},{'saturation':-100},{'lightness':52},{'gamma':1}]
    },
    {
      featureType: 'water',
      stylers: [{'hue':'#0078FF'},{'saturation':-13.200000000000003},{'lightness':2.4000000000000057},{'gamma':1}]
    },
    {
      featureType: 'poi',
      stylers: [{'hue':'#00FF6A'},{'saturation':-1.0989010989011234},{'lightness':11.200000000000017},{'gamma':1}]
    }
  ],

  mapDefaultOptions: {
    zoom: 10,
    scrollwheel: false
  },

  getOptions() {
    const options = this.mapDefaultOptions;
    options.center = gon.map_center
    return options;
  },

  createMarker(datum) {
    const component = this;
    const infoWindow = new google.maps.InfoWindow({ content: datum.partial });

    const marker = new google.maps.Marker({
      position: datum.position,
      map: this.mapHandler,
      title: datum.title,
      animation: google.maps.Animation.DROP
    });

    marker.addListener('click', () => {
      infoWindow.open(component.mapHandler, marker);
    });
  },

  createMarkers(data) {
    const component = this;

    $.each(data, (index, value) => {
      component.createMarker(value);
    });
  },

  init() {
    const wrapper = document.getElementById(this.mapSelectorId);
    this.mapHandler = new google.maps.Map(wrapper, this.getOptions());
    this.mapHandler.setOptions({ styles: this.mapStyles });
    this.createMarkers(gon.map_markers);
  },

  destroy() {},
};
