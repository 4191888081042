import React from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import FiltersSecondaryMenuContent from './FiltersSecondaryMenuContent';
import FiltersSecondaryMenuFooter from './FiltersSecondaryMenuFooter';
import Icon from '../Icon';

const FiltersSecondaryMenu = ({
  i18n,
  showFilter,
  setShowFilter,
  closeFiltersMenu,
  open,

  productCategories,
  toggleCurrentCategory,
  currentCategoryIds,

  colorPalettes,
  toggleCurrentColor,
  currentColors,

  toggleCurrentPrice,
  currentPrices,

  toggleCurrentThickness,
  currentThicknesses,

  toggleCurrentFlowerType,
  currentFlowerTypes,

  toggleCurrentTextureType,
  currentTextureTypes,

  toggleCurrentAnilinaType,
  currentAnilinaTypes,

  toggleCurrentAppearanceType,
  currentAppearanceTypes,
}) => {
  const onFilterSelection = (filter) => { setShowFilter(filter); };

  return (
    <div className="FiltersSecondaryMenu">
      <FiltersSecondaryMenuContent
        onFilterSelection={onFilterSelection}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        i18n={i18n}

        productCategories={productCategories}
        toggleCurrentCategory={toggleCurrentCategory}
        currentCategoryIds={currentCategoryIds}

        colorPalettes={colorPalettes}
        toggleCurrentColor={toggleCurrentColor}
        currentColors={currentColors}

        toggleCurrentPrice={toggleCurrentPrice}
        currentPrices={currentPrices}

        toggleCurrentThickness={toggleCurrentThickness}
        currentThicknesses={currentThicknesses}

        toggleCurrentFlowerType={toggleCurrentFlowerType}
        currentFlowerTypes={currentFlowerTypes}

        toggleCurrentTextureType={toggleCurrentTextureType}
        currentTextureTypes={currentTextureTypes}

        toggleCurrentAnilinaType={toggleCurrentAnilinaType}
        currentAnilinaTypes={currentAnilinaTypes}

        toggleCurrentAppearanceType={toggleCurrentAppearanceType}
        currentAppearanceTypes={currentAppearanceTypes}
      />

      <FiltersSecondaryMenuFooter
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        closeFiltersMenu={closeFiltersMenu}
        i18n={i18n}
      />
    </div>
  );
};

export default FiltersSecondaryMenu;
