import { get, post, httpDelete } from './utils';

const basePath = ({ locale }) => (
  `/${locale || 'it'}/sample_request_cart`
);

// Product

const productPath = ({ locale, productId }) => (
  `${basePath({ locale })}/product/${productId}.json`
);

export const addProductToCart = ({ productId, locale }) => (
  post(productPath({ locale, productId }))
);

export const removeProductFromCart = ({ productId, locale }) => (
  httpDelete(productPath({ locale, productId }))
);

// Color

const colorPath = ({ locale, colorId }) => (
  `${basePath({ locale })}/variant/${colorId}.json`
);

export const addColorToCart = ({ colorId, locale }) => (
  post(colorPath({ locale, colorId }))
);

export const removeColorFromCart = ({ colorId, locale }) => (
  httpDelete(colorPath({ locale, colorId }))
);

// Print

const printPath = ({ locale, printId }) => (
  `${basePath({ locale })}/print/${printId}.json`
);

export const addPrintToCart = ({ printId, locale }) => (
  post(printPath({ locale, printId }))
);

export const removePrintFromCart = ({ printId, locale }) => (
  httpDelete(printPath({ locale, printId }))
);

// Show/Hide cart

export const showCartBadge = (props = {}) => (
  get(`/${props.locale || 'it'}/sample_request/show_badge.json`)
);

export const hideCartBadge = (props = {}) => (
  get(`/${props.locale || 'it'}/sample_request/hide_badge.json`)
);

export default {
  addColorToCart,
  removeColorFromCart,

  addProductToCart,
  removeProductFromCart,

  addPrintToCart,
  removePrintFromCart,

  showCartBadge,
  hideCartBadge,
};
