import React from 'react';

import Ruler from '../Ruler';

const FallbackImage = ({ product }) => {
  const {
    name,
    image,
    image_percentage_ruler,
  } = product;
  const imageUrl = (image || {}).default;

  return (
    <>
      <Ruler percentage={image_percentage_ruler}>
        <img
          src={imageUrl}
          alt={name}
          className="product-detail__image"
        />
      </Ruler>
    </>
  );
};

export default FallbackImage;
