import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import PrintsSlide from './PrintsSlide';

const defaultSlickSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 650,
  lazyLoad: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
};

const inGroupsOf = (array, number, fillWith = null) => {
  let index = -number;
  const slices = [];

  if (number < 1) { return array; }

  while ((index += number) < array.length) {
    const s = array.slice(index, index + number);

    while (s.length < number) {
      s.push(fillWith);
    }

    slices.push(s);
  }
  return slices;
};

const PrintsSlider = ({
  prints,
  i18n,
  onCartButtonClick,
  onZoomButtonClick,
  cartPrintIds,
}) => {
  const settings = { ...defaultSlickSettings };
  const groupedPrints = inGroupsOf(prints, 6);

  return (
    <div className={classnames('PrintsSlider')}>
      <Slider
        {...settings}
        initialSlide={0}
        className="slick-slider--prints-gallery"
      >
        {
          groupedPrints.map((entries) => (
            <PrintsSlide
              key={entries[0].id}
              prints={entries}
              cartPrintIds={cartPrintIds}
              onCartButtonClick={onCartButtonClick}
              onZoomButtonClick={onZoomButtonClick}
            />
          ))
        }
      </Slider>
    </div>
  );
};

PrintsSlider.propTypes = {
  prints: PropTypes.arrayOf(PropTypes.shape({})),
//   i18n: i18nShape.isRequired,
//   productCategories: productCategoryShape.isRequired,
};

PrintsSlider.defaultProps = {
  prints: [],
};

export default PrintsSlider;
