import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import DefaultHeader from './Header';
import ArtemidaHeader from './ArtemidaHeader';
import ProductsFilters from './ProductsFilters';
import Body from './Body';

const Layout = () => {
  const { artemida } = useContext(Context);
  const Header = artemida ? ArtemidaHeader : DefaultHeader;

  return (
    <div className={classnames('ProductsPage')}>
      <Header />
      <div className="scrollify">
        <ProductsFilters />
        <Body />
      </div>
    </div>
  );
};

Layout.propTypes = {
};

Layout.defaultProps = {
};

export default Layout;
