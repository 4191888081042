import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InstafeedItem from './InstafeedItem';

const Instafeed = ({ endpoint }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch(endpoint);
      response = await response.json();

      setItems(response.data);
    };

    if (items === null) { fetchData(); }
  });

  return (
    <div className="Instafeed pb-5">
      <div className="row">
        {
          (items || []).map((item) => (
            <InstafeedItem {...item} key={item.id} />
          ))
        }
      </div>
    </div>
  );
};

Instafeed.propTypes = {
  endpoint: PropTypes.string,
};

Instafeed.defaultProps = {
  endpoint: '/en/instafeed.json',
};

export default Instafeed;
