import React, { useContext } from 'react';

import Context from './Context';
import ArticlesGrid from './ArticlesGrid';
import ColorsGallery from '../ColorsGallery';

const Body = () => {
  const {
    i18n,
    locale,
    bodyRef,
    products,
    zoomedColorId,
    resetZoomedColor,
    showColorsGallery,
    toggleCartColorId,
    cartColorIds,
    artemida,
  } = useContext(Context);

  const abstract = artemida ? i18n.abstract_artemida : i18n.abstract;

  return (
    <div ref={bodyRef} className="">
      {
        showColorsGallery && (
          <ColorsGallery
            i18n={i18n}
            locale={locale}
            colors={products}
            activeColorId={zoomedColorId}
            onClose={resetZoomedColor}
            onColorAddedToCart={toggleCartColorId}
            cartColorIds={cartColorIds}
            artemida={artemida}
          />
        )
      }

      <div className="container d-none d-md-block">
        <h4 className="mt-5 text-center">{abstract}</h4>
      </div>

      <div className="container-xl mt-5">
        <ArticlesGrid />
      </div>
    </div>
  );
};

Body.propTypes = {
};

Body.defaultProps = {
};

export default Body;
