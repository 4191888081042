import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Pager = ({ code, label, onClick, active, i18n }) => {
  const { abstract } = i18n;

  const classNames = classnames(
    'SustainabilityReportPager',
    `SustainabilityReportPager--${code}`,
    {
      'SustainabilityReportPager--active': active
    },
  );

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-3 mb-5">
      <div className={classNames} onClick={() => { onClick(code) }}>
        <div className="SustainabilityReportPager__bg" />
        <div className="SustainabilityReportPager__element" />

        <div className="SustainabilityReportPager__content">
          <h2 className="SustainabilityReportPager__title">{label}</h2>
          <p className="SustainabilityReportPager__abstract h6">
            {abstract}
          </p>
        </div>
      </div>
    </div>
  );
};

Pager.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Pager;
