import React from 'react';
import PropTypes from 'prop-types';

// import curlTopImage from './curl-top.png';
// import curlBottomImage from './curl-bottom.png';

const isPresent = (text) => (text && text.length > 0);

const SlideCaption = ({
  title,
  titleImage,
  subtitle,
  cta,
}) => {
  const image = isPresent(titleImage) && (
    <img
      className="slide__title-image"
      src={titleImage}
      alt={title}
      title={title}
    />
  );
  const heading = isPresent(title) && (
    <h2 className="SlideCaption__title">{title}</h2>
  );
  const description = isPresent(subtitle) && (
    <p className="SlideCaption__subtitle">{subtitle}</p>
  );

  const callToAction = isPresent(cta.url) && (
    <a
      type="button"
      href={cta.url}
      target={cta.target}
      className="btn btn-light btn-lg"
    >
      {cta.text}
    </a>
  );

  const curlTop = (
    <div
      className="SlideCaption__curlTop"
    />
  );

  return (
    <div className="SlideCaption container">
      <div className="SlideCaption__body">
        {curlTop}

        <div className="SlideCaption__content">
          <div>
            {image}
            {heading}
            {description}
            {callToAction}
          </div>
        </div>

        <div className="SlideCaption__curlBottom" />
      </div>
    </div>
  );
};

SlideCaption.propTypes = {
  title: PropTypes.string,
  titleImage: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    target: PropTypes.string,
  }),
};

SlideCaption.defaultProps = {
  title: null,
  titleImage: null,
  subtitle: null,
  cta: null,
};

export default SlideCaption;
