import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterItem from './FilterItem';
import Icon from '../Icon';

const FiltersSecondaryMenuFilterSector = ({
  i18n,
  show,
  productCategories,
  toggleCurrentCategory,
  currentCategoryIds,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'sector';
  const { configurator_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterSector',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );

  const handleClick = (id) => { toggleCurrentCategory(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className="col-sm-4">
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            productCategories.map(({ name, id, icon, image }) => (
              <div className="col-sm-4 my-3" key={id}>
                <FilterItem
                  key={id}
                  id={id}
                  name={name}
                  image={icon.thumb.url}
                  onClick={handleClick}
                  active={_.includes(currentCategoryIds, id)}
                />
              </div>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterSector;
