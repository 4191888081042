import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './Icon';

const AddPrintButton = ({
  starred, onClick, printId, showLabel, i18n,
  ...props
}) => {
  const className = classnames('AddPrintButton', props.className, {
    'AddPrintButton--active': starred,
  });

  const handleClick = (e) => {
    e.preventDefault();

    onClick(printId);
  };

  // WARNING: React does not work well with the Icon (font-awesome) component
  // This ugly render function is required to ensure a correct rerender
  return (
    <>
      {
        starred && (
          <a className={className} href="#unstar" onClick={handleClick}>
            <Icon name="star" family="fas" fw />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.saved_print}
                </span>
              )
            }
          </a>
        )
      }
      {
        !starred && (
          <a className={className} href="#star" onClick={handleClick}>
            <Icon name="star" family="far" fw />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.save_print}
                </span>
              )
            }
          </a>
        )
      }
    </>
  );
};

AddPrintButton.propTypes = {
  starred: PropTypes.bool,
  showLabel: PropTypes.bool,
  onClick: PropTypes.func,
  printId: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    save_print: PropTypes.string,
    saved_print: PropTypes.string,
  }),
  className: PropTypes.string,
};

AddPrintButton.defaultProps = {
  starred: false,
  onClick: () => {},
  className: '',
  showLabel: false,
  i18n: {},
};

export default AddPrintButton;
