import React from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import { productsConfiguratorPDFPath } from '../../api/products';
import FiltersCloud from './FiltersCloud';
import Icon from '../Icon';

const FiltersMainMenu = ({
  i18n,
  setTextFilter,
  isFiltersMenuOpen,
  toggleFiltersMenu,
  textFilter,
  showFilter,
  currentFilters,
  urlParams,

  productCategories,
  toggleCurrentCategory,
  currentCategoryIds,

  colorPalettes,
  toggleCurrentColor,
  currentColors,

  toggleCurrentPrice,
  currentPrices,

  toggleCurrentThickness,
  currentThicknesses,

  toggleCurrentFlowerType,
  currentFlowerTypes,

  toggleCurrentTextureType,
  currentTextureTypes,

  toggleCurrentAnilinaType,
  currentAnilinaTypes,

  toggleCurrentAppearanceType,
  currentAppearanceTypes,

  products,
  allProducts,
  toggleExcludedProductVariantId,
  excludedProductVariantIds,
  initiallyExcludedProductVariants,
}) => {
  const handleColorNameChange = _.debounce((event) => {
    setTextFilter(event.target.value);
  }, 250);
  const handleFiltersButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    toggleFiltersMenu();
  };

  const iconStyle = {
    position: 'absolute',
    left: 0,
    top: 0,
  };

  return (
    <div className="FiltersMainMenu">
      <div className="container-fluid">
        <h5
          className="text-light FiltersMainMenu__title"
          dangerouslySetInnerHTML={{__html: i18n.apply_filters_html}}
        />
        <button
          style={{ position: 'relative', height: 33, width: 22 }}
          className="toggle-main-menu-button btn btn-link text-light text-start ps-0"
          onClick={handleFiltersButtonClick}
        >
          <span style={iconStyle} className={classnames({ 'fade':  isFiltersMenuOpen })}>
            <Icon
              name="chevron-left"
              size="2x"
            />
          </span>

          <span style={iconStyle} className={classnames({ 'fade':  !isFiltersMenuOpen })}>
            <Icon
              name="chevron-right"
              size="2x"
            />
          </span>
        </button>

        <h5
          className="text-light mt-5 FiltersMainMenu__title"
          dangerouslySetInnerHTML={{__html: i18n.search_by_name_html}}
        />
        <Form>
          <Form.Group className="HeaderFilters__formGroup" controlId="formColorName">
            <Form.Control
              type="text"
              className="form-control--header"
              onChange={handleColorNameChange}
              defaultValue={textFilter}
            />
          </Form.Group>
        </Form>
      </div>

      <FiltersCloud
        i18n={i18n}
        currentFilters={currentFilters}

        productCategories={productCategories}
        toggleCurrentCategory={toggleCurrentCategory}
        currentCategoryIds={currentCategoryIds}

        colorPalettes={colorPalettes}
        toggleCurrentColor={toggleCurrentColor}
        currentColors={currentColors}

        toggleCurrentPrice={toggleCurrentPrice}
        currentPrices={currentPrices}

        toggleCurrentThickness={toggleCurrentThickness}
        currentThicknesses={currentThicknesses}

        toggleCurrentFlowerType={toggleCurrentFlowerType}
        currentFlowerTypes={currentFlowerTypes}

        toggleCurrentTextureType={toggleCurrentTextureType}
        currentTextureTypes={currentTextureTypes}

        toggleCurrentAnilinaType={toggleCurrentAnilinaType}
        currentAnilinaTypes={currentAnilinaTypes}

        toggleCurrentAppearanceType={toggleCurrentAppearanceType}
        currentAppearanceTypes={currentAppearanceTypes}

        products={products}
        allProducts={allProducts}
        toggleExcludedProductVariantId={toggleExcludedProductVariantId}
        excludedProductVariantIds={excludedProductVariantIds}
        initiallyExcludedProductVariants={initiallyExcludedProductVariants}
      />
    </div>
  );
};

export default FiltersMainMenu;
