import React from 'react';

import Icon from '../Icon';

const iconName = (type) => (type === 'VIDEO' ? 'video' : 'camera');

const InstafeedItem = ({
  thumbnail_url, media_url, caption, media_type, permalink,
}) => (
  <div className="InstafeedItem col-md-6 col-lg-4">
    <a href={permalink} target="_blank" rel="noreferrer" className="InstafeedItem__link">
      <img
        src={thumbnail_url || media_url}
        className="img-fluid"
        alt={caption}
      />

      <div className="InstafeedItem__type">
        <span className="visually-hidden">
          {media_type}
        </span>

        <Icon name={iconName(media_type)} fw />
      </div>

      <div className="InstafeedItem__info">
        <div className="InstafeedItem__content">
          <h6 className="p-5">
            {caption.length > 200 ? `${caption.substring(0, 200)}...` : caption}
          </h6>

          <div className="InstafeedItem__icons">
            <Icon name="heart" fw />
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <Icon name="comment" fw />
          </div>
        </div>
      </div>
    </a>
  </div>
);

InstafeedItem.propTypes = {
};

InstafeedItem.defaultProps = {
};

export default InstafeedItem;
