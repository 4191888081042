import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import { productShape } from '../utils';

const valuableSpec = (value) => (value && value.length > 0);

const SpecItem = ({ value, label }) => (
  <div className="ProductTile__spec" key={label}>
    <span className="ProductTile__spec-label">
      {label}
      :
    </span>

    <span className="ProductTile__spec-value">
      <span dangerouslySetInnerHTML={{ __html: value }} />
    </span>
  </div>
);

const ProductTileSpecs = ({ product }) => {
  const { i18n } = useContext(Context);
  const { size, weight } = product;
  const willRenderSpecs = valuableSpec(size) && valuableSpec(weight);

  if (!willRenderSpecs) { return ''; }

  return (
    <div className={classnames('ProductTile__specs')}>
      <SpecItem label={i18n.size_label} value={size} />
      <SpecItem label={i18n.weight_label} value={weight} />
    </div>
  );
};

ProductTileSpecs.propTypes = {
  product: productShape.isRequired,
};
//
// ArticlesGrid.defaultProps = {
// };

export default ProductTileSpecs;
