import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon';

const IconBadge = ({ open, onClick }) => (
  <div
    className={classnames('ChatForm__IconBadge', { active: open })}
    onClick={onClick}
  >
    <Icon
      name="comment-dots"
      className={classnames('ChatForm__ChatIcon')}
    />

    <Icon
      name="angle-down"
      className={classnames('ChatForm__TimesIcon')}
      fw
    />
  </div>
);

IconBadge.propTypes = {};

export default IconBadge;

