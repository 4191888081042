import React, { useContext } from 'react';

import Context from './Context';
import ArticlesGrid from './ArticlesGrid';

const Body = () => {
  const {
    i18n,
    locale,
    bodyRef,
    products,
    zoomedColorId,
    resetZoomedColor,
    showColorsGallery,
    toggleCartColorId,
    cartColorIds,
    artemida,
  } = useContext(Context);

  return (
    <div ref={bodyRef} className="pt-10">
      <div className="container d-none d-md-block">
        <h4 className="text-success mt-5">
          {i18n.abstract_configurator1}
          <br/>
          {i18n.abstract_configurator2}
        </h4>
      </div>

      <div className="container mt-5">
        <ArticlesGrid />
      </div>
    </div>
  );
};

Body.propTypes = {};

Body.defaultProps = {};

export default Body;
