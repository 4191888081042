import React, { useContext } from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import Context from './Context';

const Header = () => {
  const {
    i18n,
    locale,
    product,
    scrollIntoColors,
    onColorFilterChange,
    productsPath,
  } = useContext(Context);

  const { name } = product;
  const handleColorNameChange = _.debounce((event) => {
    onColorFilterChange(event.target.value);
  }, 500);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      scrollIntoColors();
    }
  };
  const className = classnames('ProductPage__Header');

  return (
    <div className={className}>
      <div className="row">
        <div className="col-md-9">
          <h4 className="ProductPage__name">
            <a href={productsPath({ locale })}>{i18n.all_leathers}</a>
            <span>|</span>
            <strong>{name}</strong>
          </h4>
        </div>

        <div className="col-md-3">
          <Form>
            <Form.Group className="" controlId="formColorName">
              <Form.Control
                type="text"
                placeholder={i18n.filter_by_color}
                onChange={handleColorNameChange}
                onKeyPress={handleKeyPress}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Header;
