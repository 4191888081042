import React from 'react';
import classnames from 'classnames';

const FilterButton = ({ label, className, active, onClick }) => (
  <a
    className={classnames('FilterButton', className, { active })}
    onClick={onClick}
  >
    <div className="FilterButton__image" />
    <h6 className="FilterButton__label">
      {label}
    </h6>
  </a>
);

export default FilterButton;
