import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import HeaderFilters from './HeaderFilters';
import scrollImage from '../../images/scrolldown.gif';

const Header = () => {
  const {
    i18n,
    showCategories,
    showColors,
    showFilter,
    setShowFilter,
    resetShowFilter,
  } = useContext(Context);

  const handleFilterClick = (e, filter) => {
    e.preventDefault();

    if (showFilter === filter) {
      resetShowFilter();
    } else {
      setShowFilter(filter);
    }
  };

  return (
    <div className={classnames('phbox', 'phbox--articles', 'Scrolldown', 'scrollify')}>
      <img
        alt="Scroll down"
        className="Scrolldown__image"
        data-scrolldown="true"
        src={scrollImage}
      />

      <div className="container phbox__content">
        <div className="row">
          <div className="col-md-6">
            <div className="phbox__title">
              <h1 className="mt-4 pt-2">{i18n.title}</h1>
            </div>
          </div>

          <div className="col-md-6">
            <div className="phbox__title">
              <HeaderFilters />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="phbox__body text-center text-md-end">
              <a
                href="#sectors"
                className={classnames('btn btn--h1 btn--border phbox__cta', { active: showCategories })}
                onClick={(e) => { handleFilterClick(e, 'categories'); }}
              >
                {i18n.browse_by_sector}
              </a>
            </div>
          </div>

          <div className="col-md-6">
            <div className="phbox__body text-center text-md-start">
              <a
                href="#colors"
                className={classnames('btn btn--h1 btn--border phbox__cta', { active: showColors })}
                onClick={(e) => { handleFilterClick(e, 'colors'); }}
              >
                {i18n.browse_by_color}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Header.propTypes = {
//   i18n: i18nShape.isRequired,
//   productCategories: productCategoryShape.isRequired,
// };
//
// Header.defaultProps = {
// };

export default Header;
