
import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';

const PaginationArrow = ({ direction }) => {
  return null;

  const {
    pagination,
    productsPath,
    loadPage,
    setIsLoading,
  } = useContext(Context);
  const { total_pages, current_page } = pagination;
  const hasPrevPage = current_page > 1;
  const hasNextPage = current_page < total_pages;
  const isPrev = direction === 'prev';
  const isNext = !isPrev;
  const isDisabled = isPrev
    ? !hasPrevPage
    : !hasNextPage;

  const className = classnames('PaginationArrow', {
    disabled: isDisabled,
  });

  const iconClassName = classnames({
    'bi-chevron-left': isPrev,
    'bi-chevron-right': isNext,
  });

  if (isDisabled) {
    return (
      <div className={className}>
        <i className={iconClassName} />
      </div>
    );
  }

  const page = isPrev ? current_page - 1 : current_page + 1;
  const href = productsPath(page);
  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    loadPage(page);
  };

  return (
    <a className={className} href={href} onClick={handleClick}>
      <i className={iconClassName} />
    </a>
  );
};

export default PaginationArrow;
