import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterItem from './FilterItem';
import Icon from '../Icon';
import imageTextureNatural from '../../images/flf-texture_natural.jpg';
import imageTextureSmooth from '../../images/flf-texture_smooth.jpg';
import imageTexturePrinted from '../../images/flf-texture_printed.jpg';

const FiltersSecondaryMenuFilterTexture = ({
  i18n,
  show,
  toggleCurrentTextureType,
  currentTextureTypes,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'texture';
  const { configurator_filters, configurator_texture_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterTexture',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );
  const textureKeys = [
    'texture_natural',
    'texture_smooth',
    'texture_printed',
  ];
  const textureImages = {
    texture_natural: imageTextureNatural,
    texture_smooth: imageTextureSmooth,
    texture_printed: imageTexturePrinted,
  };

  const handleClick = (id) => { toggleCurrentTextureType(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className={classnames('col-sm-4')}>
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            textureKeys.map((key, idx) => (
              <React.Fragment key={key}>
                <div className={classnames('col-sm-4 my-3')}>
                  <FilterItem
                    key={key}
                    id={key}
                    name={configurator_texture_filters[key]}
                    image={textureImages[key]}
                    onClick={handleClick}
                    active={_.includes(currentTextureTypes, key)}
                  />
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterTexture;
