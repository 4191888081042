import React, { useContext } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import _ from 'lodash';

import Context from './Context';
import FilterItem from './FilterItem';

const breakpoint = (resolution, slidesToShow) => ({
  breakpoint: resolution,
  arrows: true,
  dots: false,
  settings: {
    arrows: true,
    slidesToShow,
    slidesToScroll: 1,
  },
});

const maxOr = (items, max) => (max < items ? max : items);
const slickSettings = (max) => ({
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: maxOr(6, max),
  slidesToScroll: 1,
  responsive: [
    breakpoint(1440, maxOr(8, max)),
    breakpoint(1200, maxOr(6, max)),
    breakpoint(1024, maxOr(5, max)),
    breakpoint(992,  maxOr(4, max)),
    breakpoint(768,  maxOr(3, max)),
    breakpoint(576,  maxOr(1, max)),
    breakpoint(0,    maxOr(1, max)),
  ],
});

const ColorsFilters = () => {
  const {
    showColors,
    colorPalettes,
    currentColors,
    toggleCurrentColor,
  } = useContext(Context);

  const settings = slickSettings(colorPalettes.length);
  const className = classnames('ProductsFiltersRow d-flex align-items-center', {
    active: showColors,
  });

  const handleClick = (id) => {
    toggleCurrentColor(id);
  };

  return (
    <div className={className}>
      <div className="container ProductsFiltersRow__content">
        <Slider {...settings}>
          {
            colorPalettes.map(({ id, name, image }) => (
              <FilterItem
                key={id}
                id={id}
                name={name}
                image={image.thumb.url}
                onClick={handleClick}
                active={_.includes(currentColors, id)}
              />
            ))
          }
        </Slider>
      </div>
    </div>
  );
};

ColorsFilters.propTypes = {
};

ColorsFilters.defaultProps = {
};

export default ColorsFilters;
