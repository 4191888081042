import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import Context from './Context';
import FiltersMainMenu from './FiltersMainMenu';
import FiltersSecondaryMenu from './FiltersSecondaryMenu';

const ProductsConfiguratorFilter = () => {
  const {
    i18n,
    urlParams,
    filtersRef,
    textFilter,
    setTextFilter,
    showFilters,
    toggleFiltersMenu,
    isFiltersMenuOpen,
    showFilter,
    setShowFilter,
    toggleCurrentCategory,
    currentFilters,
    allProducts,
    products,

    productCategories,
    currentCategoryIds,

    colorPalettes,
    toggleCurrentColor,
    currentColors,

    toggleCurrentPrice,
    currentPrices,

    toggleCurrentThickness,
    currentThicknesses,

    toggleCurrentFlowerType,
    currentFlowerTypes,

    toggleCurrentTextureType,
    currentTextureTypes,

    toggleCurrentAnilinaType,
    currentAnilinaTypes,

    toggleCurrentAppearanceType,
    currentAppearanceTypes,

    excludedProductVariantIds,
    toggleExcludedProductVariantId,
    initiallyExcludedProductVariants,
  } = useContext(Context);

  const className = classnames('ProductsConfiguratorFilter', {
    active: isFiltersMenuOpen,
  });

  return (
    <div className={className} ref={filtersRef}>
      <FiltersMainMenu
        i18n={i18n}
        setTextFilter={setTextFilter}
        isFiltersMenuOpen={isFiltersMenuOpen}
        toggleFiltersMenu={toggleFiltersMenu}
        textFilter={textFilter}
        showFilter={showFilter}
        currentFilters={currentFilters}
        urlParams={urlParams}

        productCategories={productCategories}
        toggleCurrentCategory={toggleCurrentCategory}
        currentCategoryIds={currentCategoryIds}

        colorPalettes={colorPalettes}
        toggleCurrentColor={toggleCurrentColor}
        currentColors={currentColors}

        toggleCurrentPrice={toggleCurrentPrice}
        currentPrices={currentPrices}

        toggleCurrentThickness={toggleCurrentThickness}
        currentThicknesses={currentThicknesses}

        toggleCurrentFlowerType={toggleCurrentFlowerType}
        currentFlowerTypes={currentFlowerTypes}

        toggleCurrentTextureType={toggleCurrentTextureType}
        currentTextureTypes={currentTextureTypes}

        toggleCurrentAnilinaType={toggleCurrentAnilinaType}
        currentAnilinaTypes={currentAnilinaTypes}

        toggleCurrentAppearanceType={toggleCurrentAppearanceType}
        currentAppearanceTypes={currentAppearanceTypes}

        allProducts={allProducts}
        products={products}
        toggleExcludedProductVariantId={toggleExcludedProductVariantId}
        excludedProductVariantIds={excludedProductVariantIds}
        initiallyExcludedProductVariants={initiallyExcludedProductVariants}
      />

      <FiltersSecondaryMenu
        i18n={i18n}
        open={isFiltersMenuOpen}
        showFilter={showFilter}
        setShowFilter={setShowFilter}

        productCategories={productCategories}
        toggleCurrentCategory={toggleCurrentCategory}
        currentCategoryIds={currentCategoryIds}

        colorPalettes={colorPalettes}
        toggleCurrentColor={toggleCurrentColor}
        currentColors={currentColors}

        toggleCurrentPrice={toggleCurrentPrice}
        currentPrices={currentPrices}

        toggleCurrentThickness={toggleCurrentThickness}
        currentThicknesses={currentThicknesses}

        toggleCurrentFlowerType={toggleCurrentFlowerType}
        currentFlowerTypes={currentFlowerTypes}

        toggleCurrentTextureType={toggleCurrentTextureType}
        currentTextureTypes={currentTextureTypes}

        toggleCurrentAnilinaType={toggleCurrentAnilinaType}
        currentAnilinaTypes={currentAnilinaTypes}

        toggleCurrentAppearanceType={toggleCurrentAppearanceType}
        currentAppearanceTypes={currentAppearanceTypes}
      />
    </div>
  );
};

ProductsConfiguratorFilter.propTypes = {
};

ProductsConfiguratorFilter.defaultProps = {
};

export default ProductsConfiguratorFilter;
