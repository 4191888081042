import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Pie from './Pie';

import handshakeImage from '../../images/balance-people-handshake.png';
import employeesImage from '../../images/balance-people-employees.png';

const slideColor = '#8BBBB4';
const slideColorAlt = '#3F8C7D';

const SlidePeople = ({ code, label, i18n, active }) => {
  const { one, two, three, four, five, six } = i18n;

  return (
    <div className="BalanceSliderSlide BalanceSliderSlide--people">
      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-one with-arrow">
          <div className="">
            <h5 className="mb-3 pe-5 me-5">{one.title}</h5>

            <h6 className="BalanceSliderSlide__title-alt mb-0">
              {one.over}
              &nbsp;
              <span className="BalanceSliderSlide__strong-number">
                15
              </span>
            </h6>

            <h6 className="BalanceSliderSlide__title-alt mb-3">
              {one.years}
            </h6>

            <img
              src={handshakeImage}
              className="d-block img-fluid w-25"
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-two">
          <div className="BalanceSliderSlide__pie-dots" />

          <h5 className="BalanceSliderSlide__title-alt mb-5">
            {two.title}
          </h5>

          <div className="mx-auto px-5 px-sm-4 px-lg-5">
            <Pie
              value={73}
              color={slideColor}
              colorAlt={slideColorAlt}
              active={active}
            />
          </div>

          <h6 className="mt-5 small pe-5 BalanceSliderSlide__text">{two.text}</h6>
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-three">
          <div className="BalanceSliderSlide__pie-dots" />

          <h5 className="BalanceSliderSlide__title-alt mb-5">
            {three.title}
          </h5>

          <div className="mx-auto px-5 px-sm-4 px-lg-5">
            <Pie
              value={85}
              color={slideColor}
              colorAlt={slideColorAlt}
              active={active}
            />
          </div>

          <h6 className="mt-5 small pe-5 BalanceSliderSlide__text">
            {three.text}
          </h6>
        </div>
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">

        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-four with-arrow">
          <h5 className="mb-3">{four.title}</h5>

          <img
            src={employeesImage}
            className="d-block img-fluid w-25"
          />
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-five">
          <h6 className="text-nowrap mb-4 small pe-md-5 BalanceSliderSlide__text text-center">
            {five.text}
          </h6>

          <div className="mx-auto px-5 px-sm-4 px-lg-5">
            <Pie
              value={85}
              color={slideColor}
              colorAlt={slideColorAlt}
              active={false}
              altLabel={
                <h6 className="BalanceSliderSlide__title-alt text-center">
                  <span className="BalanceSliderSlide__strong-number">
                    108
                  </span>
                  <br />
                  {five.employees}
                </h6>
              }
            />
          </div>
        </div>

        <div className="col-md-6 col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-six">
          <div className="BalanceSliderSlide__pie-dots" />

          <h6 className="text-nowrap mb-4 small pe-5 BalanceSliderSlide__text">
            &nbsp;
          </h6>

          <div className="mx-auto px-5 px-sm-4 px-lg-5">
            <Pie
              value={96}
              color={slideColor}
              colorAlt={slideColorAlt}
              active={active}
            />
          </div>

          <h6 className="text-center text-md-start mt-5 pe-md-5 BalanceSliderSlide__text">
            {six.text}
          </h6>
        </div>
      </div>
    </div>
  );
};

SlidePeople.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SlidePeople;
