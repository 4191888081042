import React from 'react';
import PropTypes from 'prop-types';

const NotchRuler = ({ index, percentage }) => {
  const classes = `image-ruler__item image-ruler__item--${index}`;
  const style = {
    width: `${percentage}%`,
    marginLeft: `${percentage * (index - 1)}%`,
  };

  return (
    <div className={classes} style={style}>
      <div className="image-ruler__text">
        {index}
        &nbsp;
        <small>cm</small>
      </div>
    </div>
  );
};

// Simple example of a React "dumb" component
class Ruler extends React.Component {
  renderNotches() {
    const { percentage } = this.props;
    const items = [];

    for (let i = 1; i <= 10; i += 1) {
      items.push((
        <NotchRuler key={i} index={i} percentage={percentage} />
      ));
    }

    return items;
  }

  render() {
    const { children } = this.props;

    return (
      <div className="image-ruler__wrapper">
        {children}
        {this.renderNotches()}
      </div>
    )
  }
}

Ruler.propTypes = {
  percentage: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default Ruler;
