import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SlidePeople from './SlidePeople';
import SlidePlanet from './SlidePlanet';
import SlideProsperity from './SlideProsperity';
import SlideProduct from './SlideProduct';

const slidesMap = {
  people: SlidePeople,
  planet: SlidePlanet,
  prosperity: SlideProsperity,
  product: SlideProduct,
};

const Slide = ({ code, label, i18n, active }) => {
  const { one, two, three, four, five, six } = i18n;

  const CurrentSlide = slidesMap[code];

  if (CurrentSlide) {
    return (
      <div className="container">
        <CurrentSlide
          code={code}
          label={label}
          i18n={i18n}
          active={active}
        />
      </div>
    );
  }

  return (
    <div className="BalanceSliderSlide container">
      <h1>Missing slide {label}...</h1>
    </div>
  );
};

Slide.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Slide;
