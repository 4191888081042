import React from 'react';
import PropTypes, { bool, arrayOf, shape } from 'prop-types';
import classnames from 'classnames';

import StyledMenu from './Menu.styled';
import Icon from '../Icon';

const visit = (path) => (new Promise((resolve) => {
  const handlePageLoad = (event) => { resolve(event); };
  document.addEventListener('turbo:load', handlePageLoad, { once: true });
  global.Turbo.visit(path);
}));

const Link = ({
  children, tabIndex, href, medium, turbo, onClick
}) => (
  <a
    href={href}
    tabIndex={tabIndex}
    className={classnames('Menu__link', { 'Menu__link--medium': medium })}
    data-turbo={turbo}
    onClick={onClick}
  >
    {children}
  </a>
);

Link.defaultProps = {
  turbo: 'true',
};

const NestedLink = ({
  children,
  tabIndex,
  href,
  turbo,
  onClick,
}) => (
  <a
    href={href}
    tabIndex={tabIndex}
    className={classnames('Menu__nestedLink')}
    onClick={onClick}
    data-turbo={turbo}
  >
    {children}
  </a>
);

NestedLink.defaultProps = {
  turbo: 'true',
};

const SocialLink = ({
  icon,
  url,
  tabIndex,
  onClick,
}) => (
  <a
    href={url}
    tabIndex={tabIndex}
    onClick={onClick}
    className={classnames('Menu__socialLink')}
  >
    <Icon name={icon} family="fab" size="2x" />
  </a>
);

const Menu = ({
  open,
  socials,
  links,
  onLinkClick,
  ...props
}) => {
  const isHidden = open;
  const tabIndex = isHidden ? 0 : -1;
  const {
    leathers,
    store,
    hygiene,
    approach,
    distributors,
    contacts,
    production,
    tips,
    admin,
  } = links;

  const scrollTo = (chunk, wait = 300) => {
    setTimeout(() => {
      document.getElementById(chunk).scrollIntoView();
    }, wait);
  };

  const handleClick = (event) => {
    event.preventDefault();
    onLinkClick(event);

    const fullUrl = event.target.href;
    const urlChunks = fullUrl.split('#');
    const currPageUrl = document.location.href.split('#')[0];

    if (currPageUrl === urlChunks[0]) {
      if (urlChunks.length > 1) {
        scrollTo(urlChunks[1], 0);
      } else {
        window.scrollTo(0, 0);
      }

      return;
    }

    visit(urlChunks[0]).then(() => {
      if (urlChunks.length > 1) {
        scrollTo(urlChunks[1]);
      }
    });
  };

  return (
    <StyledMenu
      open={open}
      aria-hidden={!isHidden}
      className={classnames('header__menu', 'Menu')}
      {...props}
    >
      <div className="Menu__content">
        <div className="row mb-5">
          <div className="col">
            <Link href={leathers.path} onClick={handleClick} tabIndex={tabIndex}>
              {leathers.label}
            </Link>

            <Link href={store.path} onClick={handleClick} tabIndex={tabIndex}>
              {store.label}
            </Link>

            <Link href={hygiene.path} onClick={handleClick} tabIndex={tabIndex}>
              {hygiene.label}
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-md mb-3">
            <Link href={distributors.path} onClick={handleClick} tabIndex={tabIndex} turbo={false} medium>
              {distributors.label}
            </Link>

            <NestedLink href={`${distributors.path}#history`} onClick={handleClick} turbo={false} tabIndex={tabIndex} medium>
              {distributors.history_label}
            </NestedLink>

            <NestedLink href={`${distributors.path}#group`} onClick={handleClick} turbo={false} tabIndex={tabIndex} medium>
              {distributors.group_label}
            </NestedLink>

            <NestedLink href={`${distributors.path}#net`} onClick={handleClick} turbo={false} tabIndex={tabIndex} medium>
              {distributors.net_label}
            </NestedLink>

            <Link href={approach.path} tabIndex={tabIndex} onClick={handleClick} medium>
              {approach.label}
            </Link>

            <NestedLink href={`${approach.path}#values`} tabIndex={tabIndex} onClick={handleClick} medium>
              {approach.values_label}
            </NestedLink>

            <NestedLink href={`${approach.path}#sustainability`} tabIndex={tabIndex} onClick={handleClick} medium>
              {approach.sustainability_label}
            </NestedLink>

            <NestedLink href={`${approach.path}#commitment`} tabIndex={tabIndex} onClick={handleClick} medium>
              {approach.commitment_label}
            </NestedLink>
          </div>

          <div className="col-md mb-3">
            <Link href={production.path} onClick={handleClick} tabIndex={tabIndex} medium>
              {production.label}
            </Link>

            <Link href={tips.path} onClick={handleClick} tabIndex={tabIndex} medium>
              {tips.label}
            </Link>

            <Link href={contacts.path} onClick={handleClick} tabIndex={tabIndex} medium>
              {contacts.label}
            </Link>

            <div className="Menu__footer mt-4">
              <a href={admin.path} className="Menu__a" onClick={handleClick} data-turbo="false">
                {admin.label}
              </a>

              <div className="Menu__languages">
                <a href="/it" className="Menu__a" onClick={handleClick}>Italiano</a>
                &nbsp;|&nbsp;
                <a href="/en" className="Menu__a" onClick={handleClick}>English</a>
              </div>

              <div className="Menu__socials mt-3">
                {
                  socials.map(({ url, icon }) => (
                    <SocialLink url={url} icon={icon} key={icon} onClick={handleClick} />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledMenu>
  );
};

Menu.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
  open: bool.isRequired,
  links: PropTypes.shape({
    leathers: PropTypes.shape({}),
    store: PropTypes.shape({}),
    hygiene: PropTypes.shape({}),
    approach: PropTypes.shape({}),
    distributors: PropTypes.shape({}),
    contacts: PropTypes.shape({}),
    production: PropTypes.shape({}),
    tips: PropTypes.shape({}),
    admin: PropTypes.shape({}),
  }).isRequired,
  socials: arrayOf(shape({
    url: PropTypes.string,
    icon: PropTypes.string,
  })).isRequired,
};

export default Menu;
