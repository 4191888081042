import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';
import _ from 'lodash';

import ColorDetails from './ColorDetails';
import { i18nShape } from '../utils';

const defaultSlickSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 650,
  lazyLoad: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: false,
};

const getInitialSlideIndex = ({ activeColorId, colors }) => {
  if (!activeColorId) { return 0; }

  let index = 0;

  for (let i = 0; i < colors.length; i += 1) {
    if (colors[i].id === activeColorId) {
      break;
    } else {
      index += 1;
    }
  }

  return index;
};

const ColorsGallery = ({
  i18n,
  locale,
  colors,
  activeColorId,
  onClose,
  cartColorIds,
  onColorAddedToCart,
  showInfoButton,
  artemida,
}) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) { onClose(); }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (activeColorId && sliderRef) {
      const counter = getInitialSlideIndex({ colors, activeColorId });
      sliderRef.current.slickGoTo(counter);
    }
  }, [activeColorId]);

  const isActive = !!activeColorId;

  const className = classnames('ColorsGallery', {
    'ColorsGallery--active': isActive,
  });

  const nestedClassName = classnames('ColorsGallery__wrapper animate__animated d-flex align-items-center', {
    animate__fadeInUp: isActive,
    animate__fadeOutDown: !isActive,
  });

  const settings = {
    ...defaultSlickSettings,
  };

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={className}>
      <div className={nestedClassName}>
        <div className="container ColorsGallery__container">
          <a href="#close" className="ColorsGallery__close" onClick={handleClose}>
            &times;
          </a>

          <Slider
            {...settings}
            ref={sliderRef}
            initialSlide={0}
            className="slick-slider--variant-gallery"
          >
            {
              colors.map((color) => (
                <ColorDetails
                  color={color}
                  i18n={i18n}
                  locale={locale}
                  key={color.id}
                  onColorAddedToCart={onColorAddedToCart}
                  isStarred={_.includes(cartColorIds, color.id)}
                  showInfoButton={showInfoButton}
                  artemida={artemida}
                />
              ))
            }
          </Slider>
        </div>
      </div>
    </div>
  );
};

ColorsGallery.propTypes = {
  i18n: i18nShape.isRequired,
  locale: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  activeColorId: PropTypes.number,
  onClose: PropTypes.func,
  onColorAddedToCart: PropTypes.func,
  cartColorIds: PropTypes.arrayOf(PropTypes.number),
  showInfoButton: PropTypes.bool,
  artemida: PropTypes.bool,
};

ColorsGallery.defaultProps = {
  activeColorId: null,
  colors: [],
  onClose: () => {},
  onColorAddedToCart: () => {},
  cartColorIds: [],
  showInfoButton: true,
  artemida: false,
};

export default ColorsGallery;
