import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PrintsSlider from './PrintsSlider';

const PrintsGallery = ({
  title,
  prints,
  i18n,
  onCartButtonClick,
  onZoomButtonClick,
  cartPrintIds,
}) => (
  <div className={classnames('PrintsGallery', 'my-5 pb-5')}>
    <div className={classnames('container')}>
      <div className={classnames('container-fluid', 'd-none')}>
        <h5 className="text-success text-uppercase my-5">{title}</h5>
      </div>

      <PrintsSlider
        i18n={i18n}
        prints={prints}
        cartPrintIds={cartPrintIds}
        onCartButtonClick={onCartButtonClick}
        onZoomButtonClick={onZoomButtonClick}
      />
    </div>
  </div>
);

PrintsGallery.propTypes = {
  title: PropTypes.string.isRequired,
  prints: PropTypes.arrayOf(PropTypes.shape({})),
//   i18n: i18nShape.isRequired,
//   productCategories: productCategoryShape.isRequired,
};

PrintsGallery.defaultProps = {
  prints: [],
};

export default PrintsGallery;
