import React, { useContext } from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';

import Context from './Context';

const Dataset = ({ product_test_category, product_tests }) => {
  const { name } = product_test_category;

  return (
    <>
      <tr className="table-success">
        <th colSpan="3">{name}</th>
      </tr>

      {
        product_tests.map(({ name, method, result }) => (
          <tr key={name}>
            <td className="col-4">{name}</td>
            <td className="col-4">{method}</td>
            <td className="col-4">{result}</td>
          </tr>
        ))
      }
    </>
  );
};

const ProductTestSpecs = () => {
  const {
    i18n,
    product,
    details,
  } = useContext(Context);

  if (!details) { return null; }

  const { tests } = details;

  if (!tests) { return null; }

  const {
    title, disclaimer, headers, data,
  } = tests;

  return (
    <div className={classnames('ProductPage__tests mt-5')}>
      <h4 className="text-center text-success">{parse(title)}</h4>
      <p className="text-center">{parse(disclaimer)}</p>

      <table className="table">
        <thead>
          <tr>
            { headers.map((label) => (<th key={label}>{label}</th>)) }
          </tr>
        </thead>

        <tbody>
          {
            data.map(({ product_test_category_id, ...datum }) => (
              <Dataset
                key={product_test_category_id}
                {...datum}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default ProductTestSpecs;
