import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import _ from 'lodash';
import $ from 'jquery';

import {
  deserializeJsonApi,
  i18nShape,
  jsonApiShape,
  productCategoryShape,
  colorPaletteShape,
  productShape,
//   productVariantShape,
} from '../utils';

import {
  productPath,
  productsConfiguratorPath as productsPath,
  fetchProductsConfigurator as fetchProducts,
} from '../../api/products';
import { addColorToCart, removeColorFromCart } from '../../api/cart';

const Context = createContext({});

export const Provider = ({
  i18n,
  locale,
  artemida,
  children,
  view,
  ...props
}) => {
  //
  // State
  //

  // false | menu | colors | prices | sectors ...
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jsonApiProducts, setJsonApiProducts] = useState(props.products);
  const [products, setProducts] = useState(deserializeJsonApi(props.products));
  const [currentCategoryIds, setCurrentCategoryIds] = useState(props.currentCategoryIds);
  const [textFilter, setTextFilter] = useState(props.textFilter);
  const [colorFilter, setColorFilter] = useState(props.colorFilter);
  const [cartColorIds, setCartColorIds] = useState(props.cartColorIds);
  const [zoomedColorId, setZoomedColorId] = useState(null);
  const [highlightedColorId, setHighlightedColorId] = useState(null);
  const [currentColors, setCurrentColors] = useState(props.currentColors || []);
  const [currentPrices, setCurrentPrices] = useState(props.currentPrices || []);
  const [currentThicknesses, setCurrentThicknesses] = useState(props.currentThicknesses || []);
  const [currentFlowerTypes, setCurrentFlowerTypes] = useState(props.currentFlowerTypes || []);
  const [currentTextureTypes, setCurrentTextureTypes] = useState(props.currentTextureTypes || []);
  const [currentAnilinaTypes, setCurrentAnilinaTypes] = useState(props.currentAnilinaTypes || []);
  const [currentAppearanceTypes, setCurrentAppearanceTypes] = useState(props.currentAppearanceTypes || []);
  const [excludedProductVariantIds, setExcludedProductVariantIds] = useState(props.excludedVariantIds || []);
  const initiallyExcludedProductVariants = deserializeJsonApi(props.excludedVariants || { data: [] });

  //
  // Params
  //
  // const view = showFilter === 'colors' ? 'variants' : 'products';
  const currentFilters = {
    categoryIds: currentCategoryIds,
    text: textFilter,
    color: colorFilter,
    colorIds: currentColors,
    prices: currentPrices,
    thicknesses: currentThicknesses,
    flowerTypes: currentFlowerTypes,
    textureTypes: currentTextureTypes,
    anilinaTypes: currentAnilinaTypes,
    appearanceTypes: currentAppearanceTypes,
    excludedVariantIds: excludedProductVariantIds,
  };

  const [urlParams, setUrlParams] = useState(currentFilters);

  //
  // State helpers
  //
  /// const products = deserializeJsonApi(jsonApiProducts);
  const pagination = jsonApiProducts.meta || {};
  const currentPage = pagination.current_page || 1;
  const totalPages = pagination.total_pages || 1;
  const hasNextPage = totalPages > currentPage;
  const nextPage = hasNextPage ? currentPage + 1 : null;

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => (
      loadPage(nextPage, { skipHistory: true })
    ),
    // P
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    // disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    // rootMargin: '0px 0px 400px 0px',
  });

  //
  // Internal refs
  //
  const articlesGridRef = useRef(null);
  const filtersRef = useRef(null);
  const bodyRef = useRef(null);

  const queryString = {
    locale,
    view,
    page: currentPage,
    ...currentFilters,
  };

  const updateBrowserParams = (params) => {
    if (!global.history) { return; }

    const newUrl = productsPath(params);
    global.history.replaceState({}, '', newUrl);
  };

  // Api
  const loadProductsApi = (params = {}, reload = true) => {
    const fullParams = { ...params, artemida };
    const { page, view, ...otherParams } = fullParams;

    setUrlParams(otherParams);

    if (params.skipHistory !== true) {
      updateBrowserParams(otherParams);
    }

    if (!reload) { return; }

    // Reset products on filters change
    if (page === 1) {
      setProducts([]);
    }

    fetchProducts(fullParams)
      .then((response) => (response.json()))
      .then((jsonApi) => {
        setJsonApiProducts(jsonApi);
        setProducts((products) => ([
          ...products,
          ...deserializeJsonApi(jsonApi),
        ]))
        setIsLoading(false);
      });
  };
  const loadProductsRef = useRef(_.debounce(loadProductsApi, 100));
  const loadProducts = (options = {}, reload = true) => {
    if (reload) {
      setIsLoading(true);
    }

    const params = {
      ...queryString,
      // page,
      view,
      ...options,
    };

    loadProductsRef.current(params, reload);
  };
  // Helpers
  const loadPage = (page, options = {}) => (loadProducts({ ...options, page }));
  const resetFilters = (options = {}) => {
    setCurrentCategoryIds([]);
    setCurrentColors([]);
    loadProducts({
      page: 1,
      colorIds: [],
      categoryIds: [],
      view: options.view || 'variants',
    });
  };
  const resetShowFilter = () => {
    setShowFilter(false);
  };
  const showCategories = showFilter === 'categories';
  const showColors = showFilter === 'colors';
  const jsonApiProductCategories = props.productCategories;
  const productCategories = deserializeJsonApi(jsonApiProductCategories);
  const jsonApiColorPalettes = props.colorPalettes;
  const colorPalettes = deserializeJsonApi(jsonApiColorPalettes);
  const pagedProductsPath = (page, options = {}) => (
    productsPath({
      ...queryString,
      ...options,
      artemida,
      page,
    })
  );

  // handlers
  const handleSetShowFilter = (value) => {
    setShowFilter(value);
  };
  const openFiltersMenu = () => { handleSetShowMenu('menu'); }
  const isFiltersMenuOpen = showFilter !== false;
  const toggleFiltersMenu = () => {
    setShowFilter((value) => (value === false ? 'menu' : false));
  };
  const closeFiltersMenu = () => {
    setShowFilter(false);
  };
  const isFiltersMenuShown = showFilter !== false;
  // const handleSetCurrentCategory = (id) => {
  //   const nextCategory = currentCategory === id ? null : id;

  //   loadProducts({ categoryId: nextCategory, page: 1 });
  //   setCurrentCategory(nextCategory);

  //   if (filtersRef) {
  //     filtersRef.current.scrollIntoView();
  //   }
  // };
  const handleToggleCurrentCategory = (id) => {
    const nextCategoryIds = _.includes(currentCategoryIds, id)
      ? currentCategoryIds.filter((cId) => (cId !== id))
      : [...currentCategoryIds, id];

    loadProducts({
      categoryIds: nextCategoryIds,
      view: 'variants',
      page: 1,
    });

    setCurrentCategoryIds(nextCategoryIds);
  };
  const handleToggleCurrentColor = (id) => {
    if (_.includes(currentColors, id)) {
      popColorId(id);
    } else {
      pushColorId(id);
    }
  };
  const pushColorId = (id) => {
    const nextColorIds = _.uniq([...currentColors, id]);

    if (!_.isEqual(nextColorIds, currentColors)) {
      loadProducts({
        colorIds: nextColorIds,
        view: 'variants',
        page: 1,
      });

      setCurrentColors(nextColorIds);
    }
  };
  const popColorId = (id) => {
    const nextColorIds = currentColors.filter((cId) => (cId !== id));

    if (!_.isEqual(nextColorIds, currentColors)) {
      loadProducts({
        colorIds: nextColorIds,
        view: 'variants',
        page: 1,
      });

      setCurrentColors(nextColorIds);
    }
  };

  const handleToggleCurrentPrice = (id) => {
    const nextPrices = _.includes(currentPrices, id)
      ? currentPrices.filter((cId) => (cId !== id))
      : [...currentPrices, id];

    loadProducts({
      prices: nextPrices,
      view: 'variants',
      page: 1,
    });

    setCurrentPrices(nextPrices);
  };

  const handleToggleCurrentThickness = (id) => {
    const nextThicknesses = _.includes(currentThicknesses, id)
      ? currentThicknesses.filter((cId) => (cId !== id))
      : [...currentThicknesses, id];

    loadProducts({
      thicknesses: nextThicknesses,
      view: 'variants',
      page: 1,
    });

    setCurrentThicknesses(nextThicknesses);
  };

  const handleToggleCurrentFlowerType = (id) => {
    const nextFlowerTypes = _.includes(currentFlowerTypes, id)
      ? currentFlowerTypes.filter((cId) => (cId !== id))
      : [...currentFlowerTypes, id];

    loadProducts({
      flowerTypes: nextFlowerTypes,
      view: 'variants',
      page: 1,
    });

    setCurrentFlowerTypes(nextFlowerTypes);
  };

  const handleToggleCurrentTextureType = (id) => {
    const nextTextureTypes = _.includes(currentTextureTypes, id)
      ? currentTextureTypes.filter((cId) => (cId !== id))
      : [...currentTextureTypes, id];

    loadProducts({
      textureTypes: nextTextureTypes,
      view: 'variants',
      page: 1,
    });

    setCurrentTextureTypes(nextTextureTypes);
  };

  const handleToggleCurrentAnilinaType = (id) => {
    const nextAnilinaTypes = _.includes(currentAnilinaTypes, id)
      ? currentAnilinaTypes.filter((cId) => (cId !== id))
      : [...currentAnilinaTypes, id];

    loadProducts({
      anilinaTypes: nextAnilinaTypes,
      view: 'variants',
      page: 1,
    });

    setCurrentAnilinaTypes(nextAnilinaTypes);
  };

  const handleToggleCurrentAppearanceType = (id) => {
    const nextAppearanceTypes = _.includes(currentAppearanceTypes, id)
      ? currentAppearanceTypes.filter((cId) => (cId !== id))
      : [...currentAppearanceTypes, id];

    loadProducts({
      appearanceTypes: nextAppearanceTypes,
      view: 'variants',
      page: 1,
    });

    setCurrentAppearanceTypes(nextAppearanceTypes);
  };

  const handleToggleExcludedProductVariantId = (id) => {
    const nextIds = _.includes(excludedProductVariantIds, id)
      ? excludedProductVariantIds.filter((cId) => (cId !== id))
        : [...excludedProductVariantIds, id];

    loadProducts({
      excludedVariantIds: nextIds,
      view: 'variants',
      page: 1,
    }, false);

    setExcludedProductVariantIds(nextIds);
  };

  const handleToggleCartColorId = (id) => {
    const alreadySelected = _.includes(cartColorIds, id);
    const willBeSelected = !alreadySelected; // next state
    const nextCartColorIds = alreadySelected
      ? cartColorIds.filter((cId) => (cId !== id))
      : [...cartColorIds, id];
    const toogleCart = willBeSelected ? addColorToCart : removeColorFromCart;

    toogleCart({ locale, colorId: id })
      .then((response) => (response.json()));

    $(document).trigger('AddToCartCta:change', ['color', id, willBeSelected]);

    setCartColorIds(nextCartColorIds);
  };
  const handleSetTextFilter = (value) => {
    loadProducts({
      text: value,
      page: 1,
    });

    setTextFilter(value);
  };
  // Not used here..
  const handleSetColorFilter = (value) => {
    loadProducts({
      color: value,
      page: 1,
    });

    setColorFilter(value);
  };
  const resetZoomedColor = () => { setZoomedColorId(null); };
  const handleZoomColorId = (id) => {
    setZoomedColorId(id);
  };

  const showColorsGallery = !isLoading && view === 'variants';

  // Component did mount
  useEffect(() => {
    $('body').addClass('configurator-filters--close');
  }, []);

  useEffect(() => {
    if (showFilter) {
      $('body').removeClass('configurator-filters--close');
      $('body').addClass('configurator-filters--open');
    } else {
      $('body').addClass('configurator-filters--close');
      $('body').removeClass('configurator-filters--open');
    }
  }, [showFilter]);

  const filteredProducts = excludedProductVariantIds.length > 0
    ? products.filter(({ id }) => (!_.includes(excludedProductVariantIds, id)))
    : products;

  // The shared state
  const value = useMemo(
    () => ({
      i18n,
      locale,
      artemida,
      loadPage,
      isLoading,
      setIsLoading,
      allProducts: products,
      products: filteredProducts,
      pagination,
      productCategories,
      colorPalettes,
      productPath: (params) => (productPath({ locale, artemida, ...params })),
      productsPath: pagedProductsPath,
      showFilter,
      setShowFilter: handleSetShowFilter,
      resetShowFilter,
      showCategories,
      showColors,
      showProductsFilters: showColors || showCategories,
      toggleCartColorId: handleToggleCartColorId,
      cartColorIds,
      setTextFilter: handleSetTextFilter,
      setColorFilter: handleSetColorFilter,
      view,
      textFilter,
      colorFilter,
      showColorsGallery,
      zoomedColorId,
      zoomColorId: handleZoomColorId,
      resetZoomedColor,
      articlesGridRef,
      filtersRef,
      bodyRef,
      hasNextPage,
      infiniteScrollRef: sentryRef,
      toggleFiltersMenu,
      closeFiltersMenu,
      isFiltersMenuOpen,
      currentFilters,
      urlParams,

      currentCategoryIds,
      toggleCurrentCategory: handleToggleCurrentCategory,

      currentColors,
      toggleCurrentColor: handleToggleCurrentColor,
      popColorId,
      pushColorId,
      highlightedColorId,
      setHighlightedColorId,

      currentPrices,
      toggleCurrentPrice: handleToggleCurrentPrice,

      currentThicknesses,
      toggleCurrentThickness: handleToggleCurrentThickness,

      currentFlowerTypes,
      toggleCurrentFlowerType: handleToggleCurrentFlowerType,

      currentTextureTypes,
      toggleCurrentTextureType: handleToggleCurrentTextureType,

      currentAnilinaTypes,
      toggleCurrentAnilinaType: handleToggleCurrentAnilinaType,

      currentAppearanceTypes,
      toggleCurrentAppearanceType: handleToggleCurrentAppearanceType,

      excludedProductVariantIds,
      toggleExcludedProductVariantId: handleToggleExcludedProductVariantId,
      initiallyExcludedProductVariants,
    }),
    [
      pagination,
      isLoading,
      showFilter,
      cartColorIds,
      zoomedColorId,
      hasNextPage,
      isFiltersMenuOpen,

      currentCategoryIds,
      currentColors,
      currentPrices,
      currentThicknesses,
      currentFlowerTypes,
      currentTextureTypes,
      currentAnilinaTypes,
      currentAppearanceTypes,
      excludedProductVariantIds,
      urlParams,
      highlightedColorId,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  locale: PropTypes.string.isRequired,
  i18n: i18nShape.isRequired,
  productCategories: jsonApiShape(productCategoryShape),
  colorPalettes: jsonApiShape(colorPaletteShape),
  products: jsonApiShape(productShape),
  children: PropTypes.element.isRequired,
  cartColorIds: PropTypes.arrayOf(PropTypes.number),
  view: PropTypes.string,
  textFilter: PropTypes.string,
  colorFilter: PropTypes.string,
  artemida: PropTypes.bool,

  currentCategoryIds: PropTypes.arrayOf(PropTypes.number),
  currentColors: PropTypes.arrayOf(PropTypes.number),
  currentPrices: PropTypes.arrayOf(PropTypes.string),
};

Provider.defaultProps = {
  productCategories: {},
  colorPalettes: {},
  products: {},
  currentCategory: null,
  view: 'variants',
  textFilter: '',
  colorFilter: '',
  cartColorIds: [],
  artemida: false,

  currentCategoryIds: [],
  currentColors: [],
  currentPrices: [],
};

export default Context;
