import React from 'react';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import Icon from '../Icon';

const filters = [
  'sector',
  'price',
  'thickness',
  'color',
  'flower',
  'texture',
  'anilina',
  'appearance',
];

const FiltersSecondaryMenuFiltersSelection = ({
  i18n,
  show,
  onFilterSelection,
}) => {
  const { configurator_filters } = i18n;
  const handleClick = (event, filter) => {
    event.preventDefault();
    event.stopPropagation();
    onFilterSelection(filter)
  };

  const className = classnames(
    'FiltersSecondaryMenuFiltersSelection',
    'FiltersSecondaryMenuFilterSection',
    { show }
  );

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row">
          {
            filters.map((filter) => (
              <div className="col-sm-4 px-3 pt-0">
                <a
                  className={classnames('FiltersSecondaryMenuFiltersSelections__filter', filter)}
                  onClick={(e) => (handleClick(e, filter))}
                >
                  <div className="FiltersSecondaryMenuFiltersSelections__filterImage" />
                  <h6 className="FiltersSecondaryMenuFiltersSelections__filterLabel small">
                    {configurator_filters[filter]}
                  </h6>
                </a>
              </div>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFiltersSelection;
