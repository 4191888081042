import React, { useContext } from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../Icon';
import {
  priceKeys,
} from './utils';

// const FilterTag = ({ label, onClick, image }) => (
//   <button
//     onClick={onClick}
//     className="btn btn-light btn-sm me-2 mb-2 py-0 px-1 text-truncate d-block w-100 text-start FilterCloud__item"
//     title={label}
//   >
//     {
//       !image && (
//         <span className="FilterCloud__item-icon">
//           <Icon name="trash-alt" family="far" fw />
//         </span>
//       )
//     }
//     {
//       image && (
//         <span
//           className={
//             classnames('FilterCloud__item-image', `FilterCloud__item-image--${image}`)
//           }
//         />
//       )
//     }
//     &nbsp;
//     {label}
//   </button>
// );

const FilterTag = ({ label, onClick, image, className }) => {
  const style = image
    ? { backgroundImage: `url(${image})` }
    : {};

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>{label}</Tooltip>
      }
    >
      <a
        onClick={onClick}
        className={classnames('FilterCloud__item', className)}
        title={label}
        style={style}
      >
        <div className="FilterCloud__itemX">
          <Icon
            name="times"
            className="FilterCloud__itemXIcon"
            fw
          />
        </div>
        <div className="FilterCloud__itemIconWrapper d-none">
          <Icon
            name="trash-alt"
            className="FilterCloud__itemIcon"
            family="far"
            fw
          />
        </div>
      </a>
    </OverlayTrigger>
  );
};

const FiltersCloud = ({
  i18n,
  currentFilters,

  colorPalettes,
  toggleCurrentColor,
  currentColors,

  productCategories,
  toggleCurrentCategory,
  currentCategoryIds,

  toggleCurrentPrice,
  currentPrices,

  toggleCurrentThickness,
  currentThicknesses,

  toggleCurrentFlowerType,
  currentFlowerTypes,

  toggleCurrentTextureType,
  currentTextureTypes,

  toggleCurrentAnilinaType,
  currentAnilinaTypes,

  toggleCurrentAppearanceType,
  currentAppearanceTypes,

  products,
  allProducts,
  toggleExcludedProductVariantId,
  excludedProductVariantIds,
  initiallyExcludedProductVariants,
}) => {
  const {
    configurator_filters,
    configurator_price_filters,
    configurator_thickness_filters,
    configurator_flower_filters,
    configurator_texture_filters,
    configurator_anilina_filters,
    configurator_appearance_filters,
  } = i18n;

  const handleClick = (event, type, id) => {
    event.preventDefault();
    event.stopPropagation();

    switch (type) {
      case 'color':
        toggleCurrentColor(id);
        break;
      case 'price':
        toggleCurrentPrice(id);
        break;
      case 'thickness':
        toggleCurrentThickness(id);
        break;
      case 'category':
        toggleCurrentCategory(id);
        break;
      case 'flower':
        toggleCurrentFlowerType(id);
        break;
      case 'texture':
        toggleCurrentTextureType(id);
        break;
      case 'anilina':
        toggleCurrentAnilinaType(id);
        break;
      case 'appearance':
        toggleCurrentAppearanceType(id);
        break;
      case 'productVariant':
        toggleExcludedProductVariantId(id);
        break;

      default:
        console.error(`Unhandled type ${type}.`);
    }
  };

  const label = (type, label) => {
    if (type) {
      return `${configurator_filters[type]} ${label}`;
    } else {
      return label;
    }
  }

  const colClass = 'col-sm-4';

  return (
    <div className={classnames('FiltersCloud mt-5')}>
      <div className="container-fluid">
        <h5 className="FiltersMainMenu__title">
          {configurator_filters.active_filters}
        </h5>
      </div>

      <PerfectScrollbar className="container-fluid">
        <div className="row">
          {
            currentColors.map((colorId) => {
              const color = colorPalettes.find(({ id }) => (id === colorId)) || {};
              const image = color?.image?.thumb?.url;

              return (
                <div className={colClass} key={`color-${colorId}`}>
                  <FilterTag
                    key={`color-${colorId}`}
                    label={label('color', color.name)}
                    onClick={(event) => (handleClick(event, 'color', colorId))}
                    image={image}
                  />
                </div>
              );
            })
          }
          {
            currentPrices.map((priceId) => {
              const className = `FilterCloud__item-image--${priceId}`;

              return (
                <div className={colClass} key={`price-${priceId}`}>
                  <FilterTag
                    key={`price-${priceId}`}
                    label={label('price', configurator_price_filters[priceId])}
                    onClick={(event) => (handleClick(event, 'price', priceId))}
                    className={className}
                  />
                </div>
              );
            })
          }
          {
            currentThicknesses.map((thicknessId) => {
              const className = `FilterCloud__item-image--${thicknessId}`;

              return (
                <div className={colClass} key={`thickness-${thicknessId}`}>
                  <FilterTag
                    key={`thickness-${thicknessId}`}
                    label={label('thickness', configurator_thickness_filters[thicknessId])}
                    onClick={(event) => (handleClick(event, 'thickness', thicknessId))}
                    className={className}
                  />
                </div>
              );
            })
          }
          {
            currentCategoryIds.map((catId) => {
              const sector = productCategories.find(({ id }) => (id === catId)) || {};
              const sLabel = label('sector', sector.name)
              const image = sector?.icon?.thumb?.url;

              return (
                <div className={colClass} key={`category-${catId}`}>
                  <FilterTag
                    key={`category-${catId}`}
                    label={sLabel}
                    onClick={(event) => (handleClick(event, 'category', catId))}
                    image={image}
                  />
                </div>
              )
            })
          }
          {
            currentFlowerTypes.map((flowerId) => {
              const className = `FilterCloud__item-image--${flowerId}`;

              return (
                <div className={colClass} key={`flower-${flowerId}`}>
                  <FilterTag
                    key={`flower-${flowerId}`}
                    className={className}
                    label={label(false, configurator_flower_filters[flowerId])}
                    onClick={(event) => (handleClick(event, 'flower', flowerId))}
                  />
                </div>
              )
            })
          }
          {
            currentTextureTypes.map((textureId) => {
              const className = `FilterCloud__item-image--${textureId}`;

              return (
                <div className={colClass} key={`texture-${textureId}`}>
                  <FilterTag
                    key={`texture-${textureId}`}
                    className={className}
                    label={label('texture', configurator_texture_filters[textureId])}
                    onClick={(event) => (handleClick(event, 'texture', textureId))}
                  />
                </div>
              );
            })
          }
          {
            currentAnilinaTypes.map((anilinaId) => {
              const className = `FilterCloud__item-image--${anilinaId}`;

              return (
                <div className={colClass} key={`anilina-${anilinaId}`}>
                  <FilterTag
                    key={`anilina-${anilinaId}`}
                    className={className}
                    label={label(false, configurator_anilina_filters[anilinaId])}
                    onClick={(event) => (handleClick(event, 'anilina', anilinaId))}
                  />
                </div>
              );
            })
          }
          {
            currentAppearanceTypes.map((appearanceId) => {
              const className = `FilterCloud__item-image--${appearanceId}`;

              return (
                <div className={colClass} key={`appearance-${appearanceId}`}>
                  <FilterTag
                    key={`appearance-${appearanceId}`}
                    label={label('appearance', configurator_appearance_filters[appearanceId])}
                    onClick={(event) => (handleClick(event, 'appearance', appearanceId))}
                    className={className}
                  />
                </div>
              );
            })
          }
          {
            excludedProductVariantIds.map((vId) => {
              let product = allProducts.find(({ id }) => (id === vId));
              if (!product) {
                product = initiallyExcludedProductVariants.find(({ id }) => (id === vId));
              }
              if (!product) { return null; }

              const image = product?.image?.thumb;

              return (
                <div className={colClass} key={`variant-${vId}`}>
                  <FilterTag
                    key={`variant-${vId}`}
                    label={label('excluded', (product || {}).name)}
                    onClick={(event) => (handleClick(event, 'productVariant', vId))}
                    image={image}
                  />
                </div>
              )
            })
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersCloud;
