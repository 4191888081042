// import CC from 'CookieConsent';
// import CookiesEuBanner from 'cookies-eu-banner';

import _ from 'lodash';

const iubendaScriptClass = 'iubenda-injected-script';

const inject = (win, doc, type, url, sync = false) => {
  const element = doc.createElement(type);
  const firstScriptEl = doc.getElementsByTagName(type)[0];

  element.async = !sync;
  element.src = url;
  element.className = iubendaScriptClass;

  firstScriptEl.parentNode.insertBefore(element, firstScriptEl);
};

// Consent Solution
// <script type="text/javascript">
//   var _iub = _iub || {};
//   _iub.cons_instructions = _iub.cons_instructions || [];
//   _iub.cons_instructions.push(["init", {api_key: "AkyJa6rgM2BgJHUFhM1kPMAB87K8u9I9"}]);
// </script>
//
// <script type="text/javascript" src="https://cdn.iubenda.com/cons/iubenda_cons.js" async></script>

export default {
  ccHandler: null,

  init() {
    this.initIubenda();
    // ///
    // Disabled GA and Facebook
    // /// this.initGoogleAnalytics();
    // /// this.initFacebook();
  },

  destroy() {
    window._iub = null;

    $('script').each((idx, el) => {
      const src = $(el).attr('src');

      if (_.includes(src, 'iubenda')) {
        $(el).remove();
      }
    })
  },

  initGoogleAnalytics() {
    // if (!global.ga) {
    //   (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //   })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
    // }

    if (!global.ga) {
      // console.log('Dont track GA');
      return;
    }

    // console.log('Track GA');

    const currentPath = location.href.split('#')[0];
    const { title } = document;

    global.ga('create', gon.ga_code, 'auto');
    global.ga('set', 'anonymizeIp', true);
    global.ga('set', 'location', currentPath);
    global.ga('send', 'pageview', { title });
  },

  initFacebook() {
    // if (!global.fbq) {
    //   !function(f,b,e,v,n,t,s) {
    //     if(f.fbq)return;n=f.fbq=function(){
    //       n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)
    //     };
    //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //     n.queue=[];t=b.createElement(e);t.async=!0;
    //     t.src=v;s=b.getElementsByTagName(e)[0];
    //     s.parentNode.insertBefore(t,s)
    //   }(
    //     window, document,'script',
    //     'https://connect.facebook.net/en_US/fbevents.js'
    //    );
    // }
    if (!global.fbq) {
      // console.log('Dont track FB');
      return;
    }

    global.fbq('init', '1487659928287942');
    global.fbq('track', 'PageView');
    // console.log('FB track widdget')
  },

  // var _iub = _iub || {};
  // _iub.cons_instructions = _iub.cwons_instructions || [];
  // _iub.cons_instructions.push(["init", {api_key: "AkyJa6rgM2BgJHUFhM1kPMAB87K8u9I9"}]);

  initIubenda() {
    window._iub = {
      cons_instructions: [],
      csConfiguration: {
        ccpaAcknowledgeOnDisplay: true,
        ccpaApplies: true,
        consentOnContinuedBrowsing: false,
        enableCcpa: true,
        invalidateConsentWithoutLog: true,
        perPurposeConsent: true,
        siteId: gon.iubenda_site_id,
        whitelabel: false,
        cookiePolicyId: gon.iubenda_policy,
        lang: gon.locale,
        privacyPolicyUrl: `https://www.futuraleathers.com/${gon.locale}/privacy`,
        banner: {
          acceptButtonCaptionColor: '#FFFFFF',
          acceptButtonColor: '#009534',
          acceptButtonDisplay: true,
          backgroundColor: '#FFFFFF',
          closeButtonRejects: true,
          customizeButtonCaptionColor: '#4D4D4D',
          customizeButtonColor: '#DADADA',
          customizeButtonDisplay: true,
          explicitWithdrawal: true,
          fontSize: '14px',
          listPurposes: true,
          position: 'bottom',
          rejectButtonCaptionColor: '#FFFFFF',
          rejectButtonColor: '#009534',
          rejectButtonDisplay: true,
          textColor: '#000000',
        },
      },
    };

    _iub.cons_instructions.push([
      'init',
      { api_key: 'AkyJa6rgM2BgJHUFhM1kPMAB87K8u9I9' },
    ]);

    inject(
      window, document, 'script',
      '//cdn.iubenda.com/cs/ccpa/stub.js',
      true,
    );

    inject(
      window, document, 'script',
      '//cdn.iubenda.com/cs/iubenda_cs.js',
      false,
    );

    inject(
      window, document, 'script',
      '//cdn.iubenda.com/iubenda.js',
      false,
    );

    inject(
      window, document, 'script',
      '//cdn.iubenda.com/cons/iubenda_cons.js',
      false,
    );
  },
};
