import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ThemeProvider } from 'styled-components';
import FocusLock from 'react-focus-lock';

import theme from './theme';
import Burger from './Burger';
import Menu from './Menu';

const menuId = 'burger-menu';
// import Icon from './Icon';

const BurgerMenu = ({
  className,
  socials,
  links,
  colorfull,
}) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const classNames = ['BurgerMenu', className];
  const handleLinkClick = () => { setOpen(false); };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) { setOpen(false); }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div ref={node} className={classnames(classNames)}>
        <FocusLock disabled={!open}>
          <Burger
            open={open}
            setOpen={setOpen}
            aria-controls={menuId}
            colorfull={colorfull}
          />
          <Menu
            open={open}
            setOpen={setOpen}
            id={menuId}
            socials={socials}
            links={links}
            onLinkClick={handleLinkClick}
          />
        </FocusLock>
      </div>
    </ThemeProvider>
  );
};

BurgerMenu.propTypes = {
  className: PropTypes.string,
  colorfull: PropTypes.bool,
};

BurgerMenu.defaultProps = {
  className: null,
  colorfull: false,
};

export default BurgerMenu;
