import React, {
  useState, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Badge from './Badge';
import { showCartBadge, hideCartBadge } from '../../api/cart';

const SamplesCart = (props) => {
  const [hidden, setHidden] = useState(props.hidden);
  // const [productIds, setProductIds] = useState(props.productIds);
  // const [colorIds, setColorIds] = useState(props.colorIds);
  // const [printIds, setPrintIds] = useState(props.printIds);
  const initialState = {
    productIds: props.productIds,
    colorIds: props.colorIds,
    printIds: props.printIds,
  };

  const [cartState, handleCartUpdate] = useReducer((state, action) => {
    const { objectType, id, active } = action;
    const key = `${objectType}Ids`;
    const currentIds = state[key];
    const nextIds = !active
      ? currentIds.filter((cId) => (cId !== id))
      : [...currentIds, id];

    const nextState = {
      ...state,
      [key]: nextIds,
    };

    return nextState;
  }, initialState);

  const { productIds, colorIds, printIds } = cartState;
  const { url, i18n } = props;
  const amount = productIds.length + colorIds.length + printIds.length;

  useEffect(() => {
    const handleEvent = (event, objectType, id, active) => handleCartUpdate({
      event,
      objectType,
      id,
      active,
    });

    $(document).on('AddToCartCta:change', handleEvent);

    return () => {
      $(document).off('AddToCartCta:change', handleEvent);
    };
  }, []);

  const isActive = amount > 0;
  const label = amount === 1
    ? i18n.selectedOneItem.replace('PLACEHOLDER', 1)
    : i18n.selectedManyItem.replace('PLACEHOLDER', amount);

  const toggleCartVisibility = () => {
    const api = hidden ? showCartBadge : hideCartBadge;

    api()
      .then((response) => (response.json()))
      .then((response) => {
        setHidden(!hidden);
      });
  };
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    toggleCartVisibility();
  };

  return (
    <Badge
      active={isActive}
      hidden={hidden}
      label={label}
      url={url}
      onClose={handleClose}
      i18n={i18n}
    />
  );
};

SamplesCart.propTypes = {
  productIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  colorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  printIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  url: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({
    selectedOneItem: PropTypes.string,
    selectedManyItem: PropTypes.string,
  }).isRequired,
};

export default SamplesCart;
