import React from 'react';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import parse from 'html-react-parser';

import Icon from '../Icon';
import AddPrintButton from '../AddPrintButton';

const PrintTile = (props) => {
  if (!props.print) { return null; }

  const {
    print,
    onCartButtonClick,
    onZoomButtonClick,
    cartPrintIds,
  } = props;
  const {
    id,
    name,
    description,
    image,
  } = print;

  const { thumb } = image;
  const isStarred = _.includes(cartPrintIds, id);

  const handleCartButtonClick = () => {
    onCartButtonClick(id)
  };

  const handleZoomClick = (e) => {
    e.preventDefault();

    onZoomButtonClick(id);
  };

  return (
    <div className="col-md-4">
      <div className="PrintTile">
        <div
          className="PrintTile__image"
          style={{ backgroundImage: `url('${thumb}')` }}
        />

        <div className="PrintTile__backdrop" />

        <div className="PrintTile__preview">
          <h6 className="PrintTile__previewLabel">
            {name}
          </h6>
        </div>

        <div className="PrintTile__content">
          <a href="#zoom" className="PrintTile__anchor" onClick={handleZoomClick}>
            <span className="btn btn--h6 PrintTile__article">
              {name}
            </span>

            <h6 className="PrintTile__color mt-2 px-5">
              {parse(description || '')}
            </h6>
          </a>

          <div className="PrintTile__ctas">
            <a className="PrintTile__cta" href="#zoom" onClick={handleZoomClick}>
              <Icon name="search" fw />
            </a>
            &nbsp;
            <AddPrintButton
              starred={isStarred}
              onClick={handleCartButtonClick}
              className="PrintTile__cta"
              printId={id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PrintTile.propTypes = {
};

PrintTile.defaultProps = {
};

export default PrintTile;
