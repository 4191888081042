import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import Slick from 'react-slick';
import Slide from './Slide';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slickSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 2500,
  autoplaySpeed: 7500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  draggable: false,
  swipeToSlide: false,
  swipe: false,
  lazyLoad: false,
  autoplay: true,
};

class Slider extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: props.data,
      height: $(window).height(),
    };

    _.bindAll(this, 'updateHeight');
  }

  componentDidMount() {
    this.setState({
      height: $(window).height(),
    });

    $(window).on('resize', this.updateHeight);
  }

  componentWillUnmount() {
    $(window).off('resize', this.updateHeight);
  }

  updateHeight() {
    this.setState({
      height: $(window).height(),
    });
  }

  render() {
    const { height, data } = this.state;
    const { mobile, tablet } = this.props;

    return (
      <Slick {...slickSettings}>
        {
          data.map((datum) => (
            <div key={datum.id}>
              <Slide
                title={datum.title}
                subtitle={datum.subtitle}
                image={datum.image}
                titleImage={datum.titleImage}
                videoId={datum.videoId}
                ctaUrl={datum.ctaUrl}
                ctaText={datum.ctaText}
                ctaTarget={datum.ctaTarget}
                mobile={mobile}
                tablet={tablet}
                height={height}
              />
            </div>
          ))
        }
      </Slick>
    );
  }
}

Slider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mobile: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
};

export default Slider;
