import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './Icon';

const AddProductButton = ({
  isStarred,
  onClick,
  productId,
  showLabel,
  i18n,
  iconProps,
  ...props
}) => {
  const className = classnames('AddProductButton', props.className, {
    'AddProductButton--active': isStarred,
  });

  const handleClick = (e) => {
    e.preventDefault();

    onClick(productId);
  };

  // WARNING: React does not work well with the Icon (font-awesome) component
  // This ugly render function is required to ensure a correct rerender
  return (
    <>
      {
        isStarred && (
          <a className={className} href="#unstar" onClick={handleClick}>
            <Icon name="star" family="fas" fw {...iconProps} />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.saved_product}
                </span>
              )
            }
          </a>
        )
      }
      {
        !isStarred && (
          <a className={className} href="#star" onClick={handleClick}>
            <Icon name="star" family="far" fw {...iconProps} />
            {
              showLabel && (
                <span>
                  &nbsp;
                  {i18n.save_product}
                </span>
              )
            }
          </a>
        )
      }
    </>
  );
};

AddProductButton.propTypes = {
  isStarred: PropTypes.bool,
  showLabel: PropTypes.bool,
  onClick: PropTypes.func,
  productId: PropTypes.number.isRequired,
  iconProps: PropTypes.shape({}),
  i18n: PropTypes.shape({
    save_product: PropTypes.string,
    saved_product: PropTypes.string,
  }),
  className: PropTypes.string,
};

AddProductButton.defaultProps = {
  isStarred: false,
  onClick: () => {},
  className: '',
  showLabel: false,
  i18n: {},
  iconProps: {},
};

export default AddProductButton;
