import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import FiltersSecondaryMenuFiltersSelection from './FiltersSecondaryMenuFiltersSelection';
import FiltersSecondaryMenuFilterColor from './FiltersSecondaryMenuFilterColor';
import FiltersSecondaryMenuFilterPrice from './FiltersSecondaryMenuFilterPrice';
import FiltersSecondaryMenuFilterThickness from './FiltersSecondaryMenuFilterThickness';
import FiltersSecondaryMenuFilterSector from './FiltersSecondaryMenuFilterSector';
import FiltersSecondaryMenuFilterFlower from './FiltersSecondaryMenuFilterFlower';
import FiltersSecondaryMenuFilterTexture from './FiltersSecondaryMenuFilterTexture';
import FiltersSecondaryMenuFilterAnilina from './FiltersSecondaryMenuFilterAnilina';
import FiltersSecondaryMenuFilterAppearance from './FiltersSecondaryMenuFilterAppearance';
import Icon from '../Icon';


import 'react-perfect-scrollbar/dist/css/styles.css';

const FiltersSecondaryMenuContent = ({
  onFilterSelection,
  showFilter,
  setShowFilter,
  i18n,

  productCategories,
  toggleCurrentCategory,
  currentCategoryIds,

  colorPalettes,
  toggleCurrentColor,
  currentColors,

  toggleCurrentPrice,
  currentPrices,

  toggleCurrentThickness,
  currentThicknesses,

  toggleCurrentFlowerType,
  currentFlowerTypes,

  toggleCurrentTextureType,
  currentTextureTypes,

  toggleCurrentAnilinaType,
  currentAnilinaTypes,

  toggleCurrentAppearanceType,
  currentAppearanceTypes,
}) => {
  return (
    <div className="FiltersSecondaryMenuContent">
        <FiltersSecondaryMenuFiltersSelection
          onFilterSelection={onFilterSelection}
          show={showFilter === 'menu'}
          i18n={i18n}
        />

        <FiltersSecondaryMenuFilterColor
          i18n={i18n}
          show={showFilter === 'color'}
          colorPalettes={colorPalettes}
          toggleCurrentColor={toggleCurrentColor}
          currentColors={currentColors}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterPrice
          i18n={i18n}
          show={showFilter === 'price'}
          toggleCurrentPrice={toggleCurrentPrice}
          currentPrices={currentPrices}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterThickness
          i18n={i18n}
          show={showFilter === 'thickness'}
          toggleCurrentThickness={toggleCurrentThickness}
          currentThicknesses={currentThicknesses}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterSector
          i18n={i18n}
          show={showFilter === 'sector'}
          productCategories={productCategories}
          toggleCurrentCategory={toggleCurrentCategory}
          currentCategoryIds={currentCategoryIds}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterFlower
          i18n={i18n}
          show={showFilter === 'flower'}
          toggleCurrentFlowerType={toggleCurrentFlowerType}
          currentFlowerTypes={currentFlowerTypes}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterTexture
          i18n={i18n}
          show={showFilter === 'texture'}
          toggleCurrentTextureType={toggleCurrentTextureType}
          currentTextureTypes={currentTextureTypes}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterAnilina
          i18n={i18n}
          show={showFilter === 'anilina'}
          toggleCurrentAnilinaType={toggleCurrentAnilinaType}
          currentAnilinaTypes={currentAnilinaTypes}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />

        <FiltersSecondaryMenuFilterAppearance
          i18n={i18n}
          show={showFilter === 'appearance'}
          toggleCurrentAppearanceType={toggleCurrentAppearanceType}
          currentAppearanceTypes={currentAppearanceTypes}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
    </div>
  );
};

export default FiltersSecondaryMenuContent;
