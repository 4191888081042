import React, { useContext } from 'react';

import Context from './Context';
import FormModal from './FormModal';
import IconBadge from './IconBadge';

const Body = (props) => {
  const {
    open,
    loading,
    onChatToggle,
    showContent,
    content,
    showInternalLinkContent,
    internalLinkContent,
    onBack,
    i18n,
  } = useContext(Context);

  return (
    <div className="ChatForm__Body">
      <FormModal
        open={open}
        content={content}
        loading={loading}
        onBack={onBack}
        showContent={showContent}
        showInternalLinkContent={showInternalLinkContent}
        internalLinkContent={internalLinkContent}
        i18n={i18n}
      />

      <IconBadge
        open={open}
        onClick={onChatToggle}
      />
    </div>
  );
};

Body.propTypes = {};

export default Body;

