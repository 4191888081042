import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import _ from 'lodash';

import { productVariantShape } from '../utils';
import Icon from '../Icon';

const ColorTile = ({ color, Context, zoomOnClick, serigraphy, className }) => {
  const [isExcluded, setIsExcluded] = useState(false);
  const {
    i18n,
    locale,
    cartColorIds,
    toggleCartColorId,
    toggleExcludedProductVariantId,
    zoomColorId,
    productPath,
    highlightedColorId,
  } = useContext(Context);
  const {
    id,
    name,
    product_name,
    product_id,
    product_slug,
  } = color;
  const { thumb } = color.image || {};
  const product = { id: product_id, slug: product_slug, serigraphy };
  const url = zoomOnClick
    ? '#zoom'
    : productPath({ product, locale });

  const isStarred = _.includes(cartColorIds, id);

  const handleExclude = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsExcluded(true);

    setTimeout(() => {
      toggleExcludedProductVariantId(id);
    }, 100);
  };

  return (
    <div className={classnames(
      'ColorTile',
      className,
      {
        fade: isExcluded,
        highlighted: highlightedColorId === id,
      }
    )}>
      <a className="ColorTile__exclude" href="#exlude" onClick={handleExclude}>
        <Icon name="times" size="lg" fw />
      </a>

      <div
        className="ColorTile__image"
        style={{ backgroundImage: `url('${thumb}')` }}
      />

      <div className="ColorTile__backdrop" />

      <div className="ColorTile__preview">
        <h6 className="ColorTile__previewLabel">{name}</h6>
      </div>

      <div className="ColorTile__content">
        <a href={url} className="ColorTile__anchor" target="_blank">
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-${id}`}>
                {i18n.discover_the_article}
              </Tooltip>
            }
          >
            <span className="btn btn--h6 ColorTile__article">
              {product_name}
            </span>
          </OverlayTrigger>

          <h6 className="ColorTile__color mt-2">{name}</h6>
        </a>

        <div className="ColorTile__ctas d-none">
          <a className="ColorTile__cta" href="#exlude" onClick={handleExclude}>
            <Icon name="trash-alt" family="far" fw />
          </a>
        </div>
      </div>
    </div>
  );
};

ColorTile.propTypes = {
  color: productVariantShape.isRequired,
  zoomOnClick: PropTypes.bool,
  serigraphy: PropTypes.bool,
};

ColorTile.defaultProps = {
  zoomOnClick: false,
  serigraphy: false,
};

export default ColorTile;
