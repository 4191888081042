import $ from 'jquery';

export default {
  getElements() {
    return $('[data-scrolldown]');
  },

  init() {
    $(document).on('click', '[data-scrolldown]', (e) => {
      e.preventDefault();
      window.scrollTo(0, window.innerHeight);
    });

    $(document).on('click', '[data-scrollto]', (e) => {
      e.preventDefault();
      const selector = $(e.currentTarget).data('scrollto');
      const $target = $(selector);
      window.scrollTo(0, $target.offset().top);
    });
  },

  destroy() {
    $(document).off('click', '[data-scrolldown]');
    $(document).off('click', '[data-scrollto]');
  },
};
