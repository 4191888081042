import React from 'react';

const BarChart = ({ rows, cols, active }) => {
  const colHeight = (col) => (
    active ? col.height : '0'
  );

  return (
    <div className="BalanceBarChart BalanceBarChart--legend">
      <div className="BalanceBarChart__rows">
        {
          (new Array(rows)).fill(null).map((r, i) => (
            <div className="BalanceBarChart__row" key={i}/>
          ))
        }
      </div>

      <div className="BalanceBarChart__cols">
        {
          cols.map((col, i) => (
            <div
              className={`BalanceBarChart__col ${col.className}`}
              style={{ left: col.left, width: col.width || '30px' }}
              key={i}
            >
              <div className="BalanceBarChart__col-bar-wrapper">
                <div className="BalanceBarChart__col-bar"
                  style={{ backgroundColor: col.color, height: colHeight(col) }}
                />
              </div>

              {
                col.label && (
                  <div className="BalanceBarChart__col-label">
                    <span className={`text-nowrap ${col.labelClassName || ''}`}>
                      {col.label}
                    </span>
                  </div>
                )
              }

              <div className="BalanceBarChart__col-legend">
                <span className={`text-nowrap ${col.legendClassName}`}>
                  {col.legend}
                </span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default BarChart;
