import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Provider } from './Context';
import Header from './Header';
import Body from './Body';
import ColorsView from './ColorsView';

const ProductPage = (props) => (
  <Provider {...props}>
    <div className={classnames('ProductPage')}>
      <div className="container">
        <Header />

        <hr className="my-4" />

        <Body />
      </div>

      <div className="container-fluid">
        <ColorsView />
      </div>
    </div>
  </Provider>
);

ProductPage.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
};

export default ProductPage;
