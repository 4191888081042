import React, { useContext } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';

import Context from './Context';
import FilterItem from './FilterItem';

const breakpoint = (resolution, slidesToShow) => ({
  breakpoint: resolution,
  arrows: true,
  dots: false,
  settings: {
    slidesToShow,
    slidesToScroll: 1,
  }
});

const maxOr = (items, max) => (max < items ? max : items);
const slickSettings = (max) => ({
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: maxOr(6, max),
  slidesToScroll: 1,
  responsive: [
    breakpoint(1440, maxOr(6, max)),
    breakpoint(1200, maxOr(6, max)),
    breakpoint(1024, maxOr(4, max)),
    breakpoint(992,  maxOr(3, max)),
    breakpoint(768,  maxOr(2, max)),
    breakpoint(576,  maxOr(1, max)),
    breakpoint(0,    maxOr(1, max)),
  ],
});

const CategoriesFilters = () => {
  const {
    productCategories,
    showCategories,
    currentCategory,
    setCurrentCategory,
  } = useContext(Context);

  const settings = slickSettings(productCategories.length);
  const className = classnames('ProductsFiltersRow  d-flex align-items-center', {
    active: showCategories,
  });

  const handleClick = (id) => {
    setCurrentCategory(id);
  };

  return (
    <div className={className}>
      <div className="container  ProductsFiltersRow__content">
        <Slider {...settings}>
          {
            productCategories.map(({ id, icon, name }) => (
              <FilterItem
                id={id}
                key={id}
                name={name}
                image={icon.thumb.url}
                onClick={handleClick}
                active={id === currentCategory}
              />
            ))
          }
        </Slider>
      </div>
    </div>
  );
};

CategoriesFilters.propTypes = {
};

CategoriesFilters.defaultProps = {
};

export default CategoriesFilters;
