import $ from 'jquery';
import d3 from 'd3';
import 'topojson';
import CanadaDatamap from 'datamaps/dist/datamaps.can';
import Datamap from 'datamaps/dist/datamaps.all';

export default {
  defaultSelector: 'distributors-map',

  defaultOptions: {
    projection: 'mercator',
    fills: {
      defaultFill: 'rgba(219, 219, 219, 0.9)',
      mainFill: '#009534',
      distributorFill: '#C6E5D3',
    },
    data: {
      ITA: {
        fillKey: 'mainFill',
      },
    },
    geographyConfig: {
      // highlightOnHover: true,
      // popupOnHover: true,
      borderWidth: 1,
      borderOpacity: 1,
      borderColor: '#FDFDFD',
      popupTemplate: (geography, data) => {
        // this function should just return a string
        const { caption } = (data || {});

        if (caption && (!gon.mobile && !gon.tablet)) {
          return `<div class="world-caption-wrap">${caption}</div>`;
        }

        return '<div class="world-caption-wrap world-caption-wrap--hidden"></div>';
      },
      popupOnHover: true, // disable the popup while hovering
      highlightOnHover: false,
    },
    responsive: true,
  },

  countryOptions: {
    world: {
      done: (datamap) => {
        datamap.svg.selectAll('.datamaps-subunit').on('click', (geography) => {
          if (geography.id === 'CAN') {
            // const url = Routes.distributors_path({ country: 'canada', locale: gon.locale })
            const url = `/${gon.locale}/about-us/canada#world`;
            window.location.href = url;
          }
        });
      },
    },
    CAN: {
      scope: 'can',
      setProjection: (element) => {
        const projection = d3.geo.mercator()
          .center([-98, 62])
          .scale(400)
          .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
        const path = d3.geo.path()
          .projection(projection);

        return { path, projection };
      },
    },
  },

  buildOptions(options, distributors) {
    const extOptions = $.extend(
      this.defaultOptions,
      this.countryOptions[gon.country_scope] || {},
      options || {},
    );

    $.each(distributors, (code, data) => {
      let caption = '';

      extOptions.data[code] = extOptions.data[code] || {
        fillKey: 'distributorFill',
      };

      $.each(data.data, (index, distributor) => {
        caption += distributor.caption;
      });

      extOptions.data[code].caption = caption;
    });

    return extOptions;
  },

  init(selector, options) {
    const extOptions = this.buildOptions(options, gon.distributors);

    const DM = gon.country_scope === 'CAN'
      ? CanadaDatamap
      : Datamap;

    return new DM({
      element: document.getElementById(selector || this.defaultSelector),
      ...extOptions,
    });
  },

  destroy() {
    // selector = selector || this.defaultSelector;
    // $(selector).datamaps('destroy');
  },
};
