import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon';

const Badge = ({
  onClose,
  hidden,
  active,
  url,
  label,
}) => {
  const handleClose = (e) => {
    onClose(e);
  };

  const className = classnames('cart-badge', {
    'cart-badge--hidden': hidden,
    'cart-badge--active': active,
  });

  return (
    <a className={className} href={url}>
      <Icon name="star" additionalClasses="cart-badge__icon" />
      &nbsp;
      <span className="cart-badge__label">{label}</span>
      <span className="cart-badge__close" onClick={handleClose}>
        &times;
      </span>
    </a>
  );
};

Badge.propTypes = {
  active: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
};

export default Badge;
