import React, { useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import parse from 'html-react-parser';

import Context from './Context';

const SpecsDatum = ({ name, value }) => (
  <tr className="row">
    <td className="col-4">
      {name}
      :
    </td>

    <td title={value} className="col-8 text-truncate">
      {parse(value || '')}
    </td>
  </tr>
);

const PriceInfoDatum = ({ priceInfo }) => {
  if (!priceInfo) { return null; }

  const {
    price, symbol, download_url, download_label,
  } = priceInfo;

  return (
    <tr className="row">
      <td className="col-4">
        {price}
        &nbsp;
        <small>{symbol}</small>
      </td>

      <td className="col-8 text-truncate" colSpan="2">
        {
          download_url && download_url.length > 0
            ? <a href={download_url} target="_blank">{download_label}</a>
            : '-'
        }
      </td>
    </tr>
  );
}

const ProductDatatable = () => {
  const {
    details,
  } = useContext(Context);

  if (!details) {
    return (
      <div className="p-5 text-center">
        <Spinner animation="grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>

        <h6 className="mt-3">Loading...</h6>
      </div>
    );
  }

  const { specs, price_info } = details;

  return (
    <div className="px-2 mx-1">
      <table className="table table-success-inverted">
        <tbody>
          {
            specs.map(({ name, value }) => (
              <SpecsDatum
                key={name}
                name={name}
                value={value}
              />
            ))
          }

          <PriceInfoDatum priceInfo={price_info} />
        </tbody>
      </table>
    </div>
  );
};

export default ProductDatatable;
