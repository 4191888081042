import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';

const FilterItem = ({
  id,
  image,
  onClick,
  url,
  active,
  name,
  compact,
  className
}) => {
  const classNameWrap = classnames('FilterItem', className, { active, compact });
  const handleClick = (e) => {
    e.preventDefault();
    onClick(id);
  };

  const href = url || `#${id}`;

  return (
    <div className={classNameWrap}>
      <a className="FilterItem__anchor" href={href} onClick={handleClick}>
        <div
          className="FilterItem__image"
          style={{ backgroundImage: `url('${image}')` }}
        />

        <div className="FilterItem__backdrop" />

        <div className="FilterItem__title">
          <h6 className="FilterItem__titleLabel text-uppercase small">{name}</h6>
          <div className="FilterItem__check">
            <Icon name="check" size="2x" fw />
          </div>
        </div>
      </a>
    </div>
  );
};

FilterItem.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  url: PropTypes.string,
  active: PropTypes.bool,
  compact: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

FilterItem.defaultProps = {
  compact: false,
  onClick: () => {},
  url: null,
  active: false,
};

export default FilterItem;
