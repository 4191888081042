import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Pie from './Pie';
import BarChart from './BarChart';

import revenuesImage from '../../images/balance-prosperity-revenues.png';
import ebitdaImage from '../../images/balance-prosperity-ebitda.png';
import euroImage from '../../images/balance-prosperity-euro.png';
import futuraImage from '../../images/balance-prosperity-futura.png';
import greenBlueImage from '../../images/balance-prosperity-greenblue.png';

const slideColor = '#C7DC6A';
const slideColorAlt = '#97A552';
const slideColorLight = '#D8E68F';

const SlideProsperity = ({ code, label, i18n, active }) => {
  const {
    one, two, three, four, five, six, seven, eight, nine,
  } = i18n;

  return (
    <div className="BalanceSliderSlide BalanceSliderSlide--prosperity">
      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-one with-arrow">
          <div className="">
            <h5 className="mb-5">{one.title}</h5>
          </div>

          <img
            src={revenuesImage}
            className="d-block img-fluid w-50 pe-5"
          />
        </div>

        <div className="col-md-8 col-lg-5 BalanceSliderSlide__step BalanceSliderSlide__step-two">
          <div className="row">
            <div className="col-sm-4">
              <h6
                className="BalanceSliderSlide__title text-nowrap ps-2"
                style={{ borderLeft: `2px solid ${slideColorLight}` }}
              >
                44.985.162 €
              </h6>
            </div>

            <div className="col-sm-4">
              <h6
                className="BalanceSliderSlide__title text-nowrap ps-2"
                style={{ borderLeft: `2px solid ${slideColor}` }}
              >
                32.507.576 €
              </h6>
            </div>

            <div className="col-sm-4">
              <h6
                className="BalanceSliderSlide__title text-nowrap ps-2"
                style={{ borderLeft: `2px solid ${slideColorAlt}` }}
              >
                <strong>40.539.100 €</strong>
              </h6>
            </div>
          </div>

          <BarChart
            rows={6}
            cols={[
              { label: '2019', className: 'col-2019', height: '78%', color: slideColorLight, left: 0 },
              { label: '2020', className: 'col-2020', height: '54%', color: slideColor, left: '35.2%' },
              { label: '2021', className: 'col-2021', height: '67%', color: slideColorAlt, left: '70.5%' },
            ]}
            active={active}
          />
        </div>

        <div className="col-md-4 col-lg-3 BalanceSliderSlide__step BalanceSliderSlide__step-three">
          <img
            src={futuraImage}
            className="img-fluid p-3 mx-auto w-50"
          />
        </div>
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-four with-arrow">
          <h5 className="mb-5">
            {four.title}
          </h5>

          <img
            src={ebitdaImage}
            className="d-block img-fluid w-50 pe-5"
          />
        </div>

        <div className="col-lg-5 BalanceSliderSlide__step BalanceSliderSlide__step-five">
          <div className="row">
            <div className="col-sm-4">
              <h6 className="BalanceSliderSlide__text col-2019">
                <span className="BalanceSliderSlide__strong-number-4">
                  +4
                </span>
                <span style={{ verticalAlign: 'super' }}>
                  %
                </span>
              </h6>
            </div>

            <div className="col-sm-4">
              <h6 className="BalanceSliderSlide__text col-2020">
                <span className="BalanceSliderSlide__strong-number-4">
                  +10
                </span>
                <span style={{ verticalAlign: 'super' }}>
                  %
                </span>
              </h6>
            </div>

            <div className="col-sm-4">
              <h6 className="BalanceSliderSlide__text col-2021">
                <span className="BalanceSliderSlide__strong-number-4">
                  +15
                </span>
                <span style={{ verticalAlign: 'super' }}>
                  %
                </span>
              </h6>
            </div>
          </div>

          <div className="BalanceSliderSlide__chartWrapper">
            <BarChart
              rows={4}
              cols={[
                { label: '2019', className: 'col-2019', height: '25%', color: slideColorLight, left: 0, width: '1px' },
                { label: '2020', className: 'col-2020', height: '51%', color: slideColor, left: '35.2%', width: '1px' },
                { label: '2021', className: 'col-2021', height: '75%', color: slideColorAlt, left: '70.5%', width: '1px' },
              ]}
              active={active}
            />

            <div className="BalanceSliderSlide__chartLine" />

            <div className="BalanceSliderSlide__chartDot item-2019" />
            <div className="BalanceSliderSlide__chartDot item-2020" />
            <div className="BalanceSliderSlide__chartDot item-2021" />
          </div>
        </div>

        <div className="col-md-3" />
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-seven with-arrow">
          <h5 className="mb-5">
            {seven.title}
          </h5>

          <img
            src={euroImage}
            className="d-block img-fluid w-50 pe-5"
          />
        </div>

        <div className="col-md-4 col-lg-2 BalanceSliderSlide__step BalanceSliderSlide__step-eight">
          <h6 className="BalanceSliderSlide__title">
            <span className="BalanceSliderSlide__strong-number-5">
              +13
            </span>
            <span style={{ verticalAlign: 'super' }}>
              %
            </span>
          </h6>

          <h5 className="BalanceSliderSlide__title">
            {eight.title}
          </h5>

          <h6 className="mt-4 BalanceSliderSlide__text">
            {eight.text}
          </h6>
        </div>

        <div className="col-md-4 offset-lg-1 col-lg-2 BalanceSliderSlide__step BalanceSliderSlide__step-nine">
          <h6 className="BalanceSliderSlide__title">
            <span className="BalanceSliderSlide__strong-number-5">
              +1
            </span>
          </h6>

          <h5 className="BalanceSliderSlide__title">
            {nine.title}
          </h5>

          <h6 className="mt-4 BalanceSliderSlide__text">
            {nine.text}
          </h6>
        </div>

        <div className="col-md-4 col-lg-3 BalanceSliderSlide__step BalanceSliderSlide__step-ten">
          <img
            src={greenBlueImage}
            className="img-fluid p-3 mx-auto w-50 mb-3"
          />
        </div>
      </div>
    </div>
  );
};

SlideProsperity.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SlideProsperity;
