import React from 'react';

import { Provider } from './Context';
import Body from './Body'

const DownloadModal = (props) => (
  <Provider {...props}>
    <div className="ProductsConfiguratorPage__DownloadModal ConfiguratorDownloadModal">
      <Body />
    </div>
  </Provider>
);

DownloadModal.propTypes = {};

export default DownloadModal;
