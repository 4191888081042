/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Vendor libs
import $ from 'jquery';
import { Turbo } from '@hotwired/turbo-rails';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@oddcamp/cocoon-vanilla-js';
import Headroom from 'headroom.js';

global.$ = $;
global.jQuery = $;

import '../behaviours/boot';

// require('jquery');
// require('@nathanvda/cocoon');

// Headings font
import '@fontsource/metropolis'; // Defaults to weight 400.
import '@fontsource/metropolis/100.css'; // lighter
import '@fontsource/metropolis/300.css'; // light
import '@fontsource/metropolis/700.css'; // bold
import '@fontsource/libre-caslon-text';

// Body font
import '@fontsource/lato'; // Defaults to weight 400.

// Stylesheets
import '../stylesheets/application.sass';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

// earlier: var ujs = require("react_ujs");
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

// TODO: move into a behaviour file
document.addEventListener('turbo:load', async () => {
  // select your header or whatever element you wish
  const header = document.getElementById('header');

  if (!header) { return; }

  const headroom = new Headroom(header, {
    onPin()  {
      // console.log('onPin');
      $('body').addClass('headroom-pin');
      $('body').removeClass('headroom-unpin');
    },
    onUnpin() {
      // console.log('onUnpin');
      $('body').addClass('headroom-unpin')
      $('body').removeClass('headroom-pin')
    }
  });
  headroom.init();
});

window.addEventListener('popstate', () => {
  // Ugly hack:
  // https://github.com/hotwired/turbo/issues/50#issuecomment-949753435
  Turbo.visit(document.location);
});
