import React, {
  useState,
  createContext,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';

import {
  deserializeJsonApi,
//  i18nShape,
//  jsonApiShape,
//  productCategoryShape,
//  colorPaletteShape,
//  productShape,
//  productVariantShape,
} from '../utils';

// import { productPath, productsPath, fetchProducts } from '../../api/products';
import {
  addPrintToCart,
  removePrintFromCart,
} from '../../api/cart';

const Context = createContext({});

const normalize = (str) => _.trim(_.toLower(_.deburr(str || '')));

const filterPrints = (prints, text) => {
  if (text.length === 0) {
    return prints;
  }

  return prints.filter(({ name, description }) => (
    _.includes(normalize(name), text) || _.includes(normalize(description), text)
  ));
};

export const Provider = ({
  i18n,
  locale,
  children,
  ...props
}) => {
  const jsonApiProductPrints = props.productPrints;
  // const jsonApiFurnishingsPrints = props.furnishingsPrints;
  // const jsonApiLeatherwearPrints = props.leatherwearPrints;
  const allProductPrints = deserializeJsonApi(jsonApiProductPrints);
  // const allFurnishingsPrints = deserializeJsonApi(jsonApiFurnishingsPrints);
  // const allLeatherwearPrints = deserializeJsonApi(jsonApiLeatherwearPrints);
  const [textFilter, setTextFilter] = useState('');
  const productPrints = filterPrints(allProductPrints, textFilter);
  // const furnishingsPrints = filterPrints(allFurnishingsPrints, textFilter);
  // const leatherwearPrints = filterPrints(allLeatherwearPrints, textFilter);

  const [cartPrintIds, setCartPrintIds] = useState(props.cartPrintIds);
  const [zoomedPrintId, setZoomedPrintId] = useState(null);
  // const furnishingsPrintsIds = furnishingsPrints.map(({ id }) => (id));
  const zoomViewPrints = productPrints;
  // const zoomViewPrints = _.includes(furnishingsPrintsIds, zoomedPrintId)
  //   ? furnishingsPrints
  //   : leatherwearPrints;

  //
  // State helpers
  //
  const handleToggleCartPrint = (id) => {
    const alreadySelected = _.includes(cartPrintIds, id);
    const willBeSelected = !alreadySelected; // next state
    const nextCartPrintIds = alreadySelected
      ? cartPrintIds.filter((cId) => (cId !== id))
      : [...cartPrintIds, id];
    const toogleCart = willBeSelected ? addPrintToCart : removePrintFromCart;

    toogleCart({ locale, printId: id })
      .then((response) => (response.json()));

    $(document).trigger('AddToCartCta:change', ['print', id, willBeSelected]);

    setCartPrintIds(nextCartPrintIds);
  };

  //
  // Internal refs
  //
  /// const articlesGridRef = useRef(null);
  /// const filtersRef = useRef(null);
  const bodyRef = useRef(null);

  const handleSetTextFilter = (text) => {
    const normalizedText = normalize(text);

    setTextFilter(normalizedText);
  };

  const scrollIntoBody = () => {
    if (bodyRef) {
      bodyRef.current.scrollIntoView();
    }
  };

  const resetZoomedPrint = () => { setZoomedPrintId(null); };
  const handleShowZoomedPrint = (id) => {
    setZoomedPrintId(id);
  };

  // The shared state
  const value = useMemo(
    () => ({
      i18n,
      locale,
      textFilter,
      setTextFilter: handleSetTextFilter,
      scrollIntoBody,
      bodyRef,
      // furnishingsPrints,
      // leatherwearPrints,
      productPrints,
      cartPrintIds,
      toggleCartPrint: handleToggleCartPrint,
      showZoomedPrint: handleShowZoomedPrint,
      resetZoomedPrint,
      zoomedPrintId,
      zoomViewPrints,
    }),
    [
      zoomedPrintId,
      cartPrintIds,
      zoomViewPrints,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  locale: PropTypes.string.isRequired,
  furnishingsPrints: PropTypes.shape({}).isRequired,
  leatherwearPrints: PropTypes.shape({}).isRequired,
  // i18n: i18nShape.isRequired,
  children: PropTypes.element.isRequired,
};

Provider.defaultProps = {
};

export default Context;
