import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import ProductDetail from './ProductDetail';
import ProductTestSpecs from './ProductTestSpecs';
import ColorsGallery from '../ColorsGallery';

import ProductImage from './ProductImage';

const Header = () => {
  const {
    i18n,
    locale,
    product,
    colors,
    toggleCartColorId,
    cartColorIds,

    zoomedColorId,
    resetZoomedColor,
  } = useContext(Context);

  const {
    name,
  } = product;
  const className = classnames('ProductPage__Body');

  return (
    <div className={className}>
      <ColorsGallery
        i18n={i18n}
        locale={locale}
        colors={colors}
        activeColorId={zoomedColorId}
        onClose={resetZoomedColor}
        onColorAddedToCart={toggleCartColorId}
        cartColorIds={cartColorIds}
        showInfoButton={false}
      />

      <div className="row">
        <div className="col-md-4">
          <h1 className="text-success">{name}</h1>

          <ProductDetail />
        </div>

        <div className="col-md-8">
          <ProductImage product={product} />
        </div>
      </div>

      <ProductTestSpecs />
    </div>
  );
};

export default Header;
