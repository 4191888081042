import React from 'react';
import PropTypes from 'prop-types';

import ProductTileWrapper from './ProductTileWrapper';
import ProductTile from './ProductTile';
import { productShape } from '../utils';

const ProductsView = ({ products }) => (
  <>
    {
      products.map((product, index) => (
        <ProductTileWrapper index={index} key={product.id}>
          <ProductTile
            key={product.id}
            product={product}
            index={index}
          />
        </ProductTileWrapper>
      ))
    }
  </>
);

ProductsView.propTypes = {
  products: PropTypes.arrayOf(productShape).isRequired,
};

ProductsView.defaultProps = {
};

export default ProductsView;
