import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { newUserConfiguration, createUserConfiguration } from '../../../api/productsUserConfigurations';

const Context = createContext({});
const formId = '#new_products_user_configuration';
const closeButtonSel = '.chat-contact-close';

export const Provider = ({ children, searchParams }) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('Loading...');

  const onToggle = () => { setOpen((o) => (!o)) }
  const loadRemoteContent = () => {
    setLoading(true);

    newUserConfiguration(searchParams)
      .then((response) => (response.text()))
      .then((response) => {
        setContent(response);
        setLoading(false);
      })
      .catch((error) => {
        setContent('Ops.. Somethink went wrong');
        setLoading(false);
      });
  };

  const handleDownloadButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onToggle();
  }

  // Component did mount
  useEffect(() => {
    let mounted = true;
    if (mounted) { loadRemoteContent(); }

    $('#download-selection').on('click', handleDownloadButtonClick);

    return () => {
      mounted = false;
      $('#download-selection').off('click', handleDownloadButtonClick);
    }
  }, []);

  useEffect(() => {
    loadRemoteContent();
  }, [searchParams]);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    // const qString =  $(formId).serialize();
    const rawData =  $(formId).serializeArray();

    var params = rawData.reduce((obj, item) => {
      if (item.name.substr(0, 28) === 'products_user_configuration[') {
        // "user_configuration[foo]".substring(13, "user_configuration[foo]".lenght - 1)
        obj[item.name.substring(28, item.name.length - 1)] = item.value;
      } else {
        obj[item.name] = item.value;
      }

      return obj;
    }, {});

    createUserConfiguration({ params })
      .then((response) => (response.text()))
      .then((response) => {
        setContent(response);
        setLoading(false);
      })
      .catch((error) => {
        setContent('Ops.. Somethink went wrong');
        setLoading(false);
      });
  }

  // On content change
  useEffect(() => {
    $(formId).off('submit', handleSubmit);
    $(formId).on('submit', handleSubmit);
    $(closeButtonSel).off('click');
    $(closeButtonSel).on('click', () => { setOpen(false); });

    return () => {
      $(formId).off('submit', handleSubmit);
      $(closeButtonSel).off('click');
    };
  }, [content]);

  // The shared state
  const value = useMemo(
    () => ({
      loading,
      open,
      onToggle,
      content,
    }),
    [loading, open, content],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {};

export default Context;
