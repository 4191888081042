import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterItem from './FilterItem';
import Icon from '../Icon';
import imageAnilinaAnilina from '../../images/flf-anilina_anilina.jpg';
import imageAnilinaSemianilina from '../../images/flf-anilina_semianilina.jpg';
import imageAnilinaPigmented from '../../images/flf-anilina_pigmented.jpg';

const FiltersSecondaryMenuFilterAnilina = ({
  i18n,
  show,
  toggleCurrentAnilinaType,
  currentAnilinaTypes,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'anilina';
  const { configurator_filters, configurator_anilina_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterAnilina',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );

  const anilinaKeys = [
    'anilina_anilina',
    'anilina_semianilina',
    'anilina_pigmented',
  ];
  const anilinaImages = {
    anilina_anilina: imageAnilinaAnilina,
    anilina_semianilina: imageAnilinaSemianilina,
    anilina_pigmented: imageAnilinaPigmented,
  };

  const handleClick = (id) => { toggleCurrentAnilinaType(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className={classnames('col-sm-4')}>
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            anilinaKeys.map((key, idx) => (
              <React.Fragment key={key}>
                <div className={classnames('col-sm-4 my-3')}>
                  <FilterItem
                    key={key}
                    id={key}
                    name={configurator_anilina_filters[key]}
                    image={anilinaImages[key]}
                    onClick={(event) => (handleClick(key))}
                    active={_.includes(currentAnilinaTypes, key)}
                  />
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterAnilina;
