import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import BarChart from './BarChart';

import leatherImage from '../../images/balance-product-leather.png';
import companiesImage from '../../images/balance-product-companies.png';
import cloudImage from '../../images/balance-product-cloud.png';
import bottleImage from '../../images/balance-product-bottle.png';
import arrowDown1Image from '../../images/balance-product-arrowdown1.png';
import arrowDown2Image from '../../images/balance-product-arrowdown2.png';
import pickerImage from '../../images/balance-product-picker.png';
import greenStarImage from '../../images/balance-product-greenstart.png';
import dropsImage from '../../images/balance-product-drops.png';

const slideColor = '#C7DC6A';
const slideColorAlt = '#97A552';
const slideColorLight = '#D8E68F';

const SlideProduct = ({ code, label, i18n, active }) => {
  const {
    one, two, three, four, five, six, seven, eight, nine,
  } = i18n;

  return (
    <div className="BalanceSliderSlide BalanceSliderSlide--product">
      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-one with-arrow">
          <h5 className="mb-3">{one.title}</h5>

          <div className="w-50 pe-md-5 pe-lg-0">
            <img
              src={leatherImage}
              className="d-block img-fluid px-5 mb-5"
            />

            <img
              src={companiesImage}
              className=" p-3 mt-4 img-fluid BalanceSliderSlide__img"
            />
          </div>
        </div>

        <div className="col-lg-8 BalanceSliderSlide__step BalanceSliderSlide__step-two">
          <div className="row">
            <div className="col-md-6">
              <div className="row pb-5">
                <div className="col-3">
                  <img
                    src={cloudImage}
                    className="d-block img-fluid my-4"
                  />

                  <img
                    src={arrowDown1Image}
                    className="d-block img-fluid"
                  />
                </div>

                <div className="col-9">
                  <h6
                    className="BalanceSliderSlide__title pe-5 mt-4"
                  >
                    {two.title_1}
                  </h6>

                  <h6 className="BalanceSliderSlide__title mt-4 w-75 text-center text-nowrap">
                    <span className="BalanceSliderSlide__strong-number-5 d-inline-block">
                      -18
                    </span>
                    <span style={{ verticalAlign: 'super' }}>
                      %
                    </span>

                    <small className="">
                      2019 → 2021
                    </small>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-3">
                  <img
                    src={bottleImage}
                    className="d-block img-fluid my-4 px-2 px-md-1 px-lg-3"
                  />

                  <img
                    src={arrowDown2Image}
                    className="d-block img-fluid pt-2"
                  />
                </div>

                <div className="col-9">
                  <h6
                    className="BalanceSliderSlide__title pe-5 mt-4"
                  >
                    {two.title_2}
                  </h6>

                  <h6 className="BalanceSliderSlide__title mt-4 w-75 text-center text-nowrap">
                    <span className="BalanceSliderSlide__strong-number-5">
                      -9
                    </span>
                    <span style={{ verticalAlign: 'super' }}>
                      %
                    </span>

                    <small className="">
                      2019 → 2021
                    </small>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <h6 className="mt-4 BalanceSliderSlide__text">
            {two.text_1}
          </h6>

          <h6 className="mt-4 BalanceSliderSlide__text">
            {two.text_2}
          </h6>
        </div>
      </div>

      <div className="BalanceSliderSlide__dots mb-5" />

      <div className="row">
        <div className="col-lg-4 BalanceSliderSlide__step BalanceSliderSlide__step-four with-arrow">
          <h5 className="mb-5">
            {four.title}
          </h5>

          <div className="w-50 pe-md-5 pe-lg-0">
            <img
              src={pickerImage}
              className="d-block img-fluid px-5 mb-5"
            />

            <img
              src={greenStarImage}
              className=" p-3 mt-4 img-fluid BalanceSliderSlide__img"
            />
          </div>
        </div>

        <div className="col-lg-8 BalanceSliderSlide__step BalanceSliderSlide__step-five">
          <div className="row">
            <div className="col-md-6">
              <div className="row pb-5">
                <div className="col-3">
                  <img
                    src={dropsImage}
                    className="d-block img-fluid mt-4 mb-4 ps-2"
                  />

                  <img
                    src={arrowDown1Image}
                    className="d-block img-fluid"
                  />
                </div>

                <div className="col-9">
                  <h6
                    className="BalanceSliderSlide__title w-75 mt-4 pe-5"
                  >
                    {five.title}
                  </h6>

                  <h6 className="BalanceSliderSlide__title mt-4 w-75 text-center text-nowrap">
                    <span className="BalanceSliderSlide__strong-number-5 d-inline-block">
                      -30
                    </span>
                    <span style={{ verticalAlign: 'super' }}>
                      %
                    </span>

                    <small className="">
                      2019 → 2021
                    </small>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-sm-6 d-flex align-items-center w-100">
              <h6 className="BalanceSliderSlide__text">
                {five.text}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SlideProduct.propTypes = {
  code:  PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SlideProduct;
