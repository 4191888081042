import { get, pathWithFormat, toParams, currentLocale } from './utils';

export const basePath = ({ artemida, locale }) => (
  `/${locale || currentLocale()}/${artemida ? 'artemida' : 'articles'}`
);

// export const configuratorBasePath = ({ artemida, locale }) => (
//   `${basePath({ artemida, locale })}/configurator`
// );

export const configuratorBasePath = ({ artemida, locale }) => (
  `/${locale || currentLocale()}/futurize-your-idea`
);

export const configuratorPDFPath = ({ artemida, locale }) => (
  `${basePath({ artemida, locale })}/configurator/download`
);

export const toParam = (product) => (product.slug || product.id);

export const productPath = ({ product, locale, artemida }) => (
  [
    basePath({ locale, artemida }),
    toParam(product),
  ].join('/')
);

export const productDetailsPath = ({ product, locale, artemida }) => (
  [
    basePath({ locale, artemida }),
    toParam(product),
    'details.json',
  ].join('/')
);

export const productsPath = ({
  locale,
  artemida,
  page,
  format,
  categoryId,
  categoryIds,
  colorIds,
  prices,
  thicknesses,
  flowerTypes,
  textureTypes,
  anilinaTypes,
  appearanceTypes,
  excludedVariantIds,

  view,
  text,
  color,
  ...options
}, basePathFn = basePath) => {
  const path = pathWithFormat(basePathFn({ locale, artemida }), { format });
  const parsedParams = { ...options };

  if (page) { parsedParams.page = page; }
  if (categoryId) { parsedParams['search[product_category_id]'] = categoryId; }
  if (view) { parsedParams['search[view]'] = view; }
  if (text && text.length > 0) { parsedParams['search[text]'] = text; }
  if (color && color.length > 0) { parsedParams['search[color]'] = color; }

  let qs = toParams(parsedParams);

  const pushQSArrayParam = ({ values, name }) => {
    if (values && values.length > 0) {
      values.forEach((id) => {
        qs += `&search[${name}][]=${id}`;
      });
    }
  };

  pushQSArrayParam({ values: categoryIds, name: 'product_category_ids' });
  pushQSArrayParam({ values: colorIds, name: 'color_palette_ids' });
  pushQSArrayParam({ values: prices, name: 'prices' });
  pushQSArrayParam({ values: thicknesses, name: 'thicknesses' });
  pushQSArrayParam({ values: flowerTypes, name: 'flower_types' });
  pushQSArrayParam({ values: textureTypes, name: 'texture_types' });
  pushQSArrayParam({ values: anilinaTypes, name: 'anilina_types' });
  pushQSArrayParam({ values: appearanceTypes, name: 'appearance_types' });
  pushQSArrayParam({ values: excludedVariantIds, name: 'excluded_variant_ids' });

  if (qs && qs.length > 0) {
    return `${path}?utf8=✓&${qs}`;
  }

  return path;
};

export const productsConfiguratorPDFPath = (options) => (
  productsPath({ ...options, format: 'pdf' }, configuratorPDFPath)
);

export const productsConfiguratorPath = (options) => (
  productsPath(options, configuratorBasePath)
);

export const fetchProducts = (params) => (
  get(productsPath({ ...params, format: 'json' }))
);

export const fetchProductsConfigurator = (params) => (
  get(productsConfiguratorPath({ ...params, format: 'json' }))
);

export const fetchProductDetails = (params) => (
  get(productDetailsPath({ ...params, format: 'json' }))
);

export default {
  productPath,
  productsPath,
  fetchProducts,
  fetchProductDetails,
  productsConfiguratorPath,
  fetchProductsConfigurator,
};
