import React from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar'
import _ from 'lodash';

import BackButton from './BackButton';
import FilterItem from './FilterItem';
import Icon from '../Icon';

import imageAppearanceOpaque    from '../../images/flf-appearance_opaque.jpg';
import imageAppearanceSemilucid from '../../images/flf-appearance_semilucid.jpg';
import imageAppearanceLucid     from '../../images/flf-appearance_lucid.jpg';
import imageAppearancePearly    from '../../images/flf-appearance_pearly.jpg';
import imageAppearancePullup    from '../../images/flf-appearance_pullup.jpg';
import imageAppearanceCloudy    from '../../images/flf-appearance_cloudy.jpg';
import imageAppearanceAged      from '../../images/flf-appearance_aged.jpg';

const FiltersSecondaryMenuFilterAppearance = ({
  i18n,
  show,
  toggleCurrentAppearanceType,
  currentAppearanceTypes,
  showFilter,
  setShowFilter,
}) => {
  const filter = 'appearance';
  const { configurator_filters, configurator_appearance_filters } = i18n;
  const className = classnames(
    'FiltersSecondaryMenuFilterAppearance',
    'FiltersSecondaryMenuFilterSection',
    filter,
    { show }
  );
  const appearanceKeys = [
    'appearance_opaque',
    'appearance_semilucid',
    'appearance_lucid',
    'appearance_pearly',
    'appearance_pullup',
    'appearance_cloudy',
    'appearance_aged',
  ];
  const appearanceKeysCol1 = appearanceKeys.slice(0, 3);
  const appearanceKeysCol2 = appearanceKeys.slice(3, 6);
  const appearanceKeysCol3 = appearanceKeys.slice(6)
  const appearanceImages = {
    appearance_opaque:    imageAppearanceOpaque,
    appearance_semilucid: imageAppearanceSemilucid,
    appearance_lucid:     imageAppearanceLucid,
    appearance_pearly:    imageAppearancePearly,
    appearance_pullup:    imageAppearancePullup,
    appearance_cloudy:    imageAppearanceCloudy,
    appearance_aged:      imageAppearanceAged,
  };

  const handleClick = (id) => { toggleCurrentAppearanceType(id); };

  return (
    <div className={className}>
      <PerfectScrollbar style={{}} className="container">
        <div className="row mb-2">
          <div className={classnames('col-sm-4')}>
            <div className="d-none">
              <div className="FiltersSecondaryMenuFilterSection__filterImage" />
              <h6 className="FiltersSecondaryMenuFilterSection__filterLabel">
                {configurator_filters[filter]}
              </h6>
            </div>
            <h5 className="FiltersSecondaryMenuFilterSection__filterTitle">
              {configurator_filters[filter]}
            </h5>
          </div>

          <div className="col-sm-8 text-end">
            <BackButton
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>

        <div className="row">
          {
            appearanceKeys.map((key, idx) => (
              <div className={classnames('col-sm-4')} key={key}>
                <FilterItem
                  key={key}
                  id={key}
                  name={configurator_appearance_filters[key]}
                  image={appearanceImages[key]}
                  onClick={(event) => (handleClick(key))}
                  active={_.includes(currentAppearanceTypes, key)}
                  className="my-3"
                />
              </div>
            ))
          }
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default FiltersSecondaryMenuFilterAppearance;
