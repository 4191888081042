import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import _ from 'lodash';
import $ from 'jquery';

import {
  deserializeJsonApi,
  i18nShape,
  jsonApiShape,
  productCategoryShape,
  colorPaletteShape,
  productShape,
//   productVariantShape,
} from '../utils';

import { productPath, productsPath, fetchProducts } from '../../api/products';
import { addColorToCart, removeColorFromCart } from '../../api/cart';

const Context = createContext({});

export const Provider = ({
  i18n,
  locale,
  artemida,
  children,
  isMobile,
  ...props
}) => {
  //
  // State
  //

  // Filter values: none | categories | colors
  let initialFilter = 'none';
  // infer the initial filter... yeah.. it's ugly, refactor later
  switch (props.view) {
    case 'products':
      if (props.currentCategory) { initialFilter = 'categories'; }

      break;

    case 'variants':
      initialFilter = 'colors';

      break;

    default:
      if (props.currentCategory) {
        initialFilter = 'categories';
      } else if (props.currentColors.length > 0) {
        initialFilter = 'colors';
      }
  }
  const [showFilter, setShowFilter] = useState(initialFilter);
  const [isLoading, setIsLoading] = useState(false);
  const [jsonApiProducts, setJsonApiProducts] = useState(props.products);
  const [products, setProducts] = useState(deserializeJsonApi(props.products));
  const [currentCategory, setCurrentCategory] = useState(props.currentCategory);
  const [currentColors, setCurrentColors] = useState(props.currentColors || []);
  const [textFilter, setTextFilter] = useState(props.textFilter);
  const [colorFilter, setColorFilter] = useState(props.colorFilter);
  const [cartColorIds, setCartColorIds] = useState(props.cartColorIds);
  const [zoomedColorId, setZoomedColorId] = useState(null);


  //
  // State helpers
  //
  /// const products = deserializeJsonApi(jsonApiProducts);
  const pagination = jsonApiProducts.meta || {};
  const currentPage = pagination.current_page || 1;
  const totalPages = pagination.total_pages || 1;
  const hasNextPage = totalPages > currentPage;
  const nextPage = hasNextPage ? currentPage + 1 : null;

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: () => (
      loadPage(nextPage, { skipHistory: true })
    ),
    // P
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    // disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    // rootMargin: '0px 0px 400px 0px',
  });

  //
  // Internal refs
  //
  const articlesGridRef = useRef(null);
  const filtersRef = useRef(null);
  const bodyRef = useRef(null);

  // Options:
  // - forceModile scroll
  const scrollIntoArticlesView = (options = {}) => {
    if (isMobile && !options.forceScroll) { return; }

    if (filtersRef) {
      filtersRef.current.scrollIntoView();
    }
  }

  //
  // Params
  //
  // const view = showFilter === 'colors'
  //   ? 'variants'
  //   : 'products';
  let view = 'products'; // The default view
  if (showFilter === 'colors') { view = 'variants'; }
  if ((colorFilter || '').length > 0 ) { view = 'variants'; }

  const queryString = {
    locale,
    page: currentPage,
    categoryId: currentCategory,
    colorIds: currentColors,
    view,
    text: textFilter,
    color: colorFilter,
  };

  // Api
  const loadProductsApi = (params = {}) => {
    const fullParams = { ...params, artemida };
    const { page } = params;

    // Reset products on filters change
    if (page === 1) {
      setProducts([]);
    }

    fetchProducts(fullParams)
      .then((response) => (response.json()))
      .then((jsonApi) => {
        setJsonApiProducts(jsonApi);
        setProducts((products) => ([
          ...products,
          ...deserializeJsonApi(jsonApi),
        ]))
        setIsLoading(false);

        if (global.history && params.skipHistory !== true) {
          const newUrl = productsPath(fullParams);
          global.history.replaceState({}, '', newUrl);
        }
      });
  };
  const loadProductsRef = useRef(_.debounce(loadProductsApi, 500));
  const loadProducts = (options = {}) => {
    if (!isLoading) { setIsLoading(true); }

    scrollIntoArticlesView();

    const params = {
      ...queryString,
      ...options,
    };

    loadProductsRef.current(params);
  };
  // Helpers
  const loadPage = (page, options = {}) => (loadProducts({ ...options, page }));
  const resetFilters = (options = {}) => {
    setCurrentCategory(null);
    setCurrentColors([]);
    loadProducts({
      page: 1,
      colorIds: [],
      categoryId: null,
      view: options.view || 'products',
    });
  };
  const resetShowFilter = () => {
    setShowFilter('none');
    resetFilters();
  };
  const setProductsView = () => {
    setShowFilter('categories');
    resetFilters();
  };
  const setColorsView = () => {
    setShowFilter('colors');
    resetFilters({ view: 'variants' });
  };
  const showCategories = showFilter === 'categories';
  const showColors = showFilter === 'colors';
  const jsonApiProductCategories = props.productCategories;
  const productCategories = deserializeJsonApi(jsonApiProductCategories);
  const jsonApiColorPalettes = props.colorPalettes;
  const colorPalettes = deserializeJsonApi(jsonApiColorPalettes);
  const pagedProductsPath = (page, options = {}) => (
    productsPath({
      ...queryString,
      ...options,
      artemida,
      page,
    })
  );

  // handlers
  const handleSetShowFilter = (value) => {
    switch (value) {
      case 'colors':
        setColorsView();
        break;
      case 'categories':
        setProductsView();
        break;
      default:
        console.error('Unhandled value', { value });
    }

    scrollIntoArticlesView({ forceScroll: true });
  };
  const handleSetCurrentCategory = (id) => {
    const nextCategory = currentCategory === id ? null : id;

    loadProducts({ categoryId: nextCategory, page: 1 });
    setCurrentCategory(nextCategory);

    scrollIntoArticlesView();
  };
  const handleToggleCurrentColor = (id) => {
    const nextColorIds = _.includes(currentColors, id)
      ? currentColors.filter((cId) => (cId !== id))
      : [...currentColors, id];

    loadProducts({
      colorIds: nextColorIds,
      view: 'variants',
      page: 1,
    });

    setCurrentColors(nextColorIds);
  };
  const handleToggleCartColorId = (id) => {
    const alreadySelected = _.includes(cartColorIds, id);
    const willBeSelected = !alreadySelected; // next state
    const nextCartColorIds = alreadySelected
      ? cartColorIds.filter((cId) => (cId !== id))
      : [...cartColorIds, id];
    const toogleCart = willBeSelected ? addColorToCart : removeColorFromCart;

    toogleCart({ locale, colorId: id })
      .then((response) => (response.json()));

    $(document).trigger('AddToCartCta:change', ['color', id, willBeSelected]);

    setCartColorIds(nextCartColorIds);
  };
  const handleSetTextFilter = (value) => {
    loadProducts({
      text: value,
      page: 1,
    });

    setTextFilter(value);
  };

  const handleSetColorFilter = (color) => {
    const page = 1;

    if (color.length > 0) {
      loadProducts({ view: 'variants', color, page });
    } else {
      loadProducts({ color, page });
    }

    setColorFilter(color);
  };
  const resetZoomedColor = () => { setZoomedColorId(null); };
  const handleZoomColorId = (id) => {
    setZoomedColorId(id);
  };

  const showColorsGallery = !isLoading && view === 'variants';

  // Component did mount
  useEffect(() => {
    if (props.currentCategory) {
      setTimeout(() => {
        scrollIntoArticlesView()
      }, 1000);
    }
  }, []);

  // The shared state
  const value = useMemo(
    () => ({
      i18n,
      locale,
      artemida,
      loadPage,
      isLoading,
      setIsLoading,
      products,
      pagination,
      productCategories,
      colorPalettes,
      productPath: (params) => (productPath({ locale, artemida, ...params })),
      productsPath: pagedProductsPath,
      showFilter,
      setShowFilter: handleSetShowFilter,
      resetShowFilter,
      showCategories,
      showColors,
      showProductsFilters: showColors || showCategories,
      currentCategory,
      setCurrentCategory: handleSetCurrentCategory,
      currentColors,
      toggleCurrentColor: handleToggleCurrentColor,
      toggleCartColorId: handleToggleCartColorId,
      cartColorIds,
      setTextFilter: handleSetTextFilter,
      setColorFilter: handleSetColorFilter,
      view,
      textFilter,
      colorFilter,
      showColorsGallery,
      zoomedColorId,
      zoomColorId: handleZoomColorId,
      resetZoomedColor,
      articlesGridRef,
      filtersRef,
      bodyRef,
      hasNextPage,
      infiniteScrollRef: sentryRef,
    }),
    [
      pagination,
      isLoading,
      showFilter,
      currentCategory,
      currentColors,
      cartColorIds,
      zoomedColorId,
      hasNextPage,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  locale: PropTypes.string.isRequired,
  i18n: i18nShape.isRequired,
  productCategories: jsonApiShape(productCategoryShape),
  colorPalettes: jsonApiShape(colorPaletteShape),
  products: jsonApiShape(productShape),
  children: PropTypes.element.isRequired,
  currentCategory: PropTypes.number,
  currentColors: PropTypes.arrayOf(PropTypes.number),
  cartColorIds: PropTypes.arrayOf(PropTypes.number),
  view: PropTypes.string,
  textFilter: PropTypes.string,
  colorFilter: PropTypes.string,
  artemida: PropTypes.bool,
};

Provider.defaultProps = {
  productCategories: {},
  colorPalettes: {},
  products: {},
  currentCategory: null,
  currentColors: [],
  view: 'products',
  textFilter: '',
  colorFilter: '',
  cartColorIds: [],
  artemida: false,
};

export default Context;
